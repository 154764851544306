import React from 'react';
import OrderModalProvider from './OrderModalProvider';
import OrderModalWrap from './OrderModalWrap';

function OrderModal({ close, id, updater = () => {}, parent_api_headers = {} }) {
	return (
		<OrderModalProvider>
			<OrderModalWrap close={close} id={id} updater={updater} parent_api_headers={parent_api_headers} />
		</OrderModalProvider>
	);
}

export default OrderModal;
