export const TYPES = {
	SMART: 'smart',
	BUSINESS: 'business',
	NETWORK: 'network',
	THREE_PL: 'three_pl',
	AUTO_MANIFEST: 'auto_manifest',
};

export const AVAILABLE_RIDER_TYPE = {
	AVAILABLE: 'available',
	LOWEST_COST: 'lowest_cost',
	BORADCAST: 'broadcast',
	TOP_RIDER: 'top_rider',
};

export const ALLOCATE_OPTIONS = [
	{
		title: 'Available',
		key: 'available',
	},
	{
		title: 'Lowest Cost',
		key: 'lowest_cost',
	},
	{
		title: 'Top Rider',
		key: 'top_rider',
	},
	// {
	// 	title: 'Broadcast',
	// 	key: 'broadcast',
	// },
];

export const BOTTOM_STATE = {
	start: 'start',
	in_progress: 'in_progress',
	success: 'success',
	failed: 'failed',
};

export const tnc_data = {
	dunzo: {
		list: [
			{
				heading: 'Delivery Charges',
				description: 'Dunzo charges are dynamic and can change basis number of stops or surge',
			},
			{
				heading: 'Cancellation',
				description: 'Dunzo allows cancellation before pickup and may charge a fee',
			},
			{
				heading: 'Failed Delivery',
				description: 'Dunzo allows cancellation after pickup for 1 or more orders and will charge a fee for orders returned',
			},
			{
				heading: 'COD',
				description: 'COD only supported with pre-existing relationships between client and partner',
			},
			{
				heading: 'Package size',
				description: 'cumulative package size should fit comfortably on a bike and not exceed 10 kg',
			},
			{
				heading: 'Multiple drops',
				description: 'Dunzo optimizes route for multiple drops',
			},
		],
	},
	pidge: {
		list: [
			{
				heading: 'Delivery Charges',
				description: 'Delivery charges are default unless agreed otherwise with Pidge',
			},
			{
				heading: 'Cancellation',
				description: 'Pidge charges a fee if cancelled after allocation',
			},
			{
				heading: 'Failed Delivery',
				description: 'Orders rejected by customers will be returned to sender and may incur additional charges',
			},
			{
				heading: 'COD',
				description: 'Pidge allows COD orders. Max limits may vary',
			},
			{
				heading: 'Package size',
				description: 'Individual package size should fit 14" x 14" x 14"',
			},
			{
				heading: 'Multiple drops',
				description: 'Pidge optimizes route for multiple drops and may assign multiple delivery executives if needed',
			},
		],
	},
	wefast: {
		list: [
			{
				heading: 'Delivery Charges',
				description: 'Borzo charges are dynamic and can change basis number of stops or surge',
			},
			{
				heading: 'Cancellation',
				description: 'Borzo allows cancellation before pickup and may charge a fee',
			},
			{
				heading: 'Failed Delivery',
				description: 'Borzo does not encourage failed deliveries',
			},
			{
				heading: 'COD',
				description: 'COD only supported with pre-existing relationships between client and partner',
			},
			{
				heading: 'Package size',
				description: 'Borzo executives may or may not have carry boxes',
			},
			{
				heading: 'Multiple drops',
				description: 'Multiple drops currently not supported',
			},
		],
	},
	shadowfax: {
		list: [
			{
				heading: 'Delivery Charges',
				description: 'Shadowfax charges are dynamic basis distance',
			},
			{
				heading: 'Cancellation',
				description: 'Shadowfax allows cancellation before pickup and may charge a fee',
			},
			{
				heading: 'Failed Delivery',
				description: 'Shadowfax allows cancellation after pickup and will charge an equivalent fee for orders returned',
			},
			{
				heading: 'COD',
				description: 'COD only supported with pre-existing relationships between client and partner',
			},
			{
				heading: 'Package size',
				description: 'Cumulative package size should fit comfortably on a bike and not exceed 10 kg',
			},
			{
				heading: 'Multiple drops',
				description: 'Shadowfax does not support multiple drops',
			},
		],
	},
	loadshare: {
		list: [
			{
				heading: 'Delivery Charges',
				description: 'Loadshare charges are dynamic basis distance',
			},
			{
				heading: 'Cancellation',
				description: 'Loadshare allows cancellation before pickup and may charge a fee',
			},
			{
				heading: 'Failed Delivery',
				description: 'Loadshare allows cancellation after pickup and will charge an equivalent fee for orders returned',
			},
			{
				heading: 'COD',
				description: 'COD only supported with pre-existing relationships between client and partner',
			},
			{
				heading: 'Package size',
				description: 'Cumulative package size should fit comfortably on a bike and not exceed 10 kg',
			},
			{
				heading: 'Multiple drops',
				description: 'Loadshare does not support multiple drops',
			},
		],
	},
	zomato: {
		list: [
			{
				heading: 'Delivery Charges',
				description: 'Zomato charges are dynamic basis distance, weather, and peak periods',
			},
			{
				heading: 'Cancellation',
				description: 'Zomato allows cancellation before pickup and may charge a fee',
			},
			{
				heading: 'Failed Delivery',
				description: 'Zomato allows cancellation after pickup and will charge an equivalent fee for orders returned',
			},
			{
				heading: 'COD',
				description: 'COD only supported with pre-existing relationships between client and partner',
			},
			{
				heading: 'Package size',
				description: 'Cumulative package size should fit comfortably on a bike and not exceed 10 kg',
			},
			{
				heading: 'Multiple drops',
				description: 'Zomato does not support multiple drops',
			},
		],
	},
	porter: {
		list: [
			{
				heading: 'Delivery Charges',
				description: 'Porter charges are dynamic basis distance',
			},
			{
				heading: 'Cancellation',
				description: 'Porter allows cancellation before pickup and may charge a fee',
			},
			{
				heading: 'Failed Delivery',
				description: 'Porter allows cancellation after pickup and will charge an equivalent fee for orders returned',
			},
			{
				heading: 'COD',
				description: 'COD only supported with pre-existing relationships between client and partner',
			},
			{
				heading: 'Package size',
				description: 'Cumulative package size should fit comfortably on a bike and not exceed 10 kg',
			},
			{
				heading: 'Multiple drops',
				description: 'Porter does not support multiple drops',
			},
		],
	},
};

export const FULFILLMENT_TYPE = {
	IMMEDIATE: 0,
	SYSTEM_SCHEDULE: 1,
	REMOTE_SCHEDULE: 2,
	PLANNED_SCHEDULE: 3,
	SMART: 4,
};
