import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Button from 'modules/Button';
import Select from 'modules/Select';
import { PICKUP_POINTS } from '../helper';
import Routes from 'resources/Routes';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles({
	wrapper: {
		position: 'absolute',
		zIndex: 1000,
		left: 0,
		right: 0,
		top: '15%',
		marginLeft: 'auto',
		marginRight: 'auto',
		background: theme.colors.white,
		borderRadius: 12,
		width: 783,
		// height: 91,
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		padding: 20,
		justifyContent: 'space-around',
	},
	line_bar: {
		width: 1,
		height: 40,
		background: theme.colors.lightPurple14,
	},
	animatedItem: {
		animation: `$myEffect 1000ms`,
		animationFillMode: 'both',
		animationTimingFunction: 'ease-in-out',
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 1,
			transform: 'translateY(50%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateY(-20%)',
		},
	},
});

const FindMyPartners = ({
	pickup_point,
	drop_point,
	set_pickup_point,
	set_drop_points,
	handle_fetch_partner,
	show_circle,
	map_data,
	fetch_location_from_ip,
}) => {
	const classes = useStyles();
	const history = useHistory();

	const [current_distance, set_current_distance] = useState(null);
	const [show_signup, set_show_signup] = useState(false);

	useEffect(() => {
		if (show_circle) {
			const interval = _.map(Object.keys(map_data), (val, index) => {
				return setTimeout(() => {
					set_current_distance(val);
					if (Object.keys(map_data).length - 1 == index) {
						set_show_signup(true);
					}
				}, map_data[val].delay);
			});

			return () => {
				interval.forEach((timerId) => clearTimeout(timerId));
			};
		}
	}, [show_circle]);

	const handle_pickup = (v) => {
		if (v == 0) {
			fetch_location_from_ip(true);
		} else {
			_.map(PICKUP_POINTS.pickup_points, (val) => {
				if (val.value == v) {
					set_pickup_point(val);
					set_drop_points(val.drop_points[Math.floor(Math.random() * val.drop_points.length)]);
				}
			});
		}
	};

	const handle_drop = (v) => {
		_.map(pickup_point.drop_points, (val) => {
			if (val.value == v) {
				set_drop_points(val);
			}
		});
	};

	const sign_up_click = () => {
		history.push(Routes.LOGIN.path);
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.FIND_PARTNER_SINGUP);
	};
	return (
		<div className={clsx(classes.wrapper, classes.animatedItem)}>
			{show_circle && (
				<div className='d-flex justify-content-between align-items-center' style={{ padding: 30 }}>
					<div style={{ display: 'flex', gap: 8 }}>
						<Text semi style={{ fontSize: 16, color: theme.colors.darkGrey8 }}>
							Partners within
						</Text>
						<Text semi style={{ fontSize: 16, color: theme.colors.lightGreen3 }}>
							{current_distance}
						</Text>
					</div>
					<div className='d-flex justify-content-center align-items-center' style={{ gap: 12, visibility: show_signup ? '' : 'hidden' }}>
						<Text semi style={{ fontSize: 14, color: theme.colors.black }}>
							Unlock More Delivery Options{' '}
						</Text>
						<Button type='round' text='Sign up for Free' right_icon={ImageLinks.arrow_right_white_no_fill} size='large' onClick={sign_up_click} />
					</div>
				</div>
			)}

			{!show_circle && (
				<div className={classes.container}>
					<div className='d-flex' style={{ flexDirection: 'column', gap: 4 }}>
						<Text semi style={{ fontSize: 16, color: theme.colors.darkGrey8 }}>
							Pickup Location
						</Text>
						<Select
							value={pickup_point.value}
							right_icon={ImageLinks.chevron_up_gray}
							defaultOption={{
								title: `Select`,
								value: '',
							}}
							options={_.map([{ title: 'Near by', value: 0 }, ...PICKUP_POINTS.pickup_points], (option) => {
								return {
									title: option.title,
									value: option.value,
								};
							})}
							handleChange={(v) => handle_pickup(v)}
							containerStyles={{
								height: 20,
								width: '100%',
								background: theme.colors.white,
								border: 'none',
								gap: 60,
								padding: 0,
								justifyContent: 'space-between',
							}}
							text_styles={{ fontSize: 16, color: theme.colors.darkGrey7 }}
							text_props={{ semi: true }}
							image_styles={{ width: 16, height: 16 }}
						/>
					</div>
					<div className={classes.line_bar}></div>
					<div className='d-flex' style={{ flexDirection: 'column', gap: 4 }}>
						<Text semi style={{ fontSize: 16, color: theme.colors.darkGrey8 }}>
							Drop Location
						</Text>

						<Select
							value={drop_point.value}
							right_icon={ImageLinks.chevron_up_gray}
							defaultOption={{
								title: `Select`,
								value: '',
							}}
							options={
								pickup_point.value == 0
									? [{ title: '10KM around the current loaction', value: 0 }]
									: _.filter(PICKUP_POINTS.pickup_points, (item) => {
											return item.value == pickup_point.value;
									  })[0].drop_points
							}
							handleChange={(v) => handle_drop(v)}
							containerStyles={{
								height: 20,
								width: '100%',
								background: theme.colors.white,
								border: 'none',
								gap: 60,
								padding: 0,
								justifyContent: 'space-between',
							}}
							text_styles={{ fontSize: 16, color: theme.colors.darkGrey7 }}
							text_props={{ semi: true }}
							image_styles={{ width: 16, height: 16 }}
						/>
					</div>
					<div className={classes.line_bar}></div>
					<div>
						<Button type='round' size='large' text='Discover' onClick={handle_fetch_partner} />
					</div>
				</div>
			)}
		</div>
	);
};

export default FindMyPartners;
