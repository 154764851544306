import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { ListItem, makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Footer from './Footer';

const useStyles = makeStyles(() => ({
	title_text: { fontSize: 40 },
	content_wrap: { flex: 0.9, display: 'flex', flexDirection: 'column', justifyContent: 'center' },
	form_style: {
		width: '50%',
		height: '100%',
	},
	type_wrapper: { width: 330, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' },
	user_list_item_wrapper: {
		width: 160,
		height: 150,
		background: theme.colors.white,
		borderRadius: 8,
		border: '1px solid #E4DCF3',
		marginTop: 25,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	user_list_item: {
		height: 60,
		width: 60,
		background: 'black',
		borderRadius: 100,
		backgroundColor: '#E1D9F0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const SelectType = ({ login_details, on_type_select }) => {
	const classes = useStyles();

	return (
		<div className={classes.form_style}>
			<div style={{ paddingLeft: '15%', paddingTop: '15%' }}>
				<Text semi style={{ fontSize: 20, marginTop: 10 }}>
					User Type
					<Text component={'span'} medium style={{ fontSize: 20, color: theme.colors.lightGrey5 }}>
						{` | User`}
					</Text>
				</Text>
				<div className={classes.type_wrapper}>
					{_.map(
						[
							{
								key: _.get(login_details, 'info.is_aggregator'),
								title: 'Enterprise',
								id: 6,
							},
							{
								key: _.get(login_details, 'info.is_business'),
								title: 'Business',
								id: 4,
							},
							{
								key: _.get(login_details, 'info.is_manpower'),
								title: 'Manpower',
								id: 2,
							},
							{
								key: _.get(login_details, 'info.is_vendor'),
								title: 'Vendor',
								id: 3,
							},
						],
						(type, index) => {
							if (!type.key) {
								return;
							}
							return (
								<ListItem
									key={index}
									button
									onClick={() =>
										on_type_select({
											title: type.title,
											id: type.id,
										})
									}
									className={classes.user_list_item_wrapper}>
									<div className={classes.user_list_item}>
										<Text bold style={{ fontSize: 20, color: theme.colors.primary }}>
											{type.title[0]}
										</Text>
									</div>
									<Text semi style={{ fontSize: 18, marginTop: 20 }}>
										{type.title}
									</Text>
								</ListItem>
							);
						},
					)}
				</div>
				<Footer show_help />
			</div>
		</div>
	);
};

export default SelectType;
