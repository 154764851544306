import { makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import React, { useEffect, useMemo, useState } from 'react';
import theme from 'resources/theme';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);

const options = {
	barThickness: 12,
	elements: {
		bar: {
			backgroundColor: theme.colors.lightPurple,
			borderRadius: 2,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
		datalabels: {
			display: false,
		},
	},
	scales: {
		x: {
			// type: 'time',
			// time: {
			// 	unit: 'month',
			// 	round: 'month',
			// },
			grid: {
				display: false,
			},
			ticks: {
				color: theme.colors.lightGrey2,
				font: {
					size: 10,
					family: 'HK Grotesk Medium',
				},
			},
		},
		y: {
			border: {
				display: false,
			},
			position: 'right',
			beginAtZero: true,
			grid: {
				display: true,
				color: theme.colors.lightGrey5,
				lineWidth: 0.55,
			},
			ticks: {
				color: theme.colors.lightPurple7,
				font: {
					size: 10,
					family: 'HK Grotesk Bold',
				},
				callback: function (value, index, ticks) {
					return value + ' hr';
				},
			},
		},
	},
	maintainAspectRatio: false,
};

const useStyles = makeStyles((theme) => ({
	wrapper: { display: 'flex', flex: 1, flexDirection: 'column' },
	left_wrap: { flex: 0.4, display: 'flex', flexDirection: 'column' },
	flex_center_between: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
	flex_center: { display: 'flex', alignItems: 'center' },
	indicator: { width: 8, height: 8, borderRadius: 4, marginRight: 4 },
	label: { fontSize: 10, color: theme.colors.darkGrey2 },
}));

const HourChart = ({ chart_data }) => {
	const classes = useStyles();
	const [plot_data, set_plot_data] = useState(null);

	useEffect(() => {
		if (chart_data) {
			const formatted_chart_data = _.map(chart_data.data, (item, index) => {
				return { x: item.x, y: item.y.value };
			});

			const data = {
				datasets: [
					{
						data: formatted_chart_data,
					},
				],
			};

			set_plot_data(data);
		}
	}, [chart_data]);

	const sum_of_hours = useMemo(() => {
		let total = 0;
		for (let i = 0; i < chart_data.data.length; i++) {
			const element = chart_data.data[i];
			total += element.y.value;
		}
		total = parseFloat(total).toFixed(2);
		return total;
	}, [chart_data]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.stats_wrap}>
				<Text medium style={{ fontSize: 10, color: theme.colors.lightGrey2 }}>
					Total Hours
				</Text>
				<Text bold style={{ fontSize: 16, color: theme.colors.primary }}>
					{sum_of_hours}hr
				</Text>
			</div>
			<div style={{ width: '100%', height: '100%' }}>{plot_data && <Bar options={options} data={plot_data} />}</div>
		</div>
	);
};

export default HourChart;
