import React, { useRef, useState } from 'react';
import theme from 'resources/theme';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import clsx from 'clsx';
import OutlinedTextField from 'modules/OutlinedTextField';
import ContainedButton from 'modules/ContainedButton';
import PaymentItem from './PaymentItem';
import IconButton from 'modules/IconButton';
import Amount from 'modules/Amount';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import api_requests from 'resources/api_requests';
import RazorpayUtils from 'resources/RazorpayUtils';
import PaytmUtils from 'resources/PaytmUtils';
import useStyles from '../styles';
import { hide_loader, show_loader } from 'actions/app';
import Button from 'modules/Button';

const RechargeWallet = ({ business_details, close, refetch_details, wrapper_styles = {} }) => {
	const dispatch = useDispatch();
	const [amount, set_amount] = useState('');
	const [payment_methods, set_payment_methods] = useState([]);
	const init_txn_data = useRef(null);
	const checkout_data = useRef(null);
	const classes = useStyles();

	const clear_data = () => {
		set_amount('');
		set_payment_methods([]);
		init_txn_data.current = null;
		checkout_data.current = null;
	};

	const handle_amount = (value) => {
		if (payment_methods.length > 0) set_payment_methods([]);
		set_amount(value);
	};

	const on_recharge = async () => {
		if (amount === '' || amount <= 0) {
			alert('enter valid amount');
			return;
		}
		if (init_txn_data.current != null && init_txn_data.current.amount == amount) return;

		try {
			dispatch(show_loader());
			const response = await api_requests.init_wallet_txn({
				amount,
			});
			if (response.success) {
				init_txn_data.current = response.data;
				get_payment_methods();
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const get_payment_methods = async () => {
		try {
			const response = await api_requests.get_wallet_payment_methods(init_txn_data.current.transaction_id);
			if (response.success) {
				dispatch(hide_loader());
				set_payment_methods(response.data.methods);
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const on_payment_method_press = async (method) => {
		try {
			dispatch(show_loader());
			const response = await api_requests.checkout_wallet_recharge(init_txn_data.current.transaction_id, {
				payment_method: method,
			});
			if (response.success) {
				checkout_data.current = response.data;
				if (checkout_data.current.pg.paymentGateway === 'paytm') {
					checkout_paytm();
				} else {
					checkout_razor_pay();
				}
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	const checkout_paytm = () => {
		const config = {
			root: '',
			flow: 'DEFAULT',
			data: {
				orderId: checkout_data.current.pg.pid,
				token: checkout_data.current.pg.txnToken,
				tokenType: 'TXN_TOKEN',
				amount: checkout_data.current.payment.amount.toFixed(2),
			},
			merchant: {
				redirect: false,
			},
		};

		const paytm_init_config = {
			config,
			mid: checkout_data.current.pg.credential.mid,
			paytm_updates: paytm_updates,
			success_callback: payment_success_callback,
			error_callback: payment_error_callback,
		};

		PaytmUtils.initiate_txn(paytm_init_config);
	};

	const checkout_razor_pay = () => {
		const options = {
			key: checkout_data.current.pg.credential.key_id,
			amount: checkout_data.current.payment.amount * 100,
			currency: 'INR',
			name: 'Pidge - Recharge',
			description: 'For Business Wallet Recharge',
			order_id: checkout_data.current.pg.txnToken,
			prefill: {
				email: checkout_data.current.customer.email,
				contact: checkout_data.current.customer.mobile,
			},
			theme: { color: theme.colors.primary },
			modal: {
				escape: false,
				confirm_close: true,
				ondismiss: () => dispatch(hide_loader()),
			},
			config: checkout_data.current.pg.config,
		};
		const razorpay_init_config = {
			options,
			success_callback: payment_success_callback,
			error_callback: payment_error_callback,
		};

		RazorpayUtils.initiate_txn(razorpay_init_config);
	};

	const payment_success_callback = (data) => {
		const payload = {
			referenceId: checkout_data.current.pg.referenceId,
			pid: checkout_data.current.pg.pid,
			response: data,
		};
		verify_payment(payload);
	};

	const payment_error_callback = (msg) => {
		dispatch(hide_loader());
		toast.error(msg, {
			position: toast.POSITION.BOTTOM_RIGHT,
		});
	};

	const paytm_updates = (msg) => {
		dispatch(hide_loader());
		toast.warning(msg, {
			position: toast.POSITION.BOTTOM_RIGHT,
		});
	};

	const verify_payment = async (paylaod) => {
		try {
			const resposne = await api_requests.verify_wallet_recharge(init_txn_data.current.transaction_id, paylaod);
			if (resposne.success && resposne.data?.status?.success) {
				toast.success('Recharge Successful', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				dispatch(hide_loader());
				clear_data();
				refetch_details();
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};

	return (
		<>
			<div className={classes.closeContainer} onClick={close} />
			<div className={classes.container} style={wrapper_styles}>
				<div style={{ padding: 50 }}>
					<div className={classes.header_wrap}>
						<Text bold className={classes.header_text}>
							Add Funds
						</Text>
						<div>
							<IconButton onClick={close} style={{ border: 'none' }}>
								<img src={ImageLinks.crossBlack} width={16} height={16} />
							</IconButton>
						</div>
					</div>

					<div className={classes.business_info}>
						<div>
							<Amount amount={_.get(business_details, 'balance', 0)} main_text_styles={{ fontSize: 28 }} sub_text_styles={{ fontSize: 14 }} />
							<Text bold style={{ color: theme.colors.lightPurple7, fontSize: 18 }}>
								Available Balance
							</Text>
						</div>
						<div>
							<Text bold style={{ color: theme.colors.white, fontSize: 18 }}>
								{_.get(business_details, 'brand_name', '')}
							</Text>

							<Text style={{ color: theme.colors.white, fontSize: 18 }}>{_.get(business_details, 'business_name', '')}</Text>
						</div>
					</div>

					<div>
						<Text semi className={classes.amount_label}>
							Enter Amount*
						</Text>
						<div style={{ position: 'relative' }}>
							<Text semi className={classes.inr_input}>
								₹
							</Text>
							<OutlinedTextField
								InputProps={{
									classes: {
										root: classes.amount_cssOutlinedInput,
										focused: classes.cssFocused,
										notchedOutline: classes.amount_notchedOutline,
									},
								}}
								style={{ width: 200, marginTop: 10 }}
								value={amount}
								type='number'
								onChange={(e) => {
									handle_amount(e.target.value);
								}}
							/>
						</div>
					</div>

					{payment_methods.length > 0 && (
						<div>
							<Text bold className={classes.label}>
								Select Payment Method*
							</Text>
							{_.map(payment_methods, (item, index) => (
								<PaymentItem
									key={`payment_method${index}`}
									item={item}
									onClick={() => {
										on_payment_method_press(item.key);
									}}
								/>
							))}
						</div>
					)}

					{amount > 0 && (
						<div className={classes.footer} style={wrapper_styles}>
							<Button type='round' text='Recharge' size='large' onClick={on_recharge} style={{ width: 110 }} />
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default RechargeWallet;
