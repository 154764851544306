import React from 'react';
import useOrderModal from './useOrderModal';
import OrderModalContext from './OrderModalContext';

function OrderModalProvider({ children }) {
	const value = useOrderModal();
	return <OrderModalContext.Provider value={value}>{children}</OrderModalContext.Provider>;
}

export default OrderModalProvider;
