import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageLinks from 'assets/images/ImageLinks';
import { SEARCH_BY_OPTIONS } from './helper';
import Select from 'modules/Select';
import SelectWithInput from 'modules/SelectWithInput';
import _ from 'lodash';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	searchBoxContainer: {
		flex: 1,
		height: 36,
		width: 400,
		display: 'flex',
		padding: '0px 10px',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: theme.colors.white,
		boxShadow: '0px 2px 16px rgba(102, 102, 102, 0.3)',
		borderRadius: 4,
		zIndex: 1500,
		position: 'absolute',
		top: '16px',
		left: '193px',
	},
	searchBoxContainerDrawerClosed: {
		top: '16px',
		flex: 1,
		left: '18px',
		width: '400px',
		height: '36px',
		display: 'flex',
		padding: '0px 10px',
		zIndex: '1500',
		position: 'absolute',
		background: theme.colors.white,
		boxShadow: '0px 2px 16px rgb(102 102 102 / 30%)',
		alignItems: 'center',
		borderRadius: '4px',
		justifyContent: 'space-between',
	},
}));

const SearchBarContent = ({
	set_search_bar_open,
	search_by,
	handle_search_value,
	search_text,
	set_search_text,
	handle_reference_id_details,
	search_rider_text,
	set_search_rider_text,
	search_riders,
	handle_get_rider_details,
	outside_click,
	open,
}) => {
	const classes = useStyles();
	const search_component_ref = useRef(null);

	useEffect(() => {
		const handle_click_outside = (event) => {
			if (
				search_component_ref.current &&
				!search_component_ref.current.contains(event.target) &&
				!event.target.className.includes('sidebar_input_dropdown')
			) {
				outside_click && outside_click();
			}
		};
		document.addEventListener('click', handle_click_outside, true);
		return () => {
			document.removeEventListener('click', handle_click_outside, true);
		};
	}, []);

	const handleSearchValue = (val) => {
		handle_search_value(val);
	};

	return (
		<div ref={search_component_ref} className={open ? classes.searchBoxContainer : classes.searchBoxContainerDrawerClosed}>
			<img
				className='cursor-pointer'
				src={ImageLinks.search_with_light_purple_background}
				alt='search'
				onClick={() => set_search_bar_open(false)}
				style={{ marginRight: 4 }}
			/>
			<div className='align-items-center' style={{ paddingLeft: 4, background: theme.colors.lightGrey7 }}>
				<Select
					value={search_by}
					options={SEARCH_BY_OPTIONS}
					handleChange={(val) => handleSearchValue(val)}
					containerStyles={{ height: 20, width: 110, background: theme.colors.white }}
					text_styles={{ fontSize: 12, width: '100%' }}
					right_icon={ImageLinks.arrow_up_pink}
					image_styles={{ width: 10 }}
					menu_styles={{ marginTop: 30 }}
					menu_item_class={{ className: 'sidebar_input_dropdown' }}
					text_props={{ className: 'sidebar_input_dropdown' }}
				/>
				<div style={{ flex: 1, marginLeft: 10, width: 200 }}>
					{search_by === SEARCH_BY_OPTIONS[0].value ? (
						<SelectWithInput
							value={`Search Rider Name, Id`}
							inputValue={search_rider_text}
							onChange={(text) => set_search_rider_text(text)}
							options={_.map(search_riders, (rider) => {
								return {
									title: `${rider.rider_name} (${rider.rider_id})`,
									value: rider,
								};
							})}
							handleChange={(val) => {
								handle_get_rider_details(val.rider_id);
							}}
							style={{ border: 'unset' }}
							mainContainerStyles={{ height: 36, width: '100%' }}
							containerStyles={{ backgroundColor: theme.colors.lightGrey7, border: 'unset', width: '168px', height: 36 }}
							show_arrow={false}
						/>
					) : (
						<form onSubmit={handle_reference_id_details}>
							<input
								placeholder={`Search Reference ID`}
								value={search_text}
								style={{ flex: 1, border: 'none', outline: 'none', background: theme.colors.lightGrey7 }}
								onChange={(e) => {
									set_search_text(e.target.value);
								}}
							/>
						</form>
					)}
				</div>
			</div>
			<img
				src={ImageLinks.arrow_right_light_purple}
				onClick={(e) => {
					if (search_by === SEARCH_BY_OPTIONS[1].value) {
						handle_reference_id_details(e);
					}
				}}
				style={{ opacity: `${search_text.length || search_rider_text.length ? 1 : 0.3}` }}
				alt='purple-right-arrow'
				width={'20px'}
				height={'20px'}
				className='cursor-pointer'
			/>
		</div>
	);
};

export default SearchBarContent;
