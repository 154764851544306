import React, { useEffect, useRef, useState, useMemo } from 'react';
import theme from 'resources/theme';
import _ from 'lodash';
import Text from 'modules/Text';
import IconButton from 'modules/IconButton';
import cross from 'assets/images/icons/cross-black.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Checkbox, FormControl, ListItem } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Select from 'modules/Select';
import moment from 'moment';
import api_requests from 'resources/api_requests';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ConfirmClose from 'modules/modals/ConfirmClose';
import { useStyles } from './styles';
import { toast } from 'react-toastify';
import TimeInput from 'modules/TimeInput';
import Button from 'modules/Button';
import Switch from 'modules/Switch';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import { hide_loader, show_loader } from 'actions/app';
import ContainedButton from 'modules/ContainedButton';

const CreateRoster = ({ close, is_rider_editable = true, rider_info = null, show_toast = (e) => {}, disabled_start_time }) => {
	const dispatch = useDispatch();
	const is_edit = rider_info?.selected_hub != null;
	const [roster_details, set_roster_details] = useState({
		hub_id: null,
		rider_ids: [],
		dates: [],
		shift_from: rider_info ? rider_info.shift_start_24_time : moment().format('08:00'),
		shift_to: rider_info ? rider_info.shift_end_24_time : moment().format('20:00'),
	});

	const [hubs, set_hubs] = useState([]);
	const [selected_riders, set_selected_riders] = useState([]);
	const [search_rider_id, set_search_rider_id] = useState('');
	const [search_riders, set_search_riders] = useState([]);
	const [confirm_close_modal, set_confirm_close_modal] = useState(false);
	const [calendar_slot, set_calender_slot] = useState(0);
	const [all_selected, set_all_selected] = useState(false);
	const [overnight, set_overnight] = useState(false);
	const input_ref = useRef();

	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);

	const [selected_master_zone_id, set_selected_master_zone_id] = useState(rider_info?.master_zone_id ? rider_info.master_zone_id : zones[0].id);

	const handle_select_zone = (val) => {
		set_selected_master_zone_id(val);
	};

	const handle_select_rider = (rider, is_delete) => {
		const index = _.findIndex(selected_riders, { rider_id: rider.rider_id });
		const _selected_riders = [...selected_riders];

		if (index === -1) {
			_selected_riders.push(rider);
		} else {
			if (is_delete) _selected_riders.splice(index, 1);
		}

		set_selected_riders(_selected_riders);
		set_search_rider_id('');
		set_search_riders([]);
	};

	const handle_upload_file = async (e) => {
		const file = e.target.files[0];
		const form_data = new FormData();
		form_data.append('file', file);
		try {
			const res = await api_requests.upload_rider_csv(form_data);
			if (res.success) {
				set_selected_riders(res.data);
				e.target.value = '';
			}
		} catch (err) {
			e.target.value = '';
		}
	};

	const handle_date = (slot) => {
		const date = moment().add(slot, 'days').format('YYYY-MM-DD');
		const index = _.indexOf(roster_details.dates, date);
		const _dates = [...roster_details.dates];

		if (index === -1) {
			_dates.push(date);
		} else {
			_dates.splice(index, 1);
		}

		set_roster_details({
			...roster_details,
			dates: _dates,
		});
	};

	const handle_calender_date = (dates) => {
		set_roster_details({
			...roster_details,
			dates: _.map(dates, (date) => {
				return moment(date).format('YYYY-MM-DD');
			}),
		});
	};

	const handle_select_all = () => {
		if (all_selected) {
			const _dates = roster_details.dates;
			_.forEach([0, 1, 2, 3, 4, 5, 6], (slot) => {
				_dates.splice(
					_.indexOf(
						_dates,
						moment()
							.add(slot + 7 * calendar_slot, 'days')
							.format('YYYY-MM-DD'),
					),
					1,
				);
			});
			set_roster_details({
				...roster_details,
				dates: _dates,
			});
		} else {
			const _dates = [];
			_.map([0, 1, 2, 3, 4, 5, 6], (slot) => {
				if (
					!_.includes(
						roster_details.dates,
						moment()
							.add(slot + 7 * calendar_slot, 'days')
							.format('YYYY-MM-DD'),
					)
				)
					_dates.push(
						moment()
							.add(slot + 7 * calendar_slot, 'days')
							.format('YYYY-MM-DD'),
					);
			});
			set_roster_details({
				...roster_details,
				dates: [...roster_details.dates, ..._dates],
			});
		}
	};

	const proceed_create_roster = () => {
		dispatch(show_loader());
		const _roster_details = _.cloneDeep(roster_details);
		const start_time = _roster_details.shift_from.split(':');
		const end_time = _roster_details.shift_to.split(':');
		const hour_diff = (end_time[0] - start_time[0]) * 60;
		const min_diff = end_time[1] - start_time[1];
		let total_time = min_diff + hour_diff;
		if (total_time < 0) total_time *= -1;

		if (total_time < 60 && !overnight) {
			toast.error(`Shift duration should be minimum of 60min`, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			return;
		}
		const shifts = _.map(roster_details.dates, (date) => {
			const shift_from_time = moment(_roster_details.shift_from, 'HH:mm').format('HH:mm');
			const shift_to_time = moment(_roster_details.shift_to, 'HH:mm').format('HH:mm');
			const shift_from = moment(`${moment(date).format('DD-MM-YYYY')} ${shift_from_time}`, 'DD-MM-YYYY HH:mm');
			const shift_to = overnight
				? moment(`${moment(date).format('DD-MM-YYYY')} ${shift_to_time}`, 'DD-MM-YYYY HH:mm').add(1, 'day')
				: moment(`${moment(date).format('DD-MM-YYYY')} ${shift_to_time}`, 'DD-MM-YYYY HH:mm');
			return {
				shift_from: moment.utc(shift_from).format(),
				shift_to: moment.utc(shift_to).format(),
			};
		});

		const curent_stamp = moment().unix();
		for (const shift of shifts) {
			const shift_to_stamp = moment(shift.shift_to).unix();

			if (shift_to_stamp <= curent_stamp) {
				dispatch(hide_loader());
				toast.error(`Today shift cannot be less than current time`, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				return;
			}
		}

		api_requests
			.create_roster({
				hub_id: roster_details.hub_id,
				shifts,
				rider_ids: _.map(selected_riders, 'rider_id'),
				...(is_edit && { rider_shift_id: rider_info.shift_id }),
			})
			.then(() => {
				dispatch(hide_loader());
				show_toast(selected_riders.length);
				setTimeout(() => {
					show_toast(0);
				}, 5000);
				close(true);
			})
			.catch((err) => {
				dispatch(hide_loader());
			});
	};

	useEffect(() => {
		api_requests.get_bop_hubs({ master_zone_id: selected_master_zone_id, all: true }).then((res) => {
			set_hubs(res.data.data);
		});
	}, [selected_master_zone_id]);

	useEffect(() => {
		if (_.isEmpty(rider_info)) return;
		const selected_rider_info = {
			mobile: rider_info.mobile,
			rider_category: rider_info.rider_category,
			rider_id: rider_info.rider_id,
			rider_name: rider_info.rider_name,
		};
		const _roster_state = _.cloneDeep(roster_details);
		_roster_state.shift_from = rider_info.shift_start_24_time ? rider_info.shift_start_24_time : '';
		_roster_state.shift_to = rider_info.shift_end_24_time ? rider_info.shift_end_24_time : '';
		_roster_state.hub_id = rider_info.selected_hub;
		const next_7_date = moment().add(6, 'days').format('YYYY-MM-DD');
		const is_after = moment(rider_info.selected_date).isAfter(next_7_date, 'day');
		if (!is_after) _roster_state.dates = [rider_info.selected_date];
		set_selected_riders([selected_rider_info]);
		set_roster_details(_roster_state);
		set_overnight(rider_info.overnigth);
	}, []);

	useEffect(() => {
		if (!_.isEmpty(search_rider_id)) {
			api_requests
				.search_rider_without_zone({
					search: search_rider_id,
					is_active: true,
				})
				.then((res) => {
					set_search_riders(res.data);
				});
		} else {
			set_search_riders([]);
		}
	}, [search_rider_id]);

	const classes = useStyles();

	const show_close_modal = () => {
		if (
			roster_details.hub_id === '' &&
			_.isEmpty(selected_riders) &&
			_.isEmpty(roster_details.dates) &&
			_.isEmpty(roster_details.shift_to) &&
			_.isEmpty(roster_details.shift_from)
		) {
			close();
			return;
		}
		set_confirm_close_modal(true);
	};

	const btn_disabled = useMemo(() => {
		let value = true;

		if (
			roster_details.hub_id > 0 &&
			selected_riders.length > 0 &&
			!_.isEmpty(roster_details.dates) &&
			!_.isEmpty(roster_details.shift_to) &&
			!_.isEmpty(roster_details.shift_from)
		)
			value = false;

		if (_.isEmpty(rider_info)) return value;

		let value_updated = false;
		if (
			roster_details.hub_id != rider_info.selected_hub ||
			roster_details.dates != rider_info.selected_date ||
			roster_details.shift_from != rider_info.shift_start_24_time ||
			roster_details.shift_to != rider_info.shift_end_24_time ||
			selected_master_zone_id != zones[0].id ||
			overnight != rider_info.overnigth
		) {
			value_updated = true;
		}
		return value || !value_updated;
	}, [roster_details, selected_riders, rider_info, overnight]);

	const is_all_selected = () => {
		let flag = true;
		_.forEach([0, 1, 2, 3, 4, 5, 6], (slot) => {
			const _date = moment()
				.add(slot + 7 * calendar_slot, 'days')
				.format('YYYY-MM-DD');
			if (!_.includes(roster_details.dates, _date)) {
				flag = false;
			}
		});
		return flag;
	};

	useEffect(() => {
		set_all_selected(is_all_selected());
	}, [calendar_slot, roster_details]);
	return (
		<>
			<div className={classes.wrapper} onClick={show_close_modal} />
			<div className={classes.sub_wrapper}>
				<div className={classes.header}>
					<div className={classes.header_text_wrap}>
						<Text semi className={classes.header_text}>
							{!is_edit ? 'Create Roster' : 'Edit Roster'}
						</Text>
					</div>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<IconButton onClick={show_close_modal} style={{ border: 'none' }}>
							<img src={cross} width={16} height={16} />
						</IconButton>
					</div>
				</div>
				<Text bold className={classes.date_label} style={{ marginTop: 12, color: theme.colors.darkGrey7 }}>
					Roster Details
				</Text>
				<div className={classes.hub_box}>
					<div className={classes.input_row}>
						<FormControl style={{ width: '50%' }}>
							<Text semi className={classes.date_label}>
								Master Zone*
							</Text>
							<Select
								value={selected_master_zone_id}
								options={_.map(zones, (zone) => {
									return {
										title: zone.name,
										value: zone.id,
									};
								})}
								handleChange={handle_select_zone}
								containerStyles={{ width: 190, height: 40 }}
							/>
						</FormControl>
						<div style={{ width: '50%' }}>
							<Text semi className={classes.date_label}>
								Hub*
							</Text>
							<Select
								containerStyles={{ width: 190, height: 40 }}
								value={roster_details.hub_id}
								options={_.map(hubs, (hub, key) => {
									return {
										title: hub.title,
										value: hub.id,
									};
								})}
								defaultOption={{
									title: 'Select',
									value: null,
								}}
								handleChange={(e) => set_roster_details({ ...roster_details, hub_id: e })}
							/>
						</div>
					</div>

					<div className={classes.input_row}>
						<div className={classes.shift_wrap}>
							<div className={classes.shift_sub_wrap}>
								<div>
									<Text semi className={classes.date_label}>
										Start Time*
									</Text>
									<TimeInput
										disabled={disabled_start_time}
										value={roster_details.shift_from}
										handleChange={(val) => set_roster_details({ ...roster_details, shift_from: val })}
									/>
								</div>

								<div>
									<Text semi className={classes.date_label}>
										End Time*
									</Text>
									<TimeInput
										value={roster_details.shift_to}
										handleChange={(val) => set_roster_details({ ...roster_details, shift_to: val })}
									/>
								</div>

								<div>
									<Text semi className={classes.overnight_wrapper}>
										Overnight
										<BootstrapTooltip
											title={
												<div style={{ display: 'flex', flexDirection: 'column', gap: 8, padding: 5 }}>
													<Text bold style={{ fontSize: 14, color: theme.colors.primary }}>
														{'Overnight Shift'}
													</Text>
													<Text semi style={{ fontSize: 12, lineHeight: 1.5, color: theme.colors.primary }}>
														{'This allows you to create rosters for continuous days including night time shifts.'}
													</Text>
												</div>
											}
											placement='left'>
											<div className={classes.info_circle}>
												<img src={ImageLinks.info_purple} width={16} height={16} className='purple_info' />
												<img
													className='filled_purple_info'
													src={ImageLinks.info_filled_purple}
													width={16}
													height={16}
													style={{ display: 'none' }}
												/>
											</div>
										</BootstrapTooltip>
									</Text>

									<Switch
										checked={overnight}
										onChange={() => set_overnight((prev) => !prev)}
										icon={<img src={ImageLinks.over_night_circle_lightpurple} width={18} height={18} />}
										checkedIcon={<img src={ImageLinks.over_night_circle_purple} width={18} height={18} />}
									/>
								</div>
							</div>
						</div>
					</div>
					<div style={{ marginTop: 30 }}>
						<div className='d-flex align-items-center justify-content-between' style={{ marginBottom: 10 }}>
							<div className='d-flex'>
								<Text bold className={classes.date_label} style={{ marginBottom: 0 }}>
									Date(s)*
								</Text>
								<div style={{ marginLeft: 10 }}>
									<DatePicker
										selectsMultiple={true}
										selectedDates={roster_details.dates}
										dateFormat='YYYY-MM-DD'
										shouldCloseOnSelect={false}
										onChange={(dates) => handle_calender_date(dates)}
										customInput={
											<div className='d-flex'>
												<img src={ImageLinks.calendar_solid_circle} />
											</div>
										}
										minDate={new Date()}
										disabled={disabled_start_time}
									/>
								</div>
							</div>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<ContainedButton
									circle
									className={classes.date_arrow}
									disabled={calendar_slot == 0}
									onClick={() => set_calender_slot(calendar_slot - 1)}>
									<img src={ImageLinks.chevronLeft} className='purple-arrow' width='10' />
									<img src={ImageLinks.chevron_left_white} style={{ display: 'none' }} className='white-arrow' width='8' />
								</ContainedButton>

								<Text bold className={classes.date_text}>
									{moment()
										.add(7 * calendar_slot, 'days')
										.format('DD MMM `YY')}{' '}
									-{' '}
									{moment()
										.add(7 * calendar_slot + 6, 'days')
										.format('DD MMM `YY')}
								</Text>
								<ContainedButton
									circle
									className={classes.date_arrow}
									onClick={() => set_calender_slot(calendar_slot + 1)}
									disabled={disabled_start_time}>
									<img src={ImageLinks.chevronLeft} className='purple-arrow' width='10' style={{ transform: 'rotate(180deg)' }} />
									<img
										src={ImageLinks.chevron_left_white}
										style={{ display: 'none', transform: 'rotate(180deg)' }}
										className='white-arrow'
										width='8'
									/>
								</ContainedButton>
							</div>
						</div>
						<div className={classes.date_input_wrap}>
							{_.map([0, 1, 2, 3, 4, 5, 6], (slot, key) => {
								const date = moment().add(slot + 7 * calendar_slot, 'days');
								const isSelected = _.includes(roster_details.dates, date.format('YYYY-MM-DD'));
								return (
									<ListItem
										disabled={disabled_start_time}
										button
										key={`date${key}`}
										style={{ ...(isSelected && { background: theme.colors.primary }) }}
										className={classes.date_list_item}
										onClick={() => handle_date(slot + 7 * calendar_slot)}>
										<Text bold style={{ fontSize: 12, ...(isSelected && { color: 'white' }), width: 30, textAlign: 'center' }}>
											{date.format('ddd')}
										</Text>
										<Text bold className={classes.textDate}>
											{date.format('DD')}
										</Text>
									</ListItem>
								);
							})}

							<Checkbox
								disabled={disabled_start_time}
								size='medium'
								checked={all_selected}
								onClick={handle_select_all}
								style={{ color: theme.colors.lightPurple2, marginTop: 20, marginLeft: 10 }}
							/>
							<Text bold className={classes.select_all_text} style={{ opacity: disabled_start_time ? 0.5 : 1 }}>
								Select all
							</Text>
						</div>
						{roster_details.dates.length > 0 && (
							<div style={{ marginTop: 14 }}>
								<Text semi style={{ color: theme.colors.lightPurple7, fontSize: 12 }}>
									{`${roster_details.dates.length} selected : `}
									{_.map(_.slice(roster_details.dates, 0, 3), (date) => {
										return (
											<Text component={'span'} semi style={{ color: theme.colors.lightPurple7, fontSize: 12 }}>
												{moment(date).format('DD-MMM , ')}
											</Text>
										);
									})}
									{roster_details.dates.length > 3 && (
										<Text semi style={{ color: theme.colors.lightPurple7, fontSize: 12 }} component={'span'}>{`+ ${
											roster_details.dates.length - 3
										} more`}</Text>
									)}
								</Text>
							</div>
						)}
						{overnight && roster_details.dates.length > 0 && (
							<div className={classes.overnight_strip}>
								<img src={ImageLinks.over_night_purple} width={16} height={16} />
								<Text semi style={{ fontSize: 14 }}>
									Overnight rostered shifts end next day.
								</Text>
							</div>
						)}
					</div>
				</div>
				<div style={{ marginTop: 30 }}>
					{is_rider_editable && (
						<Text bold className={classes.date_label} style={{ color: theme.colors.darkGrey7 }}>
							Assign Riders
						</Text>
					)}
					<div className={classes.add_rider_wrap}>
						{is_rider_editable && (
							<div className={classes.add_rider_input_wrap}>
								<input
									id='input_search_filter'
									disabled={!is_rider_editable}
									placeholder='Search Rider Name or ID'
									className={classes.add_rider_input}
									value={search_rider_id}
									onChange={(e) => set_search_rider_id(e.target.value)}
								/>
								<img src={ImageLinks.search_light_pink} alt='' />
							</div>
						)}
						{is_rider_editable && (
							<div className={classes.upload_button}>
								{selected_riders.length === 0 && (
									<>
										<img
											src={ImageLinks.upload}
											className='purple-arrow'
											height={20}
											width={20}
											style={{ width: '100%', height: 'auto' }}></img>
										<BootstrapTooltip title='Upload Rider Mobile No.' placement='top'>
											<img
												src={ImageLinks.upload_white}
												onClick={() => input_ref.current.click()}
												className='white-arrow'
												height={20}
												width={20}
												style={{ width: '100%', height: 'auto', display: 'none' }}></img>
										</BootstrapTooltip>
									</>
								)}
								{selected_riders.length > 0 && (
									<div
										className={classes.upload_button}
										onClick={() => set_selected_riders([])}
										style={{ backgroundColor: theme.colors.lightPurple, margin: 0 }}>
										<img src={ImageLinks.refresh_single_arrow_purple}></img>
									</div>
								)}
								<input type='file' ref={input_ref} onChange={handle_upload_file} accept='.csv' style={{ display: 'none' }} />
							</div>
						)}
					</div>
					{!_.isEmpty(search_riders) && (
						<div className={classes.search_rider_wrap}>
							{_.map(search_riders, (rider, index) => {
								return (
									<ListItem
										button
										key={`menu${index}`}
										onClick={() => handle_select_rider(rider)}
										tabIndex={index}
										style={{ padding: '12px 20px', ...(index !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }) }}>
										<Text>{rider.rider_id}</Text>
										<Text style={{ marginLeft: 20 }}>{rider.rider_name}</Text>
									</ListItem>
								);
							})}
						</div>
					)}

					<div className={classes.rider_list_wrap}>
						{_.map(selected_riders, (rider, key) => {
							return (
								<div style={{ position: 'relative' }} key={`selected_riders${key}`}>
									<ListItem
										key={`rider${key}`}
										button
										className={classes.rider_list_item}
										style={{
											...(key !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }),
										}}
										onClick={() => {}}>
										<div className={classes.rider_info}>
											<Text bold className={classes.rider_id_text}>
												{rider.rider_id}
											</Text>
											<Text bold className={classes.rider_name_text}>
												{_.capitalize(rider.rider_name)}
											</Text>
										</div>
									</ListItem>
									{is_rider_editable && (
										<div className={classes.delete_btn} onClick={() => handle_select_rider(rider, true)}>
											<img src={ImageLinks.delete} style={{ marginRight: 10 }} width={20} height={20} />
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
				<div className={classes.create_btn_wrap}>
					<div>
						{selected_riders.length > 0 && (
							<Text bold style={{ fontSize: 16, color: theme.colors.darkGrey2 }}>
								{selected_riders.length > 1 ? `${selected_riders.length} Riders` : `${selected_riders.length} Rider`}
							</Text>
						)}
					</div>
					<Button
						type='round'
						text={!is_edit ? 'Create' : 'Update'}
						size='large'
						onClick={proceed_create_roster}
						disabled={btn_disabled}
						style={{ width: 110 }}
					/>
				</div>
				{confirm_close_modal && (
					<ConfirmClose
						headerTitle={!is_edit ? 'Exit Roster Create' : 'Exit Roster Update'}
						title={
							!is_edit ? 'Are you sure you want to close?' : 'Are you sure you want to abandon roster edit? No roster will be updated for rider.'
						}
						titleStyles={{ textAlign: 'left' }}
						close={() => set_confirm_close_modal(false)}
						on_confirm={close}
					/>
				)}
			</div>
		</>
	);
};

export default CreateRoster;
