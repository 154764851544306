import { makeStyles } from '@material-ui/styles';
import { Marker, Popup, useMap } from 'react-leaflet';
import { DivIcon } from 'leaflet';
import _ from 'lodash';

import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';

const useStyles = makeStyles({
	popup_wrapper: {
		position: 'absolute',
		top: '-53px',
		left: '-100px',
	},
	custom_popup: {
		padding: '12px 18px',
		borderRadius: 8,
		width: 125,
		height: 72,
		background: theme.colors.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},

	popup_animation: {
		animation: `$myEffect_on_poppup 1000ms `,
		animationFillMode: 'both',
	},
	'@keyframes myEffect_on_poppup': {
		'0%': {
			opacity: 1,
			transform: 'scale(1)',
		},
		'50%': {
			opacity: 1,
			transform: 'scale(1.2)',
		},

		'100%': {
			opacity: 1,
			transform: 'scale(0.7)',
		},
	},
});

const PopupComponent = ({ position, partner_name, stop_animation, key, text }) => {
	const classes = useStyles();
	let imag_url = '';
	switch (partner_name.toLowerCase()) {
		case 'zomato':
			imag_url = ImageLinks.zomato_logo_2;
			break;
		case 'porter':
			imag_url = ImageLinks.porter_logo_2;
			break;
		case 'shadowfax':
			imag_url = ImageLinks.shadowfax_logo_2;
			break;
		case 'zypp':
			imag_url = ImageLinks.zypp_loog;
			break;
		case 'loadshare':
			imag_url = ImageLinks.loadshare_logo;
			break;
		case 'borzo':
			imag_url = ImageLinks.borzo_logo;
			break;
		case 'ridding rangers':
			imag_url = ImageLinks.ridding_rangers;
			break;
		case 'truck partners':
			imag_url = ImageLinks.truck_delivery_partner;
			break;
		case 'road badge':
			imag_url = ImageLinks.road_badg_delivery_partner;
			break;
		default:
			imag_url = ImageLinks.logo;
	}
	return (
		<Marker
			key={key}
			position={position}
			icon={
				new DivIcon({
					html: `
					<div class=${classes.popup_wrapper}>
						<div class='${classes.custom_popup} ${!stop_animation ? classes.popup_animation : ''}'>
							<img src=${imag_url} height=26px style="width : 76px;" />
							<Text semi style={{ fontSize: 16, color: theme.colors.darkGrey7 }}>
								${text}
							</Text>
					        <img src=${ImageLinks.popup_arrow} style="width: 20px;position: absolute;top: 70px;" />
						</div>
					</div>`,
					iconAnchor: [-20, 24],
				})
			}></Marker>
	);
};

export default PopupComponent;
