export const STATUS_OPTION = [
	{ title: 'All', value: '' },
	{ title: 'Closed', value: 'CLOSED' },
	{ title: 'Open', value: 'OPEN' },
];

export const CATEGORY = [
	{ title: 'Wrong delivery address', value: 'FLM01' },
	{ title: 'Delay in delivery', value: 'FLM02' },
	{ title: 'Delayed delivery', value: 'FLM03' },
	{ title: 'Packaging', value: 'FLM04' },
	{ title: 'Buyer not found', value: 'FLM05' },
	{ title: 'Seller not found', value: 'FLM06' },
	{ title: 'Package info mismatch', value: 'FLM07' },
	{ title: 'Incorrectly marked as delivered', value: 'FLM08' },
];
