import React, { useEffect, useRef, Suspense, lazy } from 'react';
import { Route, Switch, Redirect, Router, Link, useHistory } from 'react-router-dom';
import { Provider, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import qs from 'qs';
import * as Sentry from '@sentry/react';

import constant from 'resources/constant';
import theme from 'resources/theme';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import Login from 'views/Login/Login';
import Routes from 'resources/Routes';
import _ from 'lodash';
import store from 'store';
import utils from 'resources/utils';
import { get_user_details, get_business_properties, user_details_received, login_failed, master_zones_received } from 'actions/app';
import browserHistory from 'browserHistory';
import { MuiThemeProvider } from '@material-ui/core';
import loadjs from 'loadjs';
import Loader from 'modules/Loader';
import RiderOverviewModal from 'modules/modals/RiderOverviewModal';
import api_requests from 'resources/api_requests';

import RiderProfile from 'views/AllRiders/components/RiderProfile/RiderProfile';

import Dashboard from 'views/Dashboard/Dashboard';

import AppSocket from 'resources/AppSocket';
import Sidebar from 'views/Sidebar/Sidebar';

import ReactGa from 'react-ga4';
import Mixpanel from 'mixpanel-browser';
import ProtectedRouts from 'resources/ProtectedRoute';
import AccessDeniedPage from 'views/AccessDeniedPage';

import PreLogin from 'views/PreLogin/PreLogin.js';
import PageNotFound from 'views/PageNotFound/PageNotFound';

import NotificationService from 'resources/NotificationService';
import Tracking from 'views/Tracking/Tracking';
import Fallback from 'modules/Fallback';

if (!constant.CONSOLE_LOG) console.log = () => {};
const AllRiders = lazy(() => import('views/AllRiders/AllRiders'));
const CashManagementDashboard = lazy(() => import('views/CashManagementFlow/CashManagementDashboard/CashManagementDashboard'));
const CM_AdminDetail = lazy(() => import('views/CashManagementFlow/CM_AdminDetail/CM_AdminDetail'));
const CM_AllAdmins = lazy(() => import('views/CashManagementFlow/CM_AllAdmins/CM_AllAdmins'));
const CM_AllRiders = lazy(() => import('views/CashManagementFlow/CM_AllRiders/CM_AllRiders'));
const CM_MyOverview = lazy(() => import('views/CashManagementFlow/CM_MyOverview/CM_MyOverview'));
const CM_RiderDetail = lazy(() => import('views/CashManagementFlow/CM_RiderDetail/CM_RiderDetail'));
const CreateOrder = lazy(() => import('views/CreateOrderFlow/CreateOrder/CreateOrder'));
const ManageHub = lazy(() => import('views/ManageHub/ManageHub'));
const ManagePickupAddress = lazy(() => import('views/ManagePickupAddress/ManagePickupAddress'));
const AllOrders = lazy(() => import('views/AllOrders/AllOrders'));
const Roster = lazy(() => import('views/Roster/Roster'));
const AllOrdersByRider = lazy(() => import('views/AllOrdersByRider/AllOrdersByRider'));
const GroupingAndAllocation = lazy(() => import('views/GroupingAndAllocation/GroupingAndAllocation'));
const MyWallet = lazy(() => import('views/MyWallet/MyWallet'));
const Settings = lazy(() => import('views/SettingsFlow/Settings/Settings'));
const Home = lazy(() => import('views/UserManagement/Home/Home'));
const AlertsListing = lazy(() => import('views/Alerts/AlertsListing/AlertsListing'));
const CreateAlert = lazy(() => import('views/Alerts/CreateAlert/CreateAlert'));
const OrderSettings = lazy(() => import('views/SettingsFlow/OrderSettings/OrderSettings'));
const ZonesDashboard = lazy(() => import('views/ZonesDashboard/ZonesDashboard'));
const PoweredWithPidge = lazy(() => import('views/PoweredWithPidge/PoweredWithPidge'));
const CommManagement = lazy(() => import('views/CommManagementFlow/CommManagement/CommManagement'));
const CreateCommunication = lazy(() => import('views/CommManagementFlow/CreateCommunication/CreateCommunication'));
const IntegrationManagement = lazy(() => import('views/IntegrationManagement/IntegrationManagement'));
const GenerateToken = lazy(() => import('views/IntegrationManagement/components/GenerateToken/GenerateToken'));
const EditToken = lazy(() => import('views/IntegrationManagement/components/EditToken.jsx/EditToken'));
const AdvancedOrderAnalytics = lazy(() => import('views/AdvancedOrderAnalytics/AdvancedOrderAnalytics'));
const AdvancedRiderAnalytics = lazy(() => import('views/AdvancedRiderAnalytics/AdvancedRiderAnalytics'));
const RiderSettings = lazy(() => import('views/SettingsFlow/RiderSettings/RiderSettings'));
const RepeatOrders = lazy(() => import('views/RepeatOrdersFlow/RepeatOrders'));
const EditRider = lazy(() => import('views/AllRiders/EditRider/EditRider'));
const NetworkListing = lazy(() => import('views/NetworksFlow/NetworkListing/NetworksListing'));
const CreateNetwork = lazy(() => import('views/NetworksFlow/CreateNetwork/CreateNetwork'));
const InviteParticipant = lazy(() => import('views/NetworksFlow/InviteParticipant/InviteParticipant'));
const FleetManagement = lazy(() => import('views/FleetManagement/FleetManagement'));
const MobileView = lazy(() => import('modules/MobileView'));
const TraceListing = lazy(() => import('views/TraceManagementFlow/TraceListing/TraceListing'));
const WhitelabellingSettings = lazy(() => import('views/SettingsFlow/WhitelabellingSetting/WhitelabellingSetting'));
const ChildBusinessList = lazy(() => import('views/ChildBusinessFlow/ChildBusinessList/ChildBusinessList'));
const ShopifyAuth = lazy(() => import('views/ShopifyAuth/ShopifyAuth'));
const SmartAllocationSettings = lazy(() => import('views/SettingsFlow/OrderSettings/components/SmartAllocationSettings/SmartAllocationSettings'));
const AutoManifestSettings = lazy(() => import('views/SettingsFlow/OrderSettings/components/AutoManifestSettings/AutoManifestSettings'));
const IGMIssue = lazy(() => import('views/IGMIssue/IgmIssue'));

const NetworkContextProvider = lazy(() => import('views/NetworksFlow/NetworkContextProvider'));

class App extends React.Component {
	componentDidMount() {
		ReactGa.initialize(constant.ENVIRONMENT == constant.env.PRODUCTION ? constant.GA4_KEY_PROD : constant.GA4_KEY_DEV, {
			debug: false,
		});
		Mixpanel.init(constant.ENVIRONMENT == constant.env.PRODUCTION ? constant.MIX_PANEL_KEY_PROD : constant.MIX_PANEL_KEY_DEV, {
			debug: false,
			track_pageview: false,
			persistence: 'localStorage',
		});
		loadjs(`https://maps.googleapis.com/maps/api/js?sensor=false&key=${constant.GOOGLE_MAP_KEY}&v=3.exp&libraries=places,drawing,marker`);
	}
	render() {
		return (
			<Sentry.ErrorBoundary fallback={<Fallback />}>
				<MuiThemeProvider theme={theme}>
					<ToastContainer />
					<Provider store={store}>
						<Router history={browserHistory}>
							<Suspense fallback={<Loader visible={true} />}>
								<Switch>
									<Route path='/login' component={Login} />
									<Route path='/' component={PrimaryComponent} />
									<Route component={PageNotFound} />
									<Redirect to='/' />
								</Switch>
							</Suspense>
						</Router>
					</Provider>
				</MuiThemeProvider>
			</Sentry.ErrorBoundary>
		);
	}
}

const PrimaryComponent = () => {
	const history = useHistory();
	const query_object = qs.parse(history.location.search.slice(1));
	const { app } = useSelector((state) => state, shallowEqual);
	const [is_mobile, set_is_mobile] = React.useState(window.innerWidth <= 768);
	const dispatch = useDispatch();

	useEffect(() => {
		if (query_object?.utm_source) {
			utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.UTM_SOURCE}=${query_object?.utm_source}`);
			utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.UTM_MEDIUM}=${query_object?.utm_medium}`);
			utils.track_event_for_analytics(`${constant.TRACKING_EVENTS.UTM_CAMPAIGN}=${query_object?.utm_campaign}`);
		}
		dispatch(get_user_details());
		window.addEventListener('resize', handleResize);
		window.addEventListener('focus', handle_focus);

		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('focus', handle_focus);
		};
	}, []);

	// useEffect(() => {
	// 	if (app.selected_business_id) {
	// 		dispatch(get_business_properties());
	// 	}
	// }, [app.selected_business_id]);

	const handle_focus = () => {
		NotificationService.reset_favicon();
	};

	const handleResize = () => {
		set_is_mobile(window.innerWidth <= 768); // Adjust the threshold as needed
	};

	const is_mobile_user_agent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent);
	if (is_mobile || is_mobile_user_agent) return <MobileView />;

	if (app.status === constant.REDUX_STATE.IDLE) {
		return null;
	}

	if (app.status !== constant.REDUX_STATE.SUCCEEDED) {
		const is_login_flage = _.includes(constant.ALLOWED_ORIGINS, window?.location?.origin);
		return (
			<Switch>
				<Route path='/' exact component={is_login_flage ? PreLogin : Login} />
				<Route path={Routes.LOGIN.path} exact={Routes.LOGIN.exact} component={Login} />
				<Redirect to='/' />
			</Switch>
		);
	}
	return <MainComponent />;
};

const MainComponent = () => {
	const { app } = useSelector((state) => state, shallowEqual);
	const [rider_data, set_rider_data] = React.useState(null);
	const [open, setOpen] = React.useState(true);
	const history = useHistory();
	const dispatch = useDispatch();
	const query_object = qs.parse(history.location.search.slice(1));

	useEffect(() => {
		const biz_config = app.app_configs.business_configs[app.selected_business_id];
		if (!_.isEmpty(biz_config?.whitelabeling)) theme.colors.sideNavPrimary = biz_config?.whitelabeling?.primary_color;
		else theme.colors.sideNavPrimary = theme.colors.primary;
	}, [app.app_configs]);

	useEffect(() => {
		const name = utils._retrieveData(constant.NAME);
		const mobile = utils._retrieveData(constant.MOBILE);
		const user_id = utils._retrieveData(constant.USER_ID);
		const user_type = utils._retrieveData(constant.TYPE_OF_USER);
		const user_email = utils._retrieveData(constant.USER_EMAIL);
		const user_teams = app.user_teams;
		const auth_token = utils._retrieveData(constant.AUTH_TOKEN);
		const type_of_onwer = utils._retrieveData(constant.TYPE_OF_OWNER).id;

		if (auth_token) {
			AppSocket.initialize({
				params: { token: auth_token },
				on_socket_connected: () => {
					console.log('Socket connected');
					for (let i = 0; i < app.business_accounts.length; i++) {
						const element = app.business_accounts[i];
						let room = `${element.id}@${type_of_onwer}`;
						AppSocket.join_room(room);
					}
					for (let i = 0; i < user_teams.length; i++) {
						const element = user_teams[i];
						let team_channel = `team_ch_${element.team_id}`;
						AppSocket.join_room(team_channel);
					}
					AppSocket.join_room(`user_ch_${user_id}`);
				},
			});
		}
		if (name) {
			dispatch(user_details_received(name, mobile, user_id, user_type, user_email));
		} else {
			dispatch(login_failed({ logout: false }));
		}
		// if (!_.isEmpty(query_object.shopify_token))
		// 	history.push(Routes.SHOPIFY_AUTH.path + `?shopify_token=${query_object.shopify_token}&is_login=${query_object.is_login}`);
	}, []);

	useEffect(() => {
		(async () => {
			try {
				dispatch(master_zones_received([]));
				const zones = (await api_requests.get_master_zones(app.selected_business_id)).data;
				dispatch(master_zones_received(zones));
			} catch (err) {}
		})();
	}, [app.selected_business_id, app.refresh_master_zones]);

	useEffect(() => {
		const event_string =
			'page_view_' + history.location.pathname.split('/')[1] + (history.location.search != '' ? '_' + history.location.search.split('=')[1] : '');
		utils.track_event_for_analytics(event_string.split('-').join('_'));
	}, [history.location]);

	if (_.isEmpty(app.zones)) {
		return null;
	}

	if (!app.user_details.name) {
		return null;
	}
	return (
		<div style={{ flex: 1, display: 'flex', backgroundColor: theme.colors.sideNavPrimary, height: '100%' }}>
			<div style={open ? { width: 240 } : { width: 72 }}>
				<Sidebar set_rider_data={set_rider_data} open={open} setOpen={setOpen} />
			</div>
			<div
				key={app.selected_business_id}
				style={{
					flex: 1,
					height: '100%',
					position: 'relative',
					background: '#FAFAFA',
					borderTopLeftRadius: 30,
					overflow: 'hidden',
					background: theme.colors.white,
				}}>
				<Switch>
					{utils._retrieveData(constant.AUTH_TOKEN) &&
						_.map(
							[
								{
									path: Routes.ALL_ORDERS.path,
									exact: Routes.ALL_ORDERS.exact,
									component: AllOrders,
								},
								{
									path: Routes.POWERED_WITH_PIDGE.path,
									exact: Routes.POWERED_WITH_PIDGE.exact,
									component: PoweredWithPidge,
								},
								{
									path: Routes.CREATE_ORDER.path,
									exact: Routes.CREATE_ORDER.exact,
									component: CreateOrder,
								},
								{
									path: Routes.TRACKING.path,
									exact: Routes.TRACKING.exact,
									component: Tracking,
								},
								{
									path: Routes.SETTINGS.path,
									exact: Routes.SETTINGS.exact,
									component: Settings,
								},
								{
									path: Routes.ORDER_SETTINGS.path,
									exact: Routes.ORDER_SETTINGS.exact,
									component: OrderSettings,
								},
								{
									path: Routes.RIDER_SETTINGS.path,
									exact: Routes.RIDER_SETTINGS.exact,
									component: RiderSettings,
								},
								{
									path: Routes.WHITELABELLING_SETTINGS.path,
									exact: Routes.WHITELABELLING_SETTINGS.exact,
									component: WhitelabellingSettings,
								},
								{
									path: Routes.ALL_RIDERS.path,
									exact: Routes.ALL_RIDERS.exact,
									component: AllRiders,
								},
								{
									path: Routes.ALL_ORDERS_BY_RIDER.path,
									exact: Routes.ALL_ORDERS_BY_RIDER.exact,
									component: AllOrdersByRider,
								},
								{
									path: Routes.GROUPING_AND_ALLOCATION.path,
									exact: Routes.GROUPING_AND_ALLOCATION.exact,
									component: GroupingAndAllocation,
								},
								{
									path: Routes.ROSTER.path,
									exact: Routes.ROSTER.exact,
									component: Roster,
								},
								{
									path: Routes.MANGE_HUB.path,
									exact: Routes.MANGE_HUB.exact,
									component: ManageHub,
								},
								{
									path: Routes.MANGE_PICKUP_ADDRESS.path,
									exact: Routes.MANGE_PICKUP_ADDRESS.exact,
									component: ManagePickupAddress,
								},
								{
									path: Routes.CASH_MANAGEMENT.path,
									exact: Routes.CASH_MANAGEMENT.exact,
									component: CashManagementDashboard,
								},
								{
									path: Routes.CASH_MANAGEMENT_MY_OVERVIEW.path,
									exact: Routes.CASH_MANAGEMENT_MY_OVERVIEW.exact,
									component: CM_MyOverview,
								},
								{
									path: Routes.CASH_MANAGEMENT_ALL_RIDERS.path,
									exact: Routes.CASH_MANAGEMENT_ALL_RIDERS.exact,
									component: CM_AllRiders,
								},
								{
									path: Routes.CASH_MANAGEMENT_RIDER_DETAIL.path,
									exact: Routes.CASH_MANAGEMENT_RIDER_DETAIL.exact,
									component: CM_RiderDetail,
								},
								{
									path: Routes.CASH_MANAGEMENT_ALL_ADMIN.path,
									exact: Routes.CASH_MANAGEMENT_ALL_ADMIN.exact,
									component: CM_AllAdmins,
								},
								{
									path: Routes.CASH_MANAGEMENT_ADMiN_DETAIL.path,
									exact: Routes.CASH_MANAGEMENT_ADMiN_DETAIL.exact,
									component: CM_AdminDetail,
								},
								{
									path: Routes.MY_WALLET.path,
									exact: Routes.MY_WALLET.exact,
									component: MyWallet,
								},
								{
									path: Routes.USER_MANAGEMENT.path,
									exact: Routes.USER_MANAGEMENT.exact,
									component: Home,
								},
								{
									path: Routes.HOME.path,
									exact: Routes.HOME.exact,
									component: Dashboard,
								},
								{
									path: Routes.MY_ALERTS.path,
									exact: Routes.MY_ALERTS.exact,
									component: AlertsListing,
								},
								{
									path: Routes.CREATE_ALERT.path,
									exact: Routes.CREATE_ALERT.exact,
									component: CreateAlert,
								},
								{
									path: Routes.COMMUNICATION_MANAGEMENT.path,
									exact: Routes.COMMUNICATION_MANAGEMENT.exact,
									component: CommManagement,
								},
								{
									path: Routes.CREATE_COMMUNICATION.path,
									exact: Routes.CREATE_COMMUNICATION.exact,
									component: CreateCommunication,
								},
								{
									path: Routes.RIDER_PROFILE.path,
									exact: Routes.RIDER_PROFILE.exact,
									component: RiderProfile,
								},
								{
									path: Routes.DASHBOARD.path,
									exact: Routes.DASHBOARD.exact,
									component: Dashboard,
								},
								{
									path: Routes.ZONES.path,
									exact: Routes.ZONES.exact,
									component: ZonesDashboard,
								},
								{
									path: Routes.INTEGRATION_MANAGEMENT.path,
									exact: Routes.INTEGRATION_MANAGEMENT.exact,
									component: IntegrationManagement,
								},
								{
									path: Routes.GENERATE_TOKEN.path,
									exact: Routes.GENERATE_TOKEN.exact,
									component: GenerateToken,
								},
								{
									path: Routes.EDIT_TOKEN.path,
									exact: Routes.EDIT_TOKEN.exact,
									component: EditToken,
								},
								{
									path: Routes.ADVANCED_ORDER_ANALYTICS.path,
									exact: Routes.ADVANCED_ORDER_ANALYTICS.exact,
									component: AdvancedOrderAnalytics,
								},
								{
									path: Routes.ADVANCED_RIDER_ANALYTICS.path,
									exact: Routes.ADVANCED_RIDER_ANALYTICS.exact,
									component: AdvancedRiderAnalytics,
								},
								{
									path: Routes.REPEAT_ORDERS.path,
									exact: Routes.REPEAT_ORDERS.exact,
									component: RepeatOrders,
								},
								{
									path: Routes.EDIT_RIDER.path,
									exact: Routes.EDIT_RIDER.exact,
									component: EditRider,
								},
								{
									path: Routes.NETWORKS.path,
									exact: Routes.NETWORKS.exact,
									component: NetworkListing,
								},
								{
									path: Routes.FLEET_MANAGEMENT.path,
									exact: Routes.FLEET_MANAGEMENT.exact,
									component: FleetManagement,
								},
								{
									path: Routes.TRACE_LISTING.path,
									exact: Routes.TRACE_LISTING.exact,
									component: TraceListing,
								},
								{
									path: Routes.CHILD_BUSINESS.path,
									exact: Routes.CHILD_BUSINESS.exact,
									component: ChildBusinessList,
								},
								{
									path: Routes.SHOPIFY_AUTH.path,
									exact: Routes.SHOPIFY_AUTH.exact,
									component: ShopifyAuth,
								},
								{
									path: Routes.ACCESS_DENIED.path,
									exact: Routes.ACCESS_DENIED.exact,
									component: AccessDeniedPage,
								},
								{
									path: Routes.SMART_ALLOCATION.path,
									exact: Routes.SMART_ALLOCATION.exact,
									component: SmartAllocationSettings,
								},
								{
									path: Routes.SMART_MANIFEST.path,
									exact: Routes.SMART_MANIFEST.exact,
									component: AutoManifestSettings,
								},
								{
									path: Routes.IGM_ISSUE.path,
									exact: Routes.IGM_ISSUE.exact,
									component: IGMIssue,
								},
							],
							(route, idx) => {
								return <Route key={idx} path={route.path} exact={route.exact} component={route.component} />;
								// return <ProtectedRouts key={idx} path={route.path} exact={route.exact} component={route.component} />;
							},
						)}
					{/* <Route path='/access-denied' component={AccessDeniedPage} />
					<Route path='*' component={AccessDeniedPage} /> */}
					{history.location.pathname !== Routes.CREATE_NETWORK.path && !_.includes(history.location.pathname, '/network') && (
						<Route component={PageNotFound} />
					)}
					<NetworkContextProvider>
						<Route exact={Routes.CREATE_NETWORK.exact} path={Routes.CREATE_NETWORK.path} component={CreateNetwork} />
						<Route exact={Routes.INVITE_NETWORK.exact} path={Routes.INVITE_NETWORK.path} component={InviteParticipant} />
					</NetworkContextProvider>

					<Redirect to='/' />
				</Switch>
			</div>
			{!_.isEmpty(rider_data) && <RiderOverviewModal rider_data={rider_data} set_rider_data={set_rider_data} close={() => set_rider_data(null)} />}
		</div>
	);
};

export default App;
