import React from 'react';
import Text from './Text';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
	item_wrap: { marginRight: 20, display: 'flex', alignItems: 'center' },
	outer_circle: {
		width: 14.22,
		height: 14.22,
		borderRadius: 7.11,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	label_text: { fontSize: 14, marginLeft: 4 },
}));

const RadioBox = ({ wrapper_styles, options, selected_item, on_item_press, disabled = false }) => {
	const classes = useStyles();
	return (
		<div className='d-flex' style={wrapper_styles}>
			{_.map(options, (item, index) => {
				const is_selected = selected_item === item.value;
				return (
					<div
						className={classes.item_wrap}
						style={{ cursor: disabled ? 'default' : 'pointer' }}
						onClick={() => {
							if (!disabled) on_item_press(item.value);
						}}>
						<div
							className={classes.outer_circle}
							style={{
								backgroundColor: is_selected ? theme.colors.white : theme.colors.lightPurple6,
								border: `2px solid ${is_selected ? theme.colors.primary : theme.colors.lightPurple6}`,
							}}>
							{is_selected && <div style={{ width: 6, height: 6, borderRadius: 3, backgroundColor: theme.colors.primary }}></div>}
						</div>
						<Text
							//commenting for now if it has to be reverted
							// semi={is_selected}
							// medium={!is_selected}
							// style={{ color: is_selected ? theme.colors.primary : theme.colors.lightPurple6 }}>
							semi={true}
							className={classes.label_text}
							style={{ color: theme.colors.black }}>
							{item.label}
						</Text>
						{item.subtitle ? (
							<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2, marginLeft: 2 }}>
								{`${item.subtitle}`}
							</Text>
						) : null}
					</div>
				);
			})}
		</div>
	);
};

export default RadioBox;
