const { makeStyles } = require('@material-ui/core');
export const useStyles = makeStyles((theme) => ({
	closeContainer: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	container: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100%',
		width: 500,
		backgroundColor: 'white',
		borderRadius: '35px 0px 0px 0px',
		padding: '30px 30px 27px 30px',
		zIndex: 1200,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 10,
		boxShadow: '0px 0px 16px 0px rgba(102, 102, 102, 0.10)',
	},
	pbidContainer: {
		fontSize: 12,
		color: theme.colors.primary,
	},
	footer_wrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	download_btn: {
		width: 41,
		height: 41,
		borderRadius: 30,
		cursor: 'pointer',
		boxShadow: 'none',
	},
	donwload_report_text: { fontSize: 16, display: 'none', color: theme.colors.primary, marginLeft: 6 },
	emptyContainer: { flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'auto', marginTop: 16 },
	search_bar_container: {
		height: 40,
		border: `1px solid ${theme.colors.lightPurple6}`,
		borderRadius: 36,
		background: theme.colors.white,
		display: 'flex',
		alignContent: 'center',
	},
	date_wrapper: {
		border: `1px solid ${theme.colors.lightPurple}`,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		padding: '8px 12px',
		borderRadius: 36,
		display: 'flex',
		alignItems: 'center',
		width: 130,
		justifyContent: 'space-between',
		height: 36,
	},
	input_filed: {
		border: 'none',
		outline: 'none',
		background: theme.colors.white,
		color: theme.colors.black,
		width: '100%',
		fontSize: 16,
	},
	not_text_filters_wrapper: {
		flex: 1,
		overflowY: 'auto',
		marginBottom: 20,
		marginTop: 10,
		paddingBottom: 20,
	},
	not_text_filters_text: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	multi_date_picker: {
		display: 'flex',
		width: 200,
		padding: '8px 12px',
		justifyContent: 'space-between',
		border: `1px solid ${theme.colors.lightPurple}`,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		borderRadius: 36,
		alignItems: 'center',
	},
	delivery_date: {
		display: 'flex',
		alignItems: 'center',
		minHeight: 36,
		marginTop: 5,
	},
	report_btn_text: {
		fontSize: 16,
		color: theme.colors.primary,
		display: 'none',
	},
	report_btn: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: 6,
		padding: 10,
		background: theme.colors.lightPurple,
		borderRadius: 30,
		cursor: 'pointer',
		'&:hover': {
			background: theme.colors.lightPurple,
			padding: '6px 12px 6px 10px',
			'& $report_btn_text': {
				display: 'inline !important',
			},
		},
	},
	disabled_button: {
		pointerEvents: 'none',
		cursor: 'auto',
		background: theme.colors.lightGrey5,
	},
}));
