import React, { useEffect, useRef, useState } from 'react';
import { Circle, MapContainer, TileLayer, Marker, useMap, Polyline } from 'react-leaflet';
import { DivIcon, polyline } from 'leaflet';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import theme from 'resources/theme';
import Button from 'modules/Button';
import FindMyPartners from './components/FindMyPartners';
import usePreLogin from './usePreLogin';
import PopupComponent from './components/Popup';
import FindMyRoutes from './components/FindRoutes';
import Routes from 'resources/Routes';
import utils from 'resources/utils';
import constant from 'resources/constant';
import Loader from 'modules/Loader';

const useStyles = makeStyles({
	wrapper: { height: '100%', width: '100%', background: theme.colors.white, padding: '20px 30px', overflow: 'hidden' },
	header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 100px', marginBottom: 18 },
	text_wrapper: {
		position: 'absolute',
		top: '40%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 1,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 30,
	},
	button_wrapper: {
		display: 'flex',
		gap: 30,
		justifyContent: 'center',
		position: 'absolute',
		zIndex: 1000,
		width: '95%',
		top: '48vh',
	},
	button_header: {
		display: 'flex',
		alignItems: 'center',
		padding: '8px 22px',
		justifyContent: 'center',
		background: theme.colors.primary,
		borderRadius: 6,
		cursor: 'pointer',
	},
	button_main: {
		display: 'flex',
		alignItems: 'center',
		padding: '16px 44px',
		justifyContent: 'center',
		background: theme.colors.primary,
		borderRadius: 12,
		cursor: 'pointer',
	},
	map_container: {
		borderRadius: 12,
		height: '87vh',
		filter: 'drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.16))',
	},
	animatedItem: {
		animation: `$myEffect 1000ms `,
		animationFillMode: 'both',
		// animationTimingFunction: 'ease-in-out',
	},
	'@keyframes myEffect': {
		'0%': {
			top: '48vh',
			transform: 'scale(1)',
		},
		'85%': {
			top: '0px',
			transform: 'scale(0.59)',
		},

		'100%': {
			top: '10px',
			transform: 'scale(0.59)',
		},
	},
	animatedItemOnText: {
		animation: `$myEffectOnText 1000ms `,
		animationFillMode: 'both',
		animationTimingFunction: 'ease-in-out',
	},
	'@keyframes myEffectOnText': {
		'0%': {
			opacity: 1,
			transform: 'translateY(0%)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateY(-250%) scale(0.59)',
			display: 'none',
		},
	},
	popup_wrapper: {
		position: 'absolute',
		top: '-53px',
		left: '-100px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		textAlign: 'center',
	},
	custom_popup: {
		padding: '12px 18px',
		borderRadius: 8,
		width: 155,
		height: 86,
		background: theme.colors.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		gap: 5,
	},
	sign_up_free_wrapper: {
		position: 'absolute',
		padding: 12,
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1000,
		display: 'flex',
		background: theme.colors.white,
		borderRadius: 10,
		boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)',
		bottom: '5%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	animatedItemOnSignUp: {
		animation: `$myEffectOnSignUp 1000ms forwards `,
	},
	'@keyframes myEffectOnSignUp': {
		'0%': {
			opacity: 1,
			transform: 'translate(-50%, -50%) scale(1)',
		},
		'100%': {
			opacity: 1,
			transform: 'translate(-50%, -50%) scale(0.8)',
		},
	},
});

const options = {
	noClip: false,
	color: theme.colors.lightBlue,
	stroke: true,
	weight: 5,
};

const PreLogin = () => {
	const classes = useStyles();
	const map_ref = useRef(null);
	const history = useHistory();
	const { app } = useSelector((state) => state, shallowEqual);
	const {
		pickup_point,
		drop_point,
		show_circle,
		selected_point,
		map_data,
		selected_rider,
		routes_drop_points,
		button,
		create_route,
		show_marker,
		set_pickup_point,
		set_drop_points,
		handle_fetch_partner,
		set_selected_point,
		set_selected_rider,
		generate_random_routes_points,
		set_button,
		handle_create_routes,
		set_create_route,
		set_show_circle,
		fetch_location_from_ip,
		generate_random_point,
	} = usePreLogin();
	const [circles, setCircles] = useState([]);
	const [popups, setPopups] = useState([]);

	useEffect(() => {
		if (!_.isEmpty(routes_drop_points)) {
			const map = map_ref.current;
			const markerCoordinates = [];
			routes_drop_points.forEach((marker) => {
				const { latitude, longitude } = marker;
				markerCoordinates.push([latitude, longitude]);
			});
			map.fitBounds(markerCoordinates, { padding: [10, 150] });
		}
	}, [routes_drop_points]);

	useEffect(() => {
		if (show_circle) {
			const timeoutIds = [];
			for (const key in map_data) {
				const circleData = map_data[key];
				const timeoutId = setTimeout(() => {
					setCircles((prevCircles) => [
						...prevCircles,
						<Circle
							key={key}
							center={[pickup_point.coords.latitude, pickup_point.coords.longitude]}
							pathOptions={{
								fillColor: theme.colors.lightGreen3,
								color: theme.colors.primary,
								weight: 0,
								fillOpacity: 0.2,
							}}
							radius={circleData.radius}
						/>,
					]);
					setPopups((prevPopups) => [
						...prevPopups,
						...circleData.partners.map((partner, index) => (
							<PopupComponent
								position={partner.center}
								key={`${key}-popup-${index}`}
								partner_name={partner.name}
								stop_animation={key == '10KM'}
								text={partner.text}
							/>
						)),
					]);
				}, circleData.delay);
				timeoutIds.push(timeoutId);
			}
			return () => {
				timeoutIds.forEach((timeoutId) => {
					clearTimeout(timeoutId);
				});
			};
		}
	}, [show_circle]);

	function ChangeView() {
		const map = useMap();
		if ((button == 'Find Partners' || _.isEmpty(button)) && !show_circle) {
			const markerCoordinates = [
				[pickup_point.coords.latitude, pickup_point.coords.longitude],
				[drop_point.coords.latitude, drop_point.coords.longitude],
			];
			map.fitBounds(markerCoordinates, { padding: [0, 150] });
		}
		if (show_circle) {
			const center = { lat: pickup_point.coords.latitude, lng: pickup_point.coords.longitude };
			map.setView(center, 15);
		}
		return null;
	}

	const login_click = () => {
		history.push(Routes.LOGIN.path);
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.PRE_LOGIN);
	};
	const find_partners_click = () => {
		set_button('Find Partners');
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.PRE_FIND_PARTNER);
	};

	const opt_routes_click = () => {
		set_button('Optimize Routes');
		set_show_circle(false);
		setCircles([]);
		setPopups([]);
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.PRE_OPT_ROUTES);
	};

	const sign_up_click = () => {
		history.push(Routes.LOGIN.path);
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.PRE_SINGUP);
	};
	return (
		<div className={classes.wrapper}>
			<Loader visible={app.is_loading} />
			<div className={classes.header}>
				<img src={ImageLinks.logo} alt='logo' width={96} height={40} />

				<div className='d-flex justify-content-center align-items-center'>
					<Text semi style={{ fontSize: 14, color: theme.colors.black }}>
						Already a member?
					</Text>
					<Button type='text' size='medium' text='Login' right_icon={ImageLinks.arrow_right_purple} onClick={login_click} />
				</div>
			</div>
			{button == 'Find Partners' && (
				<FindMyPartners
					pickup_point={pickup_point}
					drop_point={drop_point}
					set_pickup_point={set_pickup_point}
					set_drop_points={set_drop_points}
					handle_fetch_partner={handle_fetch_partner}
					show_circle={show_circle}
					map_data={map_data}
					fetch_location_from_ip={fetch_location_from_ip}
				/>
			)}
			{button == 'Optimize Routes' && (
				<FindMyRoutes
					selected_point={selected_point}
					set_selected_point={set_selected_point}
					selected_rider={selected_rider}
					set_selected_rider={set_selected_rider}
					generate_random_routes_points={generate_random_routes_points}
					create_route={create_route}
					handle_create_routes={handle_create_routes}
					set_create_route={set_create_route}
					map_ref={map_ref}
				/>
			)}
			<div className={classes.text_wrapper}>
				<Text
					semi
					style={{ fontSize: 50, color: theme.colors.darkGrey7, display: 'flex', justifyContent: 'center' }}
					className={!_.isEmpty(button) && classes.animatedItemOnText}>
					Test Drive Your Delivery Success
				</Text>
			</div>
			<div className={clsx(classes.button_wrapper, !_.isEmpty(button) && classes.animatedItem)}>
				<div
					onClick={find_partners_click}
					style={{
						background: button == 'Find Partners' || _.isEmpty(button) ? theme.colors.primary : theme.colors.lightPurple7,
					}}
					className={classes.button_main}>
					<Text semi style={{ fontSize: 20, color: theme.colors.white }}>
						Find Partners
					</Text>
				</div>
				<div
					onClick={opt_routes_click}
					className={classes.button_main}
					style={{
						background: button == 'Optimize Routes' ? theme.colors.primary : theme.colors.lightPurple7,
					}}>
					<Text semi style={{ fontSize: 20, color: theme.colors.white }}>
						Optimize Routes
					</Text>
				</div>
			</div>
			<div className={clsx(classes.sign_up_free_wrapper, !_.isEmpty(button) && classes.animatedItemOnSignUp)}>
				<Text semi style={{ fontSize: 14, color: theme.colors.black }}>
					Ready to enable your deliveries?
				</Text>
				<Button type='text' text='Sign Up for Free' size='large' right_icon={ImageLinks.arrow_right_purple} onClick={sign_up_click} />
			</div>
			<div className={classes.map_container}>
				<MapContainer
					ref={map_ref}
					center={{ lat: pickup_point.coords.latitude, lng: pickup_point.coords.longitude }}
					style={{ position: 'relative', outlineStyle: 'none' }}>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
					/>
					{!_.isEmpty(create_route) && button == 'Optimize Routes' && (
						<>
							{_.map(create_route, (line, key) => {
								return (
									<Polyline
										positions={[
											_.map(
												[{ latitude: pickup_point.coords.latitude, longitude: pickup_point.coords.longitude }, ...line.points],
												(item) => [item.latitude, item.longitude],
											),
										]}
										pathOptions={{
											noClip: false,
											color: key == 0 ? theme.colors.lightBlue : key == 1 ? theme.colors.orange : theme.colors.red,
											stroke: true,
											weight: 3,
										}}
									/>
								);
							})}
						</>
					)}

					<ChangeView />
					{show_circle && circles}
					{show_circle && popups}
					{!_.isEmpty(button) && (
						<Marker
							icon={
								new DivIcon({
									html: `<div>
								<img src="${ImageLinks.rider_pickup}"
								width="32px" height="32px"
								/>
								</div>`,
									iconAnchor: [12, 24],
								})
							}
							position={[pickup_point.coords.latitude, pickup_point.coords.longitude]}></Marker>
					)}
					{button == 'Find Partners' && (
						<Marker
							icon={
								new DivIcon({
									html: `<div>
								<img src="${ImageLinks.marker_down_arrow}"
								width="32px" height="32px"
								/>
								</div>`,
									iconAnchor: [12, 24],
								})
							}
							position={[drop_point?.coords?.latitude, drop_point?.coords?.longitude]}></Marker>
					)}
					{button == 'Optimize Routes' &&
						_.map(routes_drop_points, (item, key) => {
							return (
								<Marker
									icon={
										new DivIcon({
											html: `
											<div>
												<img src="${item?.sequence && !_.isEmpty(create_route) ? ImageLinks.marker_purple : ImageLinks.marker_down_arrow}"width="32px" height="32px" />
												<span style="position:absolute;color:white;top:4px;font-size:12px;width:20px;text-align:center;left:2px; display: ${
													item?.sequence && !_.isEmpty(create_route) ? '' : 'none'
												};">${item?.sequence}</span>
											</div>`,
											iconAnchor: [12, 30],
										})
									}
									position={[item.latitude, item.longitude]}></Marker>
							);
						})}

					{!_.isEmpty(create_route) && button == 'Optimize Routes' && show_marker && (
						<Marker
							position={generate_random_point(
								pickup_point.coords.latitude,
								pickup_point.coords.longitude,
								130,
								selected_point == 5 ? 400 : selected_point == 10 ? 3000 : 4000,
							)}
							icon={
								new DivIcon({
									html: `
								<div class = ${classes.popup_wrapper}>
									<div class=${classes.custom_popup} >
										<img src=${ImageLinks.link_rider} width=76px height=26px />
										<p style="color:${theme.colors.darkGrey7};font-size: 14px;font-style: normal;font-weight: 600;line-height: 16px;">Link Rider </p>
										<p style="color:${theme.colors.darkGrey7};font-size: 14px;font-style: normal;font-weight: 600;line-height: 16px;">for future dispatch</p>
									</div>
									<img src=${ImageLinks.popup_arrow} style="width: 20px; margin-top : -2px" />
								</div>`,
									iconAnchor: [-20, 24],
								})
							}></Marker>
					)}
					{!_.isEmpty(create_route) && button == 'Optimize Routes' && show_marker && (
						<Marker
							position={generate_random_point(
								pickup_point.coords.latitude,
								pickup_point.coords.longitude,
								300,
								selected_point == 5 ? 400 : selected_point == 10 ? 3000 : 4000,
							)}
							icon={
								new DivIcon({
									html: `
								<div class = ${classes.popup_wrapper}>
									<div class=${classes.custom_popup} style="width: 210px; height: 100px;">
										<img src=${ImageLinks.calendar_date} width=76px height=26px />
										<p style="color:${theme.colors.darkGrey7};font-size: 14px;font-style: normal;font-weight: 600;line-height: 16px;">Check route performance</p>
										<p style="color:${theme.colors.darkGrey7};font-size: 14px;font-style: normal;font-weight: 600;line-height: 16px;">for next week</p>
									</div>
									<img src=${ImageLinks.popup_arrow} style="width: 20px; margin-top : -2px" />
								</div>`,
									iconAnchor: [-20, 24],
								})
							}></Marker>
					)}
				</MapContainer>
			</div>
		</div>
	);
};

export default PreLogin;
