import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import theme from 'resources/theme';

const useStylesBootstrap = makeStyles(() => ({
	arrow: {
		color: theme.colors.lightPurple,
	},
	tooltip: {
		backgroundColor: theme.colors.lightPink10,
		color: theme.colors.lightPurple7,
		fontWeight: 700,
		borderRadius: 8,
	},
}));

const useStylesBootstrapWhite = makeStyles(() => ({
	arrow: {
		color: theme.colors.white,
		'&:before': {
			border: `1px solid ${theme.colors.lightGrey5}`,
		},
	},
	tooltip: {
		backgroundColor: theme.colors.white,
		color: theme.colors.lightPurple7,
		fontWeight: 700,
		border: `1px solid ${theme.colors.lightGrey5}`,
		borderRadius: 8,
	},
}));

function BootstrapTooltip(props) {
	const classes = useStylesBootstrap();
	const classes_white = useStylesBootstrapWhite();

	return <Tooltip arrow classes={props.is_white ? classes_white : classes} {...props} />;
}

export default BootstrapTooltip;
