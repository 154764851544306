import React from 'react';
import { ListItem } from '@material-ui/core';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Text from './Text';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	list_item: {
		'& :hover': {
			backgroundColor: `${theme.colors.lightPink4} !important`,
		},
	},
}));

const SelectWithInput = ({
	value,
	handleChange,
	options,
	mainContainerStyles = {},
	containerStyles = {},
	style = {},
	inputValue,
	onChange,
	show_arrow = true,
	left_icon = null,
	right_icon = null,
	onClick,
	disabled = false,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		if (anchorEl) {
			handleClose();
		} else {
			setAnchorEl(event.currentTarget);
		}
		onClick?.(event);
	};

	const handleClose = () => {
		setAnchorEl(null);
		onChange('');
	};

	const handleSelect = (value) => {
		handleChange(value);
		handleClose();
	};

	const classes = useStyles();
	const is_select_open = Boolean(anchorEl);
	return (
		<div style={{ width: '100%', position: 'relative', ...mainContainerStyles }}>
			<div
				onClick={(e) => {
					if (!disabled) handleClick(e);
				}}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					border: '1px solid #492897',
					padding: 10,
					borderRadius: 36,
					width: '100%',
					height: '100%',
					cursor: 'pointer',
					...containerStyles,
				}}>
				<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
					{left_icon && left_icon}

					{is_select_open ? (
						<input
							disabled={disabled}
							value={inputValue}
							placeholder={value}
							onChange={(e) => onChange(e.target.value)}
							autoFocus={true}
							style={{
								outline: 'none',
								border: 'none',
								flex: 1,
								backgroundColor: 'inherit',
							}}
						/>
					) : (
						<Text style={{ flex: 1, fontSize: 14, color: 'black', opacity: 0.7 }}>{value}</Text>
					)}

					{right_icon && right_icon}
				</div>
				{show_arrow && (
					<img
						src={ImageLinks.arrow_up_circle_solid}
						style={{ height: 20, width: 20, ...(!is_select_open && { transform: 'rotate(180deg)' }) }}
						alt=''
					/>
				)}
			</div>
			{!_.isEmpty(options) && is_select_open && (
				<div
					className={classes.list_item}
					style={{
						marginTop: 5,
						position: 'absolute',
						boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
						width: '100%',
						zIndex: 1000,
						border: `1px solid ${theme.colors.lightGrey3}`,
						borderRadius: 10,
						background: 'white',
						overflowY: 'auto',
						maxHeight: 200,
					}}>
					{_.map(options, (option, index) => {
						return (
							<ListItem
								button
								key={`menu${index}`}
								onClick={() => handleSelect(option.value)}
								tabIndex={index}
								style={{ padding: '12px 20px', ...(index !== 0 && { borderTop: `1px solid ${theme.colors.lightGrey3}` }) }}>
								<Text>{option.title}</Text>
							</ListItem>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default SelectWithInput;
