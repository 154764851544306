import _ from 'lodash';
import constant from './constant';
import utils from './utils';

const api_requests = {
	request_otp: (data) => {
		return utils.request({
			url: '/user/auth',
			method: 'POST',
			data,
		});
	},
	get_business_properties: () => {
		const businessId = utils.get_business_id();
		return utils.request({
			method: 'GET',
			url: `user/bop/${businessId}/properties`,
			version: 2,
		});
	},
	get_master_zones: (business_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/master-zones`,
			version: 2,
			headers: {
				'owner-id': business_id,
			},
		});
	},
	get_permissions: (business_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/permission`,
			headers: {
				'owner-id': business_id,
			},
			version: 2,
		});
	},
	update_profile: (data, token) => {
		return utils.request({
			method: 'put',
			url: `user/bop/profile`,
			data: data,
			token,
		});
	},
	verify_otp: (data) => {
		return utils.request({
			method: 'post',
			url: `user/auth/verify`,
			data: data,
		});
	},
	update_priority_task: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/rider/priority-task`,
			data: data,
		});
	},
	auth_status: (params) => {
		return utils.request({
			url: 'user/auth/status',
			method: 'GET',
			params,
		});
	},
	login: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/auth/login-with-password`,
			data,
		});
	},
	get_business_accounts: () => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/user-accounts',
		});
	},

	get_order_details: (pb_id) => {
		return utils.request({
			method: 'GET',
			url: `/user/bop/order/${pb_id}`,
			version: 2,
		});
	},
	get_ongoing_order_filters: (zone_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/ongoing/filter-items`,
			version: 2,
			headers: {
				'master-zone-id': zone_id,
			},
		});
	},
	get_ongoing_orders_search: (params = {}) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/ongoing/search`,
			version: 2,
			params,
		});
	},
	get_overview_filters: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/overview/filter-items`,
			version: 2,
			headers: {
				'master-zone-id': 6,
			},
		});
	},
	get_overview_orders: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/overview/search`,
			version: 2,
			params,
		});
	},
	get_ready_to_ship_filters: (zone_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/pending/filter-items`,
			version: 2,
			headers: {
				'master-zone-id': zone_id,
			},
		});
	},
	get_ready_to_ship_orders_search: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/pending/search`,
			version: 2,
			params,
		});
	},
	get_atwarehouse_filters: (zone_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/from-warehouse/filter-items`,
			version: 2,
			headers: {
				'master-zone-id': zone_id,
			},
		});
	},
	get_atwarehouse_search: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/from-warehouse/search`,
			version: 2,
			params,
		});
	},
	get_terminal_filters: (zone_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/terminal/filter-items`,
			version: 2,
			headers: {
				'master-zone-id': zone_id,
			},
		});
	},
	get_terminal_search: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/terminal/search`,
			version: 2,
			params,
		});
	},
	get_all_orders_overview_orders_stats: (params) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/orders/stats`,
			base_url: constant.BASE_URL.STORE,
			params,
		});
	},
	get_pwp_overview_orders_stats: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/overview/stats`,
			version: 2,
			params,
		});
	},

	get_saved_address: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/addresses`,
			params,
			version: 2,
		});
	},
	create_address: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/address`,
			data,
			version: 2,
		});
	},
	get_delivery_simulation: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/slot-simulation`,
			params,
		});
	},
	get_bop_packages: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/packages`,
			version: 2,
		});
	},
	create_csv_channel_order: (data, brand_name) => {
		return utils.request({
			method: 'POST',
			url: `store/channel/csv/orders`,
			data,
			base_url: constant.BASE_URL.STORE,
			headers: {
				'x-owner-name': encodeURIComponent(brand_name),
			},
		});
	},
	bulk_trip_creation: (data, brand_name) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/orders/create`,
			data,
			base_url: constant.BASE_URL.STORE,
			headers: {
				'x-owner-name': encodeURIComponent(brand_name),
			},
		});
	},
	get_available_routes_for_auto_add: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/multi-order/routes-available `,
			data,
		});
	},

	get_process_order_count_for_auto_add: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/multi-order/routes-auto-add-status`,
			params,
		});
	},
	logout_all_user: (params) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/logout`,
			version: 2,
			params,
		});
	},
	get_rider_tasks: (rider_id, params = {}) => {
		return utils.request({
			method: 'get',
			url: `user/bop/rider/${rider_id}/tasks`,
			params: {
				...params,
			},
		});
	},
	bulk_cancel_order: (data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/orders/cancel`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	handle_update_bulk_address: (data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/orders/sender/address`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_rider_task_api: (lat, long, params = {}) => {
		return utils.request({
			method: 'get',
			url: `user/bop/rider/tasks/map/${lat},${long}`,
			version: 3,
			params,
			// headers: {
			// 	'master-zone-id': zone_id,
			// },
		});
	},
	get_rider_task_details: (rider_id, trip_id) => {
		return utils.request({
			method: 'get',
			url: `user/bop/riders/${rider_id}/tasks/${trip_id}`,
			version: 2,
		});
	},
	get_riders: (zoneId, params) => {
		return utils.request({
			method: 'get',
			url: `user/bop/riders`,
			version: 2,
			params,
			headers: {
				'master-zone-id': zoneId,
			},
		});
	},
	get_rider_orders: (rider_id, params = {}) => {
		return utils.request({
			method: 'get',
			url: `user/bop/riders/${rider_id}/orders`,
			version: 2,
			params,
		});
	},
	create_rider: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/rider/account-v2`,
			data,
		});
	},
	get_bop_shift_by_date: (params) => {
		return utils.request({
			method: 'get',
			url: `user/bop/riders/shift-attendance-leaves`,
			params,
		});
	},

	apply_leave: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/riders/apply-leave`,
			data,
		});
	},

	get_network_rider_list: (network_id) => {
		return utils.request({
			method: 'get',
			url: `store/bop/fulfillment/network/${network_id}/riders`,
			base_url: constant.BASE_URL.STORE,
		});
	},

	get_network_configs: (network_id, params = {}) => {
		return utils.request({
			method: 'get',
			url: `store/bop/fulfillment/network/${network_id}/config`,
			params,
			base_url: constant.BASE_URL.STORE,
		});
	},

	invite_search_for_network: (id) => {
		return utils.request({
			method: 'get',
			url: `user/bop/business/invite-code/${id}`,
			version: 2,
		});
	},

	add_participant: (id, data) => {
		return utils.request({
			method: 'post',
			url: `store/bop/fulfillment/network/${id}/participants`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	update_participant: (id, participant_id, data) => {
		return utils.request({
			method: 'put',
			url: `store/bop/fulfillment/network/${id}/participants/${participant_id}`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	delete_participant: (id, participant_id) => {
		return utils.request({
			method: 'delete',
			url: `store/bop/fulfillment/network/${id}/participants/${participant_id}`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_participants: (id) => {
		return utils.request({
			method: 'get',
			url: `store/bop/fulfillment/network/${id}/participants`,
			base_url: constant.BASE_URL.STORE,
		});
	},

	delete_network_rider: (network_id, data) => {
		return utils.request({
			method: 'delete',
			url: `store/bop/fulfillment/network/${network_id}/riders`,
			base_url: constant.BASE_URL.STORE,
			data,
		});
	},

	delete_leave: (data) => {
		return utils.request({
			method: 'put',
			url: `user/bop/riders/remove-leave`,
			data,
		});
	},

	get_bop_hubs: (params = {}) => {
		return utils.request({
			method: 'get',
			url: `user/bop/hubs`,
			params,
		});
	},
	create_hub: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/hub`,
			data,
		});
	},
	delete_hub: (hub_id, data) => {
		return utils.request({
			method: 'put',
			url: `user/bop/hub/${hub_id}`,
			data,
		});
	},
	create_roster: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/rider/bulk_manual_shifts`,
			data,
		});
	},
	delete_roster: (data) => {
		return utils.request({
			method: 'put',
			url: `user/bop/roster/delete`,
			data,
		});
	},
	search_rider: (zoneId, params) => {
		return utils.request({
			method: 'get',
			url: `user/bop/rider/search`,
			params,
			headers: {
				...(zoneId && { 'master-zone-id': zoneId }),
			},
		});
	},
	search_rider_without_zone: (params, headers = {}) => {
		return utils.request({
			method: 'get',
			url: `user/bop/rider/search/no-zone`,
			params,
			headers,
		});
	},
	get_business_zones: (params = null) => {
		return utils.request({
			method: 'get',
			url: `user/bop/business-master-zone`,
			...(!_.isEmpty(params) && { params }),
			// headers: {
			// 	'master-zone-id': zoneId
			// }
		});
	},
	get_warehouses: (params = {}) => {
		return utils.request({
			method: 'get',
			url: `user/bop/warehouses`,
			params,
		});
	},
	create_warehouse: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/warehouse`,
			data,
		});
	},

	// Cash Management Apis
	get_cm_dashboard_stats: () => {
		return utils.request({
			url: `user/bop/cash-mgmt/dashboard-stats`,
			version: 2,
		});
	},
	get_cm_account_info: (params = {}) => {
		return utils.request({
			url: `user/bop/cash-mgmt/account-info`,
			params,
			version: 2,
		});
	},
	get_cm_transfer_requests: () => {
		return utils.request({
			url: `user/bop/cash-mgmt/transfer/requests`,
			version: 2,
		});
	},
	direct_settle: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/cash-mgmt/direct-settle`,
			data,
			version: 2,
		});
	},
	manual_debit: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/cash-mgmt/manual/debit/init`,
			data,
			version: 2,
		});
	},
	manual_transfer: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/cash-mgmt/manual/transfer/init`,
			data,
			version: 2,
		});
	},
	get_transactions_per_day: (params) => {
		return utils.request({
			url: `user/bop/cash-mgmt/txn/per-day-history`,
			params,
			version: 2,
		});
	},
	init_offline_cash_transfer: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/cash-mgmt/transfer/cash/init`,
			data,
			version: 2,
		});
	},
	get_accounts_by_type: (owner_type) => {
		return utils.request({
			url: `user/bop/accounts/${owner_type}/list`,
			version: 2,
		});
	},
	search_rider_by_account: ({ user_type, params }) => {
		return utils.request({
			url: `user/bop/accounts/${user_type}`,
			params,
			version: 2,
		});
	},
	process_cash_txn: (txn_id, params) => {
		return utils.request({
			url: `user/bop/cash-mgmt/transfer/cash/${txn_id}/execute`,
			params,
			version: 2,
		});
	},
	init_manual_debit: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/cash-mgmt/manual/debit/init`,
			data,
			version: 2,
		});
	},
	init_manual_transfer: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/cash-mgmt/manual/transfer/init`,
			data,
			version: 2,
		});
	},
	process_cash_txn_manual: (txn_id, data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/cash-mgmt/transfer/cash/${txn_id}/execute?manual=true`,
			data,
			version: 2,
		});
	},
	get_cm_history: (params) => {
		return utils.request({
			url: `user/bop/cash-mgmt/txn/history`,
			version: 2,
			params,
		});
	},
	get_cm_type_list: (type) => {
		return utils.request({
			url: `user/bop/cash-mgmt/${type}/accounts`,
			version: 2,
			params: { type },
		});
	},

	get_rider_details: (id) => {
		return utils.request({
			url: `user/bop/riders/${id}/info`,
			version: 2,
			show_error: false,
		});
	},
	get_trip_details: (id) => {
		return utils.request({
			url: `user/bop/trip/${id}/info`,
			version: 2,
		});
	},
	get_qr_code_data: () => {
		return utils.request({
			url: `user/bop/qr-code`,
			version: 2,
		});
	},
	edit_cod: (external_order_id, data, headers = {}) => {
		return utils.request({
			method: 'put',
			url: `store/bop/orders/${external_order_id}`,
			base_url: constant.BASE_URL.STORE,
			data,
			headers,
		});
	},
	get_bundles: (params) => {
		return utils.request({
			url: `user/bop/multi-order/get-bundles`,
			params,
		});
	},
	get_bundles_data: (bundle_id) => {
		return utils.request({
			url: `user/bop/multi-order/bundle-data/${bundle_id}`,
		});
	},
	edit_bundle_name: (bundle_id, data) => {
		return utils.request({
			url: `user/bop/${bundle_id}/bundle-name`,
			method: 'PUT',
			data,
		});
	},
	reroute_bundle: (bundle_id) => {
		return utils.request({
			url: `user/bop/multi-order/reoute-bundle/${bundle_id}`,
			method: 'PUT',
		});
	},
	update_bundle_trips: (data) => {
		return utils.request({
			url: `user/bop/multi-order/move-trip-to-bundle`,
			method: 'PUT',
			data,
		});
	},
	auto_add_point_to_existing_routes: (data) => {
		return utils.request({
			url: `user/multi-order/unprocessed-trips-bundle`,
			method: 'post',
			data,
		});
	},
	create_group_auto: (data) => {
		return utils.request({
			url: `user/bop/multi-order/create-group-auto-v3`,
			method: 'post',
			data,
		});
	},
	create_manual_group: (data) => {
		return utils.request({
			url: `user/bop/multi-order/add-trip-to-bundle`,
			method: 'post',
			data,
		});
	},
	ungroup_trip: (data, headers = {}) => {
		return utils.request({
			url: `user/bop/multi-order/ungroup-trip`,
			method: 'PUT',
			data,
			headers,
		});
	},
	unassign_rider: (data) => {
		return utils.request({
			url: `user/bop/deassociate-rider-bundle`,
			method: 'PUT',
			data,
		});
	},
	allocate_rider: (data, show_error) => {
		return utils.request({
			url: `user/bop/allocate-rider-bundle`,
			method: 'PUT',
			data,
			show_error,
		});
	},
	search_top_riders: (bundle_id, params) => {
		return utils.request({
			url: `user/bop/top-riders/${bundle_id}`,
			params,
		});
	},
	reordering_bundle: (bundle_id, data) => {
		return utils.request({
			url: `user/bop/multi-order/ordering-bundle/${bundle_id}`,
			method: 'PUT',
			data,
		});
	},
	bulk_upload_rider: (data) => {
		return utils.request({
			url: `user/bop/riders`,
			method: 'POST',
			version: 2,
			data,
		});
	},
	get_captive_riders: (headers = {}) => {
		return utils.request({
			url: `user/bop/captive-riders`,
			headers,
		});
	},
	get_pending_orders_search: (params) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/orders`,
			params,
			base_url: constant.BASE_URL.STORE,
		});
	},
	export_all_orders: (params) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/orders/export`,
			params,
			base_url: constant.BASE_URL.STORE,
		});
	},

	export_all_orders_pwp: (params, section) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/filter/${section}/search/export`,
			params,
			version: 2,
		});
	},

	get_pending_order_details: (pb_id, headers = {}) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/orders/${pb_id}`,
			base_url: constant.BASE_URL.STORE,
			headers,
		});
	},
	update_pending_order_details: (pb_id, data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/orders/${pb_id}`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	ship_pending_orders: (data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/orders/fulfill`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_wallet_txn_history: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/wallet/txn-history-per-day`,
			params,
			version: 2,
		});
	},
	init_wallet_txn: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/wallet/recharge/init`,
			data,
			version: 2,
		});
	},
	get_wallet_payment_methods: (txn_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/wallet/recharge/${txn_id}/payment-method`,
			version: 2,
		});
	},
	checkout_wallet_recharge: (txn_id, data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/wallet/recharge/${txn_id}/checkout`,
			data,
			version: 2,
		});
	},
	verify_wallet_recharge: (txn_id, data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/wallet/recharge/${txn_id}/verify`,
			data,
			version: 2,
		});
	},
	get_business_details: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/wallet`,
			version: 2,
		});
	},
	get_pending_invites: (token) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/invitations/pending`,
			token,
			version: 2,
		});
	},
	get_sent_invites: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/invitations/sent`,
			version: 2,
		});
	},
	send_user_invite: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/invitations`,
			data,
			version: 2,
		});
	},
	accept_user_invite: (id, token) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/invitations/${id}/accept`,
			token,
			version: 2,
		});
	},
	cancel_user_invite: (id) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/invitations/${id}/cancel`,
			version: 2,
		});
	},
	get_users_list: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/users`,
			version: 2,
		});
	},
	get_serviceability: (params, headers = {}) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/serviceability`,
			params,
			headers,
		});
	},
	fulfill_order: (data, show_error) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/order/fulfill`,
			version: 2,
			data,
			show_error,
		});
	},
	get_configured_alerts: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/alerts`,
			params,
			version: 2,
		});
	},
	get_alert_configuration: (id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/alerts/${id}`,
			version: 2,
		});
	},
	get_alert_usecase: (subject) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/alerts/${subject}/usecases`,
			version: 2,
		});
	},
	get_usecase_detail: (subject, type) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/alerts/${subject}/usecases/${type}`,
			version: 2,
		});
	},
	configure_alert: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/alerts`,
			data,
			version: 2,
		});
	},
	edit_alert: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/alerts/${id}`,
			data,
			version: 2,
		});
	},
	delete_alert: (id) => {
		return utils.request({
			method: 'DELETE',
			url: `user/bop/alerts/${id}`,
			version: 2,
		});
	},
	get_tracking_filters: () => {
		return utils.request({
			url: `user/bop/filter/rider/map/filter-items`,
			version: 2,
			headers: {
				'master-zone-id': 6,
			},
		});
	},
	get_all_riders_filters: (zone_id) => {
		return utils.request({
			url: `user/bop/filter/roster/filter-items`,
			version: 2,
			headers: {
				'master-zone-id': zone_id,
			},
		});
	},
	get_rider_orders_filters: (zone_id) => {
		return utils.request({
			url: `user/bop/filter/trip-status/filter-items`,
			version: 2,
			headers: {
				'master-zone-id': zone_id,
			},
		});
	},
	unsave_address: (id) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/address/${id}/unsave`,
		});
	},
	update_trip_status: (data, headers = {}) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/order/status`,
			data,
			version: 2,
			headers,
		});
	},
	auto_quick_scan: (data, headers = {}) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/order/auto-quick-scan`,
			data,
			version: 2,
			headers,
		});
	},
	get_business_settings: () => {
		return utils.request({
			url: `user/bop/business-property`,
		});
	},
	update_business_settings: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/business-property`,
			data,
		});
	},
	get_master_zones_geometry: (params) => {
		return utils.request({
			url: `user/bop/master-zones/geometry`,
			version: 2,
			params,
		});
	},
	get_sub_zones_geometry: (master_zone_id, headers = {}) => {
		return utils.request({
			url: `user/bop/master-zones/${master_zone_id}/business-zones`,
			version: 2,
			headers,
		});
	},
	create_master_zone: (data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/master-zones`,
			version: 2,
			data,
		});
	},
	create_business_zone: (master_zone, data) => {
		return utils.request({
			method: 'post',
			url: `user/bop/master-zones/${master_zone}/business-zones`,
			version: 2,
			data,
		});
	},
	cancel_pending_orders: (order_id, headers = {}) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/orders/${order_id}/cancel`,
			base_url: constant.BASE_URL.STORE,
			headers,
		});
	},
	get_day_wise_txn_history: (date) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/wallet/transactions/${date}`,
			version: 2,
		});
	},
	link_route_to_rider: (data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/link/rider-bundle`,
			data: data,
		});
	},
	unlink_route_to_rider: (data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/unlink/rider-bundle`,
			data: data,
		});
	},
	get_route_stats: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/route/stats`,
		});
	},
	bulk_allocate_riders: (data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/bulk-allocate-rider-bundle`,
			data,
		});
	},

	bulk_unlink_rider: () => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/bulk/unlink/rider-bundle`,
		});
	},
	bulk_delete_routes: (route_type) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/multi-order/route/${route_type}/bulk-ungroup-trip`,
		});
	},
	update_rider_status: (rider_id, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/riders/${rider_id}/change/active/status`,
			version: 2,
			data,
		});
	},
	reset_rider_device_id: (rider_id) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/riders/${rider_id}/reset-device_id`,
			version: 2,
		});
	},
	get_rider_roster_attendence: (rider_id, data) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/riders/${rider_id}/rider-shift/details`,
			params: data,
		});
	},
	get_rider_performance: (rider_id, type, data) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/riders/${rider_id}/performance/matrix/${type}`,
			params: data,
		});
	},
	get_filter_delivery_slots: (params, url = 'user/bop/filter/delivery-slots', base_url = constant.BASE_URL.DEFAULT) => {
		return utils.request({
			method: 'GET',
			url,
			params,
			version: base_url === constant.BASE_URL.DEFAULT ? 2 : 1,
			base_url,
		});
	},
	all_orders_filter: (group = '') => {
		return utils.request({
			method: 'GET',
			url: `store/bop/filter/items/${group}`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_order_details_from_ids: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/multi-order/mcp_ids/order-by-trip-zones`,
			params,
		});
	},
	get_dashboard_analytics: (params) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/orders/dashboard/analytics`,
			base_url: constant.BASE_URL.STORE,
			params,
			headers: {
				'Cache-Control': 'no-cache',
			},
		});
	},
	get_schedule_slots_fulfillment_service: (service, params, headers = {}) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/orders/fulfillment/${service}/schedule-slots`,
			params,
			base_url: constant.BASE_URL.STORE,
			headers,
		});
	},
	store_fulfill_order: (data, show_error, headers = {}) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/orders/fulfill`,
			// version: 2,
			data,
			show_error,
			base_url: constant.BASE_URL.STORE,
			headers,
		});
	},
	cancel_fulfilment_order: (id, show_error = true, headers = {}) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/orders/${id}/fulfillment/cancel`,
			base_url: constant.BASE_URL.STORE,
			show_error,
			headers,
		});
	},
	pbid_trip_timelines: (pbid, headers = {}) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/order/${pbid}`,
			version: 3,
			headers,
		});
	},
	admin_location_task: (data) => {
		return utils.request({
			method: 'POST',
			url: `/user/bop/admin-task`,
			data: data,
		});
	},
	get_comm_triggers_for_subject: (subject) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/notification/triggers/${subject}`,
			version: 2,
		});
	},
	get_comm_triggers_event_detail: (event) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/notification/events/${event}`,
			version: 2,
		});
	},
	update_comm_recipient: (event, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/notification/events/${event}`,
			data,
			version: 2,
		});
	},
	get_team_list: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/teams`,
			version: 2,
		});
	},
	get_team_detail: (team_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/teams/${team_id}/members`,
			version: 2,
		});
	},
	delete_member_from_team: (team_id, data) => {
		return utils.request({
			method: 'DELETE',
			url: `user/bop/teams/${team_id}/members`,
			version: 2,
			data,
		});
	},
	update_members_team: (team_id, data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/teams/${team_id}/members`,
			version: 2,
			data,
		});
	},
	create_new_team: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/teams`,
			version: 2,
			data,
		});
	},
	get_rider_location: (id) => {
		return utils.request({
			method: 'GET',
			url: `/store/bop/orders/${id}/fulfillment/tracking`,
			base_url: constant.BASE_URL.STORE,
		});
	},

	rider_login_logout: (rider_id, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/rider-login/${rider_id}`,
			data,
		});
	},
	register_business_enquiry: (token, data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/business`,
			data,
			token,
			version: 2,
		});
	},
	get_notification_list: (params) => {
		return utils.request({
			method: 'GET',
			url: `notifications`,
			params,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_joined_teams: (business_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/teams/joined`,
			version: 2,
			headers: {
				'owner-id': business_id,
			},
		});
	},
	edit_master_zone: (master_zone, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/master-zones/${master_zone}`,
			version: 2,
			data,
		});
	},
	edit_business_zone: (master_zone, business_zone, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/master-zones/${master_zone}/business-zones/${business_zone}`,
			version: 2,
			data,
		});
	},
	upload_rider_csv: (data) => {
		return utils.request({
			method: 'POST',
			url: `/user/bop/riders-from-csv`,
			data,
		});
	},
	generate_token: (data) => {
		return utils.request({
			method: 'POST',
			url: `store/channel/vendor`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},

	update_token: (data, id) => {
		return utils.request({
			method: 'PUT',
			url: `store/channel/vendor/${id}`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},

	revoke_token_access: (id) => {
		return utils.request({
			method: 'PUT',
			url: `store/channel/vendor/${id}/revoke`,
			base_url: constant.BASE_URL.STORE,
		});
	},

	get_tokens: () => {
		return utils.request({
			method: 'GET',
			url: `store/channel/vendor`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_single_token: (id) => {
		return utils.request({
			method: 'GET',
			url: `store/channel/vendor/${id}`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_business_account_details: (id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/business-account/${id}`,
		});
	},
	update_business_account_details: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/business-account/${id}`,
			data,
		});
	},
	get_advanced_rider_analytics: (params) => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/rider/analytics',
			params,
		});
	},
	get_advanced_order_analytics: (params) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/orders/order/analytics`,
			base_url: constant.BASE_URL.STORE,
			params,
			headers: {
				'Cache-Control': 'no-cache',
			},
		});
	},
	alert_notif_action: (alert_id, action) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/alerts/${alert_id}/manual-action/${action}`,
			version: 2,
		});
	},
	upload_repeat_order_details: (data) => {
		return utils.request({
			method: 'POST',
			url: `/store/bop/repeat-orders`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	repeat_order_details: (id) => {
		return utils.request({
			method: 'GET',
			url: `/store/bop/repeat-orders/${id}`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	download_repeat_order_result_details: (id, params) => {
		return utils.request({
			method: 'GET',
			url: `/store/bop/repeat-orders/${id}/export/csv`,
			base_url: constant.BASE_URL.STORE,
			params,
		});
	},
	get_repeat_order_file_data: () => {
		return utils.request({
			method: 'GET',
			url: `/store/bop/repeat-orders`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	create_repeat_order_copy: (data) => {
		return utils.request({
			method: 'POST',
			url: '/store/bop/repeat-orders/copy',
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	update_rider_details: (rider_id, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/riders/${rider_id}/details`,
			data,
			version: 2,
		});
	},
	get_wallet_export_items: () => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/wallet/transactions/exports-items',
			version: 2,
		});
	},
	export_wallet: (params) => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/wallet/transactions/exports',
			params,
			version: 2,
		});
	},
	get_rider_export_items: () => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/riders/export-items',
			version: 2,
		});
	},
	export_rider: (params) => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/riders/export',
			params,
			version: 2,
		});
	},
	get_order_export_items: (params = {}) => {
		return utils.request({
			method: 'GET',
			url: 'store/bop/orders/export-items',
			params,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_route_export_items: () => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/bundles/export-items',
			version: 2,
		});
	},
	export_route: (params) => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/bundles/export',
			params,
			version: 2,
		});
	},
	get_network_name_availablity: (params) => {
		return utils.request({
			method: 'GET',
			url: 'store/bop/fulfillment/network/availability',
			params,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_network_list: (business_id) => {
		return utils.request({
			method: 'GET',
			url: 'store/bop/fulfillment/network',
			base_url: constant.BASE_URL.STORE,
			...(business_id && {
				headers: {
					'owner-id': business_id,
				},
			}),
		});
	},
	create_network: (data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	edit_network: (network_id, data, show_error = false) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${network_id}/config`,
			data,
			base_url: constant.BASE_URL.STORE,
			show_error,
		});
	},
	get_single_network_details: (id) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/fulfillment/network/${id}`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	add_riders_to_network: (network_id, data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network/${network_id}/riders`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	fetch_partners_stream: (params, msg_handler, headers = {}) => {
		return utils.stream_request({
			url: `store/bop/orders/fulfillment/services/stream`,
			params,
			base_url: constant.BASE_URL.STORE,
			msg_handler,
			headers,
		});
	},
	get_network_top_riders: (params, headers = {}) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/top-riders`,
			params,
			version: 2,
			headers,
		});
	},
	add_supplier: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/order/add-supplier`,
			data,
			version: 2,
		});
	},
	remove_supplier: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/order/remove-supplier`,
			data,
			version: 2,
		});
	},
	get_common_configs: (business_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/configs`,
			version: 2,
			headers: {
				'owner-id': business_id,
			},
		});
	},
	update_common_configs: (data, params) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/configs`,
			version: 2,
			data,
			params,
		});
	},
	get_fleet_single_vehicle: (id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/vehicles/${id}`,
			version: 2,
		});
	},
	upload_document_s3: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/fleet/upload-document-s3`,
			data,
		});
	},
	add_fleet_vehicle: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/vehicles`,
			version: 2,
			data,
		});
	},
	update_fleet_vehicle: (data, selected_id) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/vehicles/${selected_id}`,
			version: 2,
			data,
		});
	},
	get_fleet_vehicle_list: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/vehicles`,
			params,
			version: 2,
		});
	},

	assign_rider_to_fleet: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/rider/vehicles`,
			data,
			version: 2,
		});
	},
	update_rider_to_fleet: (data, selected_id) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/rider/vehicles/${selected_id}`,
			data,
			version: 2,
		});
	},
	get_fleet_details: (vehicle_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/rider/vehicles/${vehicle_id}`,
			version: 2,
		});
	},
	get_fleet_stats: (vehicle_id, params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/rider/vehicles/stats/${vehicle_id}`,
			params,
			version: 2,
		});
	},
	get_fleet_logs: (vehicle_id, params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/rider/vehicles/reservations/${vehicle_id}`,
			params,
			version: 2,
		});
	},
	delete_fleet_assignment: (assignment_id) => {
		return utils.request({
			method: 'DELETE',
			url: `user/bop/rider/vehicles/${assignment_id}`,
			version: 2,
		});
	},
	get_smart_allocation_configs: (headers = {}) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/fulfillment/network/smart/allocation/config`,
			base_url: constant.BASE_URL.STORE,
			headers,
		});
	},
	set_smart_allocation_configs: (data) => {
		return utils.request({
			method: 'PUT',
			data,
			url: `store/bop/fulfillment/network/smart/allocation/config`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	update_trace_alias: (trace_entity_id, data) => {
		return utils.request({
			method: 'PUT',
			data,
			url: `trace/api/trace/${trace_entity_id}/alerts`,
			base_url: constant.BASE_URL.TRACE,
		});
	},
	get_smart_allocation_default: (params, headers = {}) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/fulfillment/network/smart/allocation/config/default`,
			params,
			base_url: constant.BASE_URL.STORE,
			headers,
		});
	},
	trace_list_filter: () => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/filter/items`,
			base_url: constant.BASE_URL.TRACE,
		});
	},
	trace_list_entities: (params) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/entities`,
			params,
			base_url: constant.BASE_URL.TRACE,
		});
	},
	get_trace_export_items: () => {
		return utils.request({
			method: 'GET',
			url: 'trace/api/trace/export-items',
			base_url: constant.BASE_URL.TRACE,
		});
	},
	export_trace: (params) => {
		return utils.request({
			method: 'GET',
			url: 'trace/api/trace/export',
			params,
			base_url: constant.BASE_URL.TRACE,
		});
	},
	get_trace_analytics: (params) => {
		return utils.request({
			method: 'GET',
			url: 'trace/api/trace/entities-analytics',
			params,
			base_url: constant.BASE_URL.TRACE,
		});
	},
	get_trace_analytics_single: (params, trace_entity_id) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/entities-analytics/${trace_entity_id}`,
			params,
			base_url: constant.BASE_URL.TRACE,
		});
	},

	send_payment_link: (id, data, headers = {}) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/order/${id}/cash-link`,
			data,
			version: 2,
			headers,
		});
	},
	send_tracking_link: (id, data, headers = {}) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/orders/${id}/fulfillment/tracking/notification`,
			data,
			base_url: constant.BASE_URL.STORE,
			headers,
		});
	},

	get_trace_partner_list: () => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/partners`,
			base_url: constant.BASE_URL.TRACE,
		});
	},
	get_fleet_filter_items: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/rider/vehicles/filter-items`,
			version: 2,
		});
	},

	handover_trip_rider: (data, headers = {}) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/trip/handover`,
			data,
			headers,
		});
	},

	get_fleet_export_items: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/rider/vehicles/export-items`,
			version: 2,
		});
	},

	get_network_analytics_overview: (id, params) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/fulfillment/network/${id}/order/overview`,
			params,
			base_url: constant.BASE_URL.STORE,
		});
	},

	get_network_analytics_stats: (id, params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/network/${id}/stats`,
			params,
			version: 2,
		});
	},

	export_network_report: (params) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/orders/network-report`,
			params,
			base_url: constant.BASE_URL.STORE,
		});
	},

	export_fleet: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/rider/vehicles/export`,
			version: 2,
			params,
		});
	},
	get_rider_completed_task_list: (rider_id, date) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/riders/${rider_id}/tasks/completed/${date}`,
			version: 2,
		});
	},
	get_fleet_riders_list: (params) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/current/location`,
			params,
			base_url: constant.BASE_URL.TRACE,
		});
	},
	get_network_orders_list: () => {
		return utils.request({
			method: 'GET',
			url: `store/bop/orders/network`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_three_pl_riders_list: (data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/orders/fulfillment/tracking`,
			base_url: constant.BASE_URL.STORE,
			data,
		});
	},
	update_address: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/address/${id}`,
			data,
		});
	},
	add_child_business: (data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/business/subsidiaries`,
			version: 2,
			data,
		});
	},
	get_child_business: (business_id) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/business/subsidiaries`,
			version: 2,
			...(business_id && {
				headers: {
					'owner-id': business_id,
				},
			}),
		});
	},
	get_child_business_stats: (id, params) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/subsidiaries/${id}/stats`,
			base_url: constant.BASE_URL.STORE,
			params,
		});
	},
	get_child_business_order: (id, params) => {
		return utils.request({
			method: 'GET',
			url: `store/bop/subsidiaries/${id}/orders`,
			base_url: constant.BASE_URL.STORE,
			params,
		});
	},
	create_child_business_access: (id) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/business/subsidiaries/${id}/access`,
			version: 2,
		});
	},
	get_trace_alert_logs: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/alerts/logs`,
			version: 2,
			params,
		});
	},
	get_fleet_analytics_data: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/vehicles/analytics`,
			version: 2,
			params,
		});
	},
	update_odometer_reading: (data, id) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/rider/vehicles/${id}`,
			version: 2,
			data,
		});
	},
	get_fleet_assignment_total_distance: (entity_id, params) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/entities/${entity_id}/distance-travelled`,
			base_url: constant.BASE_URL.TRACE,
			params,
		});
	},
	get_assignment_route_stats: (route_id, data) => {
		return utils.request({
			method: 'POST',
			url: `user/bop/route/${route_id}`,
			data,
		});
	},
	get_route_item_list: (id, route_id) => {
		return utils.request({
			method: 'GET',
			url: `trace/api/trace/${id}/route/${route_id}`,
			base_url: constant.BASE_URL.TRACE,
		});
	},
	download_trace_alert_logs: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/alerts/logs/exports`,
			version: 2,
			params,
		});
	},
	shopify_register: (data) => {
		return utils.request({
			method: 'POST',
			url: `store/channel/shopify/shop/register`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},

	upload_document: (data) => {
		return utils.request({
			method: 'post',
			url: `user/file/upload`,
			version: 2,
			data,
		});
	},
	delete_network: (id) => {
		return utils.request({
			method: 'delete',
			url: `store/bop/fulfillment/network/${id}`,
			base_url: constant.BASE_URL.STORE,
		});
	},
	create_network_servicibility: (id, data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network/${id}/serviceability`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	update_network_servicibility: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${id}/serviceability`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	create_network_pricing: (id, data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network/${id}/pricings`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	update_network_pricing: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${id}/pricings`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	create_network_allocation: (id, data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network/${id}/allocation`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	update_network_allocation: (id, data, allocate_id) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${id}/allocation/${allocate_id}`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	create_network_support: (id, data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network/${id}/support`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	update_network_support: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${id}/support`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	update_network_active: (id) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${id}/activate`,
			base_url: constant.BASE_URL.STORE,
		});
	},

	public_create_routes: (id, data) => {
		return utils.request({
			method: 'POST',
			url: `public/route/${id}`,
			version: 2,
			data,
		});
	},

	get_user_permission_role: (user_id = '') => {
		return utils.request({
			method: 'GET',
			url: `user/bop/permission/roles?${user_id != '' ? `user_id=${user_id}` : ''}`,
			version: 2,
		});
	},
	update_permission_role: (user_id, data) => {
		return utils.request({
			method: 'PUT',
			url: `user/bop/permissions?user_id=${user_id}`,
			version: 2,
			data,
		});
	},

	create_network_surge_pricing: (id, data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network/${id}/pricings/surge`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},

	update_network_surge_pricing: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${id}/pricings/surge`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},

	create_network_cancellation_charges: (id, data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network/${id}/pricings/cancel`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	update_network_cancellation_charges: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${id}/pricings/cancel`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},

	create_network_reattempt_config: (id, data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network/${id}/reattempt/config`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},

	update_network_reattempt_config: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${id}/reattempt/config`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_business_pricing: () => {
		return utils.request({
			method: 'GET',
			url: `user/bop/pricings`,
			version: 2,
		});
	},
	get_report_item_cod: () => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/cash-mgmt/overview/exports-items',
			version: 2,
		});
	},

	export_cod_report: (params) => {
		return utils.request({
			method: 'GET',
			url: 'user/bop/cash-mgmt/overview/export',
			version: 2,
			params,
		});
	},

	create_network_failed_reasons: (id, data) => {
		return utils.request({
			method: 'POST',
			url: `store/bop/fulfillment/network/${id}/failed-reasons`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},

	update_network_failed_reasons: (id, data) => {
		return utils.request({
			method: 'PUT',
			url: `store/bop/fulfillment/network/${id}/failed-reasons`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
	get_invoice_list: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/invoicing/business`,
			params,
			version: 2,
		});
	},
	export_invoice: (params) => {
		return utils.request({
			method: 'GET',
			url: `user/bop/invoicing/invoice-txn`,
			params,
			version: 2,
		});
	},

	get_igm_issue: (params) => {
		return utils.request({
			method: 'GET',
			url: `store/igm/tickets`,
			params,
			base_url: constant.BASE_URL.STORE,
		});
	},

	close_igm_issue: (id) => {
		return utils.request({
			method: 'PUT',
			url: `store/igm/ticket/${id}`,
			base_url: constant.BASE_URL.STORE,
		});
	},

	create_igm_issue: (data) => {
		return utils.request({
			method: 'POST',
			url: `store/igm/ticket`,
			data,
			base_url: constant.BASE_URL.STORE,
		});
	},
};

export default api_requests;
