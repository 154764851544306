import React, { useContext, useEffect } from 'react';
import OrderStatusStepper from './components/OrderStatusStepper';
import OrderHeader from './components/OrderHeader';
import OrderDetails from './components/OrderDetails';
import useStyles from './styles';
import OrderModalContext from './OrderModalContext';
import clsx from 'clsx';
import ShippingDetails from './components/ShippingDetails';
import PackageDetails from './components/PackageDetails';
import Notes from './components/Notes';
import DeliveryDetailsModal from 'views/CreateOrderFlow/CreateOrder/components/CreateOrderSection/components/modals/DeliveryDetailsModal';
import AddressInput from '../AddressInput';
import _ from 'lodash';
import AddressContactDetailsModal from '../AddressContactDetailsModal';
import Package from '../Package';
import TripModal from './components/TripModal';
import 'animate.css';
import constant from 'resources/constant';
import ProductModal from './components/ProductModal';
import ConfirmClose from '../ConfirmClose';
import CdrAllocationModal from '../CdrAllocationModal/CdrAllocationModal';
import AllocationModal from 'views/PoweredWithPidge/components/ReadyToShip/components/AllocationModal/AllocationModal';
import RiderChangeModal from 'modules/RiderChangeModal';
import DeliveryCharges from './components/DeliveryCharges';
import CreateIssueModal from 'views/IGMIssue/component/CreateIssueModal';

function OrderModalWrap({ close, id, updater, parent_api_headers }) {
	const classes = useStyles();
	const {
		set_id,
		data,
		show_delivery_modal,
		set_show_delivery_modal,
		show_address_modal,
		set_show_address_modal,
		edit_address_type,
		show_package_modal,
		set_show_package_modal,
		handle_pickup_details,
		show_trip_modal,
		set_show_trip_modal,
		handle_update_address,
		handle_update_sender_contact,
		show_edit_delivery_date_slot,
		set_show_edit_delivery_date_slot,
		handle_update_delivery_date_slot,
		show_edit_cod,
		set_show_edit_cod,
		handle_update_cod_bill,
		show_product_modal,
		handle_package_update,
		confirm_close_modal,
		set_confirm_close_modal,
		updater_ref,
		close_ref,
		show_cdr_allocation,
		set_show_cdr_allocation,
		cdr_allocation_data,
		show_pwp_allocation,
		set_show_pwp_allocation,
		pbid_data,
		serviceability_data,
		show_rider_change_modal,
		set_show_rider_change_modal,
		handle_change_rider,
		trip_data,
		set_parent_api_headers,
		show_raise_issue,
		set_show_raise_issue,
	} = useContext(OrderModalContext);

	useEffect(() => {
		if (id) set_id(id);
		set_parent_api_headers(parent_api_headers);
		updater_ref.current = () => updater();
		close_ref.current = () => close();
	}, [id]);

	return (
		<>
			<div className={classes.closeContainer} onClick={close}></div>
			<div
				className={clsx('animate__animated animate__slideInRight', classes.container, 'box-shadow')}
				style={{ borderTopLeftRadius: show_trip_modal || show_product_modal ? 0 : 20 }}>
				<OrderStatusStepper parent_api_headers={parent_api_headers} />
				<div style={{ flex: 2, padding: 10 }}>
					<OrderHeader close={close} />
					<OrderDetails />
					<ShippingDetails />
					<PackageDetails />
					<Notes />
					<DeliveryCharges />
				</div>
			</div>
			{show_delivery_modal && edit_address_type === 'reciever' && (
				<DeliveryDetailsModal
					edit_fields={[constant.DELIVERY_DETAIL_MODAL_FILEDS.name, constant.DELIVERY_DETAIL_MODAL_FILEDS.mobile]}
					lock_fields={true}
					title={'Edit Delivery Details'}
					close={() => set_show_delivery_modal(false)}
					handle_drop_details={handle_update_sender_contact}
					drop_address={{
						...data.customer_detail.address,
						address_line1: data.customer_detail.address.address_line_1,
						coordinates: { ...data.customer_detail.address },
					}}
					edit_details={{
						...data,
						receiver_name: data.customer_detail.name,
						receiver_contact: data.customer_detail.mobile,
					}}
					track_data_changes={true}
				/>
			)}
			{show_address_modal && (
				<AddressInput
					close={() => {
						set_show_address_modal(false);
					}}
					skip_confirm_close={true}
					on_address_select={handle_update_address}
					title={edit_address_type === 'sender' ? 'Edit Sender Address' : 'Edit Delivery Address'}
					zone_id={
						edit_address_type === 'sender'
							? _.get(data, 'sender_detail.address.master_zone.id', '')
							: _.get(data, 'customer_detail.address.master_zone.id', '')
					}
					edit_details={{
						...(edit_address_type === 'sender' ? data.sender_detail.address : data.customer_detail.address),
						address_line1: edit_address_type === 'sender' ? data.sender_detail.address.address_line_1 : data.customer_detail.address.address_line_1,
						selected_place:
							edit_address_type === 'sender'
								? {
										...data.sender_detail.address,
										formatted_address: _.get(data, 'sender_detail.address.address_line_1'),
										geometry: {
											location: {
												lat: () => _.get(data, 'sender_detail.address.latitude'),
												lng: () => _.get(data, 'sender_detail.address.longitude'),
											},
										},
								  }
								: {
										...data.customer_detail.address,
										formatted_address: _.get(data, 'customer_detail.address.address_line_1'),
										geometry: {
											location: {
												lat: () => _.get(data, 'customer_detail.address.latitude'),
												lng: () => _.get(data, 'customer_detail.address.longitude'),
											},
										},
								  },
					}}
				/>
			)}
			{show_delivery_modal && edit_address_type === 'sender' && (
				<AddressContactDetailsModal
					handle_pickup_details={handle_pickup_details}
					title={'Edit Sender Details'}
					close={() => set_show_delivery_modal(false)}
					pickup_address={{
						...data.sender_detail.address,
						address_line1: data.sender_detail.address.address_line_1,
						coordinates: { ...data.sender_detail.address },
					}}
					local_pickup_contact={{ sender_name: _.get(data, 'sender_detail.name', '-'), sender_contact: _.get(data, 'sender_detail.mobile', '-') }}
				/>
			)}
			{show_package_modal && (
				<Package
					track_data_changes={true}
					close={() => set_show_package_modal(false)}
					edit_packages={data.packages}
					handle_packages={handle_package_update}
				/>
			)}
			{show_trip_modal && <TripModal id={data.fulfillment.channel.order_id} close={() => set_show_trip_modal(false)} />}
			{show_edit_delivery_date_slot && (
				<DeliveryDetailsModal
					edit_fields={[constant.DELIVERY_DETAIL_MODAL_FILEDS.date, constant.DELIVERY_DETAIL_MODAL_FILEDS.slot]}
					lock_fields={true}
					title={'Edit Delivery Details'}
					close={() => set_show_edit_delivery_date_slot(false)}
					handle_drop_details={handle_update_delivery_date_slot}
					drop_address={{
						...data.customer_detail.address,
						address_line1: data.customer_detail.address.address_line_1,
						coordinates: { ...data.customer_detail.address },
					}}
					edit_details={{
						...data,
						receiver_name: data.customer_detail.name,
						receiver_contact: data.customer_detail.mobile,
					}}
					track_data_changes={true}
				/>
			)}
			{show_edit_cod && (
				<DeliveryDetailsModal
					edit_fields={[constant.DELIVERY_DETAIL_MODAL_FILEDS.bill, constant.DELIVERY_DETAIL_MODAL_FILEDS.cod]}
					lock_fields={true}
					title={'Edit Delivery Details'}
					close={() => set_show_edit_cod(false)}
					handle_drop_details={handle_update_cod_bill}
					drop_address={{
						...data.customer_detail.address,
						address_line1: data.customer_detail.address.address_line_1,
						coordinates: { ...data.customer_detail.address },
					}}
					edit_details={{
						...data,
						receiver_name: data.customer_detail.name,
						receiver_contact: data.customer_detail.mobile,
					}}
					track_data_changes={true}
				/>
			)}
			{show_product_modal && <ProductModal />}
			{confirm_close_modal.open && (
				<ConfirmClose {...confirm_close_modal} on_confirm={confirm_close_modal.confirm_acion} close={() => set_confirm_close_modal({ open: false })} />
			)}
			{show_cdr_allocation && (
				<CdrAllocationModal
					close={() => {
						set_show_cdr_allocation(false);
					}}
					cdr_ids={[data.id]}
					cdr_datas={cdr_allocation_data}
					parent_api_headers={parent_api_headers}
				/>
			)}
			{show_pwp_allocation.open && (
				<AllocationModal
					pickup_coords={show_pwp_allocation.pickup_coords}
					drop_coords={show_pwp_allocation.drop_coords}
					mcp_ids={show_pwp_allocation.mcp_ids}
					is_multiple_network={show_pwp_allocation.is_multiple_network}
					network_id={show_pwp_allocation.network_id}
					on_success={() => {
						updater();
					}}
					close={() => {
						set_show_pwp_allocation({ open: false });
					}}
				/>
			)}
			{show_rider_change_modal && (
				<RiderChangeModal
					close={() => set_show_rider_change_modal(false)}
					on_confirm={handle_change_rider}
					details={{ rider_id: trip_data.rider.id, rider_name: trip_data.rider.name, order_count: 1 }}
				/>
			)}
			{show_raise_issue && <CreateIssueModal close={() => set_show_raise_issue(false)} order_id={data?.fulfillment?.channel?.order_id} />}
		</>
	);
}

export default OrderModalWrap;
