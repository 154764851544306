import _ from 'lodash';
import React, { useMemo } from 'react';
import { ListItem, makeStyles, Modal, Divider } from '@material-ui/core';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import OutlinedButton from 'modules/OutlinedButton';
import theme from 'resources/theme';
import ConfirmClose from 'modules/modals/ConfirmClose';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { shallowEqual, useSelector } from 'react-redux';
import OutlinedTextField from 'modules/OutlinedTextField';
import Button from 'modules/Button';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles({
	quantity_wrap: {
		width: 18,
		height: 18,
		borderRadius: 9,
		backgroundColor: theme.colors.lightPurple,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	wrapper: {
		padding: 20,
		background: theme.colors.white,
		width: 580,
		height: 600,
		margin: 'auto auto',
		borderRadius: 20,
		outline: 'none',
	},
	package_information_container: {
		marginTop: 16,
		display: 'flex',
		justifyContent: 'space-between',
	},
	package_list_item: {
		border: `1px solid ${theme.colors.lightGrey}`,
		borderRadius: 4,
		width: 30,
		height: 30,
		padding: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	items_category_list_items: {
		border: `1px solid ${theme.colors.lightGrey}`,
		borderRadius: 4,
		width: 110,
		height: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 10,
		textTransform: 'capitalize',
	},
	package_and_button_container: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '94%' },
	package_add_content: {
		boxShadow: '0px 1px 2px 1px rgba(202, 202, 202, 0.3)',
		backgroundColor: theme.colors.white,
		borderRadius: 4,
		width: '48%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 12,
		padding: 10,
	},
	package_add_container: {
		maxHeight: 140,
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		overflow: 'auto',
		padding: 2,
	},
	divider: {
		border: `1px dashed ${theme.colors.lightPurple2}`,
		marginTop: 20,
		marginBottom: 4,
	},
	cta_btn: {
		padding: '8px 32px',
		borderRadius: 30,
		margin: '0 auto',
		'&:disabled': {
			color: theme.colors.darkGrey2,
		},
	},
	inputs: { width: 75, height: 32 },
});

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dotsClass: 'slick-dots slick-dots-custom',
	customPaging: function (i) {
		return <div style={{ width: 5, height: 5, backgroundColor: '#B1A3D3', borderRadius: 100, margin: '0 auto' }}> </div>;
	},
};

const PACKAGE_TYPES = [
	{
		title: 'XS',
		value: 'XSMALL',
		images: [ImageLinks.xs1, ImageLinks.xs2, ImageLinks.xs3],
	},
	{
		title: 'S',
		value: 'SMALL',
		images: [ImageLinks.small1, ImageLinks.small2, ImageLinks.small3],
	},
	{
		title: 'M',
		value: 'MEDIUM',
		images: [ImageLinks.medium1, ImageLinks.medium2, ImageLinks.medium3],
	},
	{
		title: 'L',
		value: 'LARGE',
		images: [ImageLinks.large1, ImageLinks.large2, ImageLinks.large3],
	},

	{
		title: 'XL',
		value: 'XLARGE',
		images: [ImageLinks.xlarge1, ImageLinks.xlarge2],
	},
];

const Package = ({
	close,
	handle_packages,
	edit_packages,
	clear_local_data,
	show_back_button = false,
	action_on_back_button = () => {},
	track_data_changes = false,
}) => {
	const [selected_size, set_selected_size] = React.useState('');
	const [selected_category, set_selected_category] = React.useState('');

	const [packages, set_packages] = React.useState(edit_packages || []);

	const [confirm_close_modal, set_confirm_close_modal] = React.useState(false);

	const { bop_packages } = useSelector(
		(state) => ({
			bop_packages: state.app.bop_packages,
		}),
		shallowEqual,
	);

	const classes = useStyles();

	const handleClose = () => {
		if (btn_disabled && track_data_changes) close();
		set_confirm_close_modal(true);
	};

	const allCategories = _.uniq(_.map(bop_packages, 'title'));

	const handle_add_package = () => {
		const package_id = (_.find(bop_packages, { title: selected_category, package_size: selected_size }) || {}).package_id;
		const package_wt = (_.find(bop_packages, { title: selected_category, package_size: selected_size }) || {}).volume;
		const _packages = _.cloneDeep(packages);
		const package_exist = _.find(_packages, { package_id });

		if (package_exist) {
			package_exist.quantity++;
		} else {
			_packages.push({
				package_id,
				quantity: 1,
				volumetric_weight: package_wt,
			});
		}

		set_packages(_packages);
		set_selected_size('');
		set_selected_category('');
	};

	const handle_delete_package = (index) => {
		const _packages = [...packages];
		_packages.splice(index, 1);
		set_packages(_packages);
	};

	const handle_confirm = () => {
		handle_packages(packages);
		close();
	};

	const handle_confirm_close = () => {
		close();
		clear_local_data?.();
	};

	const get_selected_pkg_size = (item) => {
		switch (item) {
			case 'XSMALL':
				return 'XS';
			case 'SMALL':
				return 'S';
			case 'MEDIUM':
				return 'M';
			case 'LARGE':
				return 'L';
			case 'XLARGE':
				return 'XL';
			default:
				return '';
		}
	};

	const is_edit = !_.isEmpty(edit_packages);

	const isAddBtnDisabled = _.isEmpty(selected_size) || _.isEmpty(selected_category);

	const btn_disabled = useMemo(() => {
		let value = true;

		if (!track_data_changes && !_.isEmpty(packages)) {
			value = false;
		}

		if (track_data_changes && !_.isEmpty(edit_packages) && !_.isEmpty(packages)) {
			if (packages.length != edit_packages.length) value = false;
			else {
				const mapped_pkg = edit_packages.reduce((map, obj) => {
					map[obj.package_id] = obj.quantity;
					return map;
				}, {});
				for (let i = 0; i < packages.length; i++) {
					const pkg = packages[i];
					if (pkg.quantity != mapped_pkg[pkg.package_id]) {
						value = false;
						break;
					}
				}
			}
		}
		return value;
	}, [track_data_changes, edit_packages, packages]);

	return (
		<Modal open={true} onClose={handleClose} style={{ display: 'flex', outline: 'none' }}>
			<div className={classes.wrapper}>
				<div className='align-items-center justify-content-between'>
					<div className='align-items-center'>
						{show_back_button && (
							<img
								src={ImageLinks.left_arrow}
								alt='back'
								width={20}
								height={20}
								style={{ marginRight: 4 }}
								className='cursor-pointer'
								onClick={action_on_back_button}
							/>
						)}
						<Text bold style={{ fontSize: 20 }}>
							{is_edit ? 'Edit' : 'Add'} Package
						</Text>
					</div>
					<img src={ImageLinks.crossBlack} onClick={handleClose} width={20} height={20} style={{ cursor: 'pointer' }} alt='cross' />
				</div>
				<div className={classes.package_and_button_container}>
					<div className={classes.package_information_container}>
						<div>
							<Text semi style={{ fontSize: 16, color: theme.colors.darkGrey2 }}>
								Package Size*
							</Text>
							<div style={{ display: 'flex', marginTop: 10 }}>
								{_.map(PACKAGE_TYPES, (size, key) => {
									const isSelected = selected_size === size.value;
									return (
										<ListItem
											key={`size${key}`}
											button
											className={classes.package_list_item}
											onClick={() => {
												utils.track_event_for_analytics(constant.TRACKING_EVENTS.CREATE_ORDER_PACKAGE_SIZE, { size: size.value });
												set_selected_size(size.value);
											}}
											style={{
												...(isSelected && { border: `1px solid ${theme.colors.primary}` }),
												...(key !== 0 && { marginLeft: 10 }),
											}}>
											<Text
												bold
												style={{ fontSize: 12, color: theme.colors.lightGrey, ...(isSelected && { color: theme.colors.primary }) }}>
												{size.title}
											</Text>
										</ListItem>
									);
								})}
							</div>

							<Text semi style={{ fontSize: 16, color: theme.colors.darkGrey2, marginTop: 24 }}>
								Item Category*
							</Text>
							<div className='d-flex justify-content-between' style={{ width: 230, flexWrap: 'wrap' }}>
								{_.map(allCategories, (category, key) => {
									const isSelected = selected_category === category;
									return (
										<ListItem
											key={`category${key}`}
											button
											className={classes.items_category_list_items}
											onClick={() => {
												utils.track_event_for_analytics(constant.TRACKING_EVENTS.CREATE_ORDER_PACKAGE_CATEGORY, { category });
												set_selected_category(category);
											}}
											style={{
												...(isSelected && { border: `1px solid ${theme.colors.primary}` }),
											}}>
											<Text
												bold
												style={{ fontSize: 12, color: theme.colors.lightGrey, ...(isSelected && { color: theme.colors.primary }) }}>
												{category}
											</Text>
										</ListItem>
									);
								})}
							</div>
						</div>
						{selected_size === '' && (
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
								<img src={ImageLinks.pidge_box} style={{ height: 200 }} alt='' />
							</div>
						)}
						{selected_size !== '' && (
							<div
								style={{
									height: 260,
									width: 260,
									border: `1px dashed ${theme.colors.lightGrey}`,
									borderRadius: 10,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<Text style={{ color: theme.colors.darkGrey2, fontSize: 12, textAlign: 'center', width: 220 }}>
									The below items can comfortably fit inside a small tamper-proof bag
								</Text>
								<Slider
									key={selected_size}
									{...settings}
									style={{
										width: 200,
										height: 100,
										marginBottom: 40,
										marginTop: 20,
									}}>
									{_.map((_.find(PACKAGE_TYPES, (p) => selected_size === p.value) || {}).images, (val, key) => {
										return (
											<div style={{ width: '100%' }} key={`slider${key}`}>
												<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
													<img src={val} style={{ height: 100 }} alt='' />
												</div>
											</div>
										);
									})}
								</Slider>
								<Text style={{ color: theme.colors.darkGrey2, fontSize: 12, textAlign: 'center', width: 220 }}>
									Please ensure that no dimension is longer than 16”
								</Text>
							</div>
						)}
					</div>
					<Divider className={classes.divider} />
					<div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginBottom: 24 }}>
						<div className={classes.package_add_container}>
							{_.map(packages, (_package, key) => {
								const package_details = _.find(bop_packages, { package_id: _package.package_id }) || _package;
								return (
									<>
										{package_details.title && (
											<div key={`package${key}`} button className={classes.package_add_content}>
												<div className='align-items-center'>
													<div className={classes.quantity_wrap}>
														<Text bold style={{ color: theme.colors.primary, fontSize: 12 }}>
															{_package.quantity}
														</Text>
													</div>
													<Text semi style={{ marginLeft: 12, fontSize: 14 }}>
														{get_selected_pkg_size(package_details.package_size)}
													</Text>
													<Text semi style={{ marginLeft: 4, fontSize: 14 }}>
														|
													</Text>
													<Text semi style={{ marginLeft: 4, fontSize: 14 }}>
														{package_details.title}
													</Text>
												</div>
												<div style={{ cursor: 'pointer' }} onClick={() => handle_delete_package(key)}>
													<img src={ImageLinks.delete} height={16} width={16} alt='' />
												</div>
											</div>
										)}
										{!package_details.title && (
											<div className={classes.package_add_content} style={{ boxShadow: 'none', padding: 2, alignItems: 'center' }}>
												<div
													key={`package${key}`}
													button
													className={classes.package_add_content}
													style={{ width: 'auto', marginTop: 0 }}>
													<div className='align-items-center'>
														<div className={classes.quantity_wrap}>
															<Text bold style={{ color: theme.colors.primary, fontSize: 12 }}>
																{_package.quantity}
															</Text>
														</div>
													</div>
												</div>
												<div className='d-flex justify-content-between align-items-center' style={{ gap: 10 }}>
													<OutlinedTextField
														label='Volume*'
														disabled={true}
														value={_package.volumetric_weight}
														className={classes.inputs}
														size='small'
														InputProps={{
															style: { height: 32, paddingRight: 0 },
															endAdornment: (
																<Text medium style={{ width: 50 }}>
																	in<sup>3</sup>
																</Text>
															),
														}}
													/>
													<OutlinedTextField
														disabled={true}
														label='Weight*'
														value={(_package.dead_weight / 1000).toFixed(1)}
														className={classes.inputs}
														size='small'
														InputProps={{
															style: { height: 32, paddingRight: 0 },
															endAdornment: (
																<Text medium style={{ width: 50 }}>
																	KG
																</Text>
															),
														}}
													/>
												</div>
												<div style={{ cursor: 'pointer' }} onClick={() => handle_delete_package(key)}>
													<img src={ImageLinks.delete} height={16} width={16} style={{ marginRight: 10 }} alt='' />
												</div>
											</div>
										)}
									</>
								);
							})}
						</div>
					</div>
					<div className='d-flex text-align-center' style={{ margin: '0 auto', gap: 8 }}>
						<Button
							size='large'
							type='outlined'
							text='Add'
							onClick={handle_add_package}
							disabled={isAddBtnDisabled}
							left_icon={isAddBtnDisabled ? ImageLinks.addGrey : ImageLinks.add_pruple_no_fill}
							style={{ width: 110 }}
						/>
						<Button
							size='large'
							type='round'
							text={is_edit ? 'Update' : 'Confirm'}
							onClick={handle_confirm}
							disabled={btn_disabled}
							style={{ width: 110 }}
						/>
					</div>
				</div>

				{confirm_close_modal && (
					<ConfirmClose
						headerTitle='Discard Changes'
						title='You have made changes but you have not saved them. Are you sure you want to discard these changes?'
						close={() => set_confirm_close_modal(false)}
						on_confirm={handle_confirm_close}
					/>
				)}
			</div>
		</Modal>
	);
};

export default Package;
