export const PICKUP_POINTS = {
	pickup_points: [
		{
			value: 1,
			title: 'World Trade Park, Jaipur',
			coords: {
				latitude: 26.8544946,
				longitude: 75.8047336,
			},
			drop_points: [
				{
					value: 1,
					title: 'Milap Nagar',
					coords: {
						latitude: 26.8534371,
						longitude: 75.7972897,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'Mata colony',
					coords: {
						latitude: 26.845739,
						longitude: 75.7887492,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'Model Town,Jagatpura, Jaipur',
					coords: {
						latitude: 26.8430633,
						longitude: 75.8068259,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
		{
			value: 2,
			title: ' Adarsh Nagar, Jaipur',
			coords: {
				latitude: 26.9031496,
				longitude: 75.8366456,
			},
			drop_points: [
				{
					value: 1,
					title: 'Transport Nagar, Jaipur',
					coords: {
						latitude: 26.9094812,
						longitude: 75.8442852,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'Ramada by Wyndham Jaipur',
					coords: {
						latitude: 26.8957231,
						longitude: 75.8289423,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'Rajasthan University Campus, Jaipur',
					coords: {
						latitude: 26.8851877,
						longitude: 75.8200022,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
		{
			value: 3,
			title: 'Kormangala, Bengaluru',
			coords: {
				latitude: 12.934533,
				longitude: 77.626579,
			},
			drop_points: [
				{
					value: 1,
					title: 'Jayanagar, Bengaluru',
					coords: {
						latitude: 12.9307412,
						longitude: 77.5837119,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'HSR Layout, Bengaluru',
					coords: {
						latitude: 12.91084326979668,
						longitude: 77.64908395714181,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'Wilson Garden, Bengaluru',
					coords: {
						latitude: 12.9482433,
						longitude: 77.5969877,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
		{
			value: 4,
			title: 'Indiranagar Bengaluru',
			coords: {
				latitude: 12.9783081,
				longitude: 77.6407756,
			},
			drop_points: [
				{
					value: 1,
					title: 'Kodihalli, Bengaluru',
					coords: {
						latitude: 12.9603427,
						longitude: 77.6492548,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'Halasuru, Bengaluru',
					coords: {
						latitude: 12.9819797,
						longitude: 77.6243731,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'Cox Town, Bengaluru',
					coords: {
						latitude: 12.9968529,
						longitude: 77.6289472,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
		{
			value: 5,
			title: 'Phoenix Marketcity, Pune',
			coords: {
				latitude: 18.5623037,
				longitude: 73.9165106,
			},
			drop_points: [
				{
					value: 1,
					title: 'Viman Nagar, Pune',
					coords: {
						latitude: 18.5679996,
						longitude: 73.9141307,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'Hyatt Pune',
					coords: {
						latitude: 18.5541981,
						longitude: 73.904417,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'SkyJumper Trampoline Park, Pune',
					coords: {
						latitude: 18.5569502,
						longitude: 73.8932766,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
		{
			value: 6,
			title: 'Seasons Mall, Pune',
			coords: {
				latitude: 18.5195531,
				longitude: 73.9312306,
			},
			drop_points: [
				{
					value: 1,
					title: 'Gopalpatti, Pune',
					coords: {
						latitude: 18.50852,
						longitude: 73.9650011,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'Malwadi, pune',
					coords: {
						latitude: 18.5053361,
						longitude: 73.9388827,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'Jasminium, Magarpatta,Pune',
					coords: {
						latitude: 18.5133194,
						longitude: 73.9216877,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
		{
			value: 7,
			title: 'Phoenix Marketcity, Mumbai',
			coords: {
				latitude: 19.091732824487327,
				longitude: 72.89069401179613,
			},
			drop_points: [
				{
					value: 1,
					title: 'Ghatkopar, Mumbai',
					coords: {
						latitude: 19.083064613082296,
						longitude: 72.9081751248548,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'DMart Ghatkopar West',
					coords: {
						latitude: 19.08267337182139,
						longitude: 72.89179439559788,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'BKC Mumabi',
					coords: {
						latitude: 19.065543081588217,
						longitude: 72.86714805636922,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
		{
			value: 8,
			title: 'Bandra West, Mumbai',
			coords: {
				latitude: 19.06157205531355,
				longitude: 72.82982584464051,
			},
			drop_points: [
				{
					value: 1,
					title: 'Galaxy Movie Theatre, Mumbai',
					coords: {
						latitude: 19.062009975951412,
						longitude: 72.83892884268123,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'Bandra junction, Mumbai',
					coords: {
						latitude: 19.06363793462243,
						longitude: 72.84115394973385,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'Khar West, Mumbai',
					coords: {
						latitude: 19.0717092,
						longitude: 72.8359038,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
		{
			value: 9,
			title: 'Citywalk, Delhi',
			coords: {
				latitude: 28.528485237156378,
				longitude: 77.21899059833298,
			},
			drop_points: [
				{
					value: 1,
					title: 'Shaheed Bhagat Singh College, Delhi',
					coords: {
						latitude: 28.5349129,
						longitude: 77.2271862,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'Chhatarpur, Delhi',
					coords: {
						latitude: 28.4959432,
						longitude: 77.1652998,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'Vasant Kunj, Delhi',
					coords: {
						latitude: 28.5346447,
						longitude: 77.1470033,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
		{
			value: 10,
			title: 'Connaught Place Delhi',
			coords: {
				latitude: 28.528485237156378,
				longitude: 77.21899059833298,
			},
			drop_points: [
				{
					value: 1,
					title: 'Jor Bagh Delhi',
					coords: {
						latitude: 28.5877008,
						longitude: 77.2151231,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 2,
					title: 'Patel Nagar Delhi',
					coords: {
						latitude: 28.6526793,
						longitude: 77.1571338,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
				{
					value: 3,
					title: 'Shastri Nagar, Delhi',
					coords: {
						latitude: 28.6699782,
						longitude: 77.1818366,
					},
					delivery_partners: ['Zomato', 'Porter', 'Shadowfax', 'Zypp', 'Loadshare', 'Borzo', 'Ridding Rangers', 'Truck partners', 'Road Badge'],
				},
			],
		},
	],
};

export const ROUTE_POINTS = [
	{
		point: 5,
		radius: 600,
		riders: [1, 2, 3],
		min_distance: 200,
	},
	{
		point: 10,
		radius: 5000,
		riders: [1, 2, 3],
		min_distance: 800,
	},
	{
		point: 15,
		radius: 6000,
		riders: [1, 2, 3],
		min_distance: 1200,
	},
];
