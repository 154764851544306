export const OUTER_FILTER_TYPE = {
	IOT: 'iot',
	NETWORK: 'network',
	LOGGED: 'logged',
	ONTASK: 'ontask',
};

export const VEHICLE_REF_SEARCH_OPTIONS = [
	{
		title: 'Rider',
		value: 'rider',
	},
	{
		title: 'Ref. Id',
		value: 'ref_id',
	},
	{
		title: 'Vehicle No.',
		value: 'vehicle_number',
	},
];

export const NETWORK_TYPE_SEARCH_OPTIONS = [
	{
		title: 'All',
		value: 'all',
	},
	{
		title: 'Owned',
		value: 'owned',
	},
	{
		title: 'PPN',
		value: 'ppn',
	},
	{
		title: '3PL',
		value: 'three_pl',
	},
];
