import React from 'react';
import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from '../modules/Text';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
	container: {
		textAlign: 'center',
		padding: '50px',
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	image: {
		marginBottom: '20px',
	},

	stepsList: {
		listStyleType: 'none',
		padding: 0,
		margin: '20px 0',
		textAlign: 'left',
		fontSize: '1rem',
	},

	supportText: {
		marginTop: '20px',
		fontSize: '1rem',
	},
	link: {
		color: '#007bff',
		textDecoration: 'none',
	},
}));

const Fallback = () => {
	const classes = useStyles();
	const handleReload = () => {
		window.location.reload(true);
	};

	return (
		<div className={classes.container}>
			<img src={ImageLinks.logo} alt='Something Went Wrong' className={classes.image} />
			<Text bold style={{ fontSize: 40 }}>
				Oops! Something went wrong
			</Text>
			<Text semi style={{ fontSize: 15 }}>
				We’re sorry for the inconvenience. Please try the following steps:
			</Text>
			<ul className={classes.stepsList}>
				<li>
					♻️ <strong>Update your browser</strong> to the latest version.
				</li>
				<br></br>
				<li>
					🔄 <strong>Hard reload</strong> this page to clear the cache.
				</li>
				<br></br>
				<li>
					📞 <strong>Contact our support team</strong> if the problem persists.
				</li>
			</ul>
			<Button type='round' text='Hard Reload Page' onClick={handleReload} size='large' />
			<p className={classes.supportText}>
				Need help?{' '}
				<a href='mailto:support@pidge.in' className={classes.link}>
					Contact Support
				</a>
			</p>
		</div>
	);
};

export default Fallback;
