import React, { useEffect, useState } from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Select from 'modules/Select';
import Button from 'modules/Button';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 18, marginBottom: 10 },
	sub_heading: { color: theme.colors.darkGrey2 },
	info_container: { width: 340, backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, height: '70vh', flex: 0.8 },
	geo_fence: { marginTop: 30, fontSize: 16, marginBottom: 5 },
	tab_section: { marginTop: 20, marginBottom: 20, display: 'flex' },
	mapContainer: { height: '360px', width: '100%', marginTop: 30, paddingBottom: 10 },
	width_url: { width: 100, marginLeft: 10, marginTop: 5 },
	update: { borderRadius: 30, fontSize: 16, marginTop: 10, width: 100, position: 'absolute', bottom: 30 },
	switch_flex: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	list_item: { border: '1px solid #492897', marginRight: 10, width: 90, borderRadius: 30, display: 'flex', justifyContent: 'center', height: 26 },
	tips: {
		backgroundColor: theme.colors.lightPurple,
		marginRight: 0,
		width: 80,
		borderRadius: 5,
		display: 'flex',
		justifyContent: 'center',
		padding: '8px 8px',
	},
}));

const select_options = [
	{ id: 0, value: 3, title: 'Reference ID', sub_title: 'Non-Unique ID as inputted by business' },
	{ id: 1, value: 1, title: 'PBID', sub_title: 'Unique AWB generated by Pidge' },
	{ id: 2, value: 5, title: 'Unique ID', sub_title: 'Unique id generated by Pidge for every order' },
];

const BarCodes = ({ handle_update_settings, quick_scan_code = null }) => {
	const classes = useStyles();
	const [selected_menu_value, set_selected_menu_value] = useState(quick_scan_code);

	useEffect(() => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.SETTING_BARCODES, { active_setting: selected_menu_value });
	}, [selected_menu_value]);

	return (
		<>
			<div style={{ position: 'relative' }}>
				<div className={classes.heading_container}>
					<Text bold className={classes.heading}>
						Barcodes
					</Text>
					<ListItem key={`tips`} button onClick={() => {}} className={classes.tips}>
						<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
							Tips & Info
						</Text>
					</ListItem>
				</div>

				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: 20, flex: '1' }}>
						<Text semi className={classes.sub_heading} style={{ fontSize: 14 }}>
							Barcodes help ensure that the correct package is picked up and delivered to the correct customer. After settings are enabled, only
							that key is allowed when scanning packages.
						</Text>
						<Text semi className={classes.geo_fence}>
							Identification Type
						</Text>
						<Text semi className={classes.sub_heading}>
							Select the type unique identification to be scanned against each order
						</Text>
						<div style={{ display: 'flex' }}>
							<div className={classes.tab_section}>
								<ListItem
									button
									onClick={() => {
										set_selected_menu_value(3);
									}}
									className={classes.list_item}
									style={{
										backgroundColor: _.includes([1, 3, 5], selected_menu_value) ? theme.palette.primary.main : theme.colors.white,
									}}>
									<Text
										semi
										style={{
											color: _.includes([1, 3, 5], selected_menu_value) ? theme.colors.white : theme.colors.primary,
											fontSize: 12,
										}}>
										Barcode
									</Text>
								</ListItem>
								<ListItem
									button
									onClick={() => {
										set_selected_menu_value(4);
									}}
									className={classes.list_item}
									style={{ backgroundColor: selected_menu_value == 4 ? theme.palette.primary.main : theme.colors.white }}>
									<Text semi style={{ color: selected_menu_value == 4 ? theme.colors.white : theme.colors.primary, fontSize: 12 }}>
										None
									</Text>
								</ListItem>
							</div>
						</div>
						{selected_menu_value === 4 && (
							<Text medium>
								<Text component={'span'} semi>
									Please Note:
								</Text>{' '}
								In this identification type, the system will not be able to scan any ID related to the order. To ensure integrity of the
								package, we recommend taking 'Photo Proof' either at package pickup or drop or both.
							</Text>
						)}
						{selected_menu_value !== 4 && selected_menu_value && (
							<div className='align-items-center justify-content-between'>
								<div>
									<Text semi className={classes.geo_fence}>
										Fetch data from
									</Text>
									<Text semi className={classes.sub_heading}>
										Select which field to use for barcode reference
									</Text>
								</div>
								<div>
									<Select
										value={selected_menu_value}
										defaultOption={{
											title: 'Select Data Source',
											value: null,
										}}
										options={_.map(select_options, (option) => {
											return {
												title: option.title,
												value: option.value,
												subtitle: option.sub_title,
											};
										})}
										handleChange={(val) => {
											set_selected_menu_value(val);
										}}
										containerStyles={{ height: 35, width: 180 }}
										text_props={{ medium: true }}></Select>
								</div>
							</div>
						)}
					</div>
					<div className={classes.info_container}>
						<Text bold style={{ fontSize: 18, color: theme.colors.lightPurple7, marginBottom: 30 }}>
							Unique Order Identification
						</Text>
						<div className='d-flex' style={{ gap: 24 }}>
							<img src={ImageLinks.barcode_scan_pbid}></img>
							<div>
								<Text bold style={{ fontSize: 16, color: theme.colors.lightPurple7 }}>
									Barcode: PBID
								</Text>
								<Text regular style={{ fontSize: 12, marginTop: 10 }}>
									PBID are unique AWB generated by Pidge when moving an order to self-fulfillment or powered with Pidge.
								</Text>
							</div>
						</div>

						<div className='d-flex' style={{ gap: 24, marginTop: 40 }}>
							<img src={ImageLinks.barcode_scan_refid}></img>
							<div>
								<Text bold style={{ fontSize: 16, color: theme.colors.lightPurple7 }}>
									Barcode: Reference ID
								</Text>
								<Text regular style={{ fontSize: 12, marginTop: 10 }}>
									Reference ID are non-unique fields inputted by businesses when creating orders via csv, dashboard, or any integration
								</Text>
							</div>
						</div>
						<div className='d-flex' style={{ gap: 24, marginTop: 40 }}>
							<img src={ImageLinks.barcode_scan_no_barcode}></img>
							<div>
								<Text bold style={{ fontSize: 16, color: theme.colors.lightPurple7 }}>
									No Barcode
								</Text>
								<Text regular style={{ fontSize: 12, marginTop: 10 }}>
									In case of no barcodes, executives will simply be asked to confirm if they have received the package. Pair this with image
									POP for added security.
								</Text>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ position: 'absolute', bottom: 20, right: 30 }}>
				<Button
					disabled={selected_menu_value === null || selected_menu_value === quick_scan_code}
					onClick={() => {
						handle_update_settings({ quick_scan_code: selected_menu_value });
					}}
					type='round'
					text='Save'
					size='large'
					style={{ width: 110 }}
				/>
			</div>
		</>
	);
};

export default BarCodes;
