export const INDUSTURY_OPTIONS = [
	{ title: 'Bakery', value: 'bakery' },
	{ title: 'Fashion', value: 'fashion' },
	{ title: 'Restaurant', value: 'restaurant' },
	{ title: 'Official Purposes', value: 'official purposes' },
	{ title: 'Other', value: 'other' }
];

export const RANGE_OPTIONS = [
	{ title: '1-5', value: '1-5' },
	{ title: '6-15', value: '6-15' },
	{ title: '15+', value: '15+' }
];

export const SOURCE_OPTIONS = [
	{ title: 'Referral', value: 'referral' },
	{ title: 'Social Media', value: 'social media' },
	{ title: 'Website', value: 'website' },
	{ title: 'Already Use Pidge', value: 'already use pidge' },
	{ title: 'Other', value: 'other' }
];
