import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import api_requests from 'resources/api_requests';
import moment from 'moment';
import theme from 'resources/theme';
import { toast } from 'react-toastify';
import Routes from 'resources/Routes';
import utils from 'resources/utils';

const current_week_date = `${moment().subtract(7, 'days').format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`;
const get_week_dates = (date = new Date()) => {
	const d = moment(date);
	const start = moment(d).startOf('week').startOf('day').format('YYYY-MM-DD');
	const end = moment(d).endOf('week').endOf('day').format('YYYY-MM-DD');
	return `${start},${end}`;
};

const initial_confirm_modal_details = {
	open: false,
	header_title: '',
	title: '',
	positive_text: '',
	negative_text: '',
	confirm_acion: () => {},
	is_error_msg: false,
};

const useRiderProfile = (rider_id, history) => {
	const time_am = ['12 AM', '01 AM', '02 AM', '03 AM', '04 AM', '05 AM', '06 AM', '07 AM', '08 AM', '09 AM', '10 AM', '11 AM'];
	const time_pm = ['12 PM', '01 PM', '02 PM', '03 PM', '04 PM', '05 PM', '06 PM', '07 PM', '08 PM', '09 PM', '10 PM', '11 PM'];
	const [selected_tab, set_selected_tab] = useState(0);
	const [graph_date, set_graph_date] = useState(current_week_date);
	const [chart_data, set_chart_data] = useState(null);
	const [roster_date, set_roster_date] = useState(get_week_dates());
	const [show_date_range_picker, set_show_date_range_picker] = useState(false);
	const [shift_time, set_shift_time] = useState(time_am);
	const [is_active, set_is_active] = useState(true);
	const [rider_details, set_rider_details] = useState(null);
	const [roster_details, set_roster_details] = useState(null);
	const [show_rider_modal, set_show_rider_modal] = useState(false);
	const [show_create_roster, set_show_create_roster] = useState(false);
	const selected_attendence_item_index = useRef(-1);
	const current_week_roster_date = useRef(get_week_dates()).current;
	const [confirm_close_modal, set_confirm_close_modal] = useState(_.cloneDeep(initial_confirm_modal_details));
	const [roster_details_week, set_roster_details_week] = useState(null);
	const [day_start_time, set_day_start_time] = useState(moment('12:00 AM', 'HH:mm A'));
	const [day_end_time, set_day_end_time] = useState(moment('11:00 AM', 'HH:mm A'));

	useEffect(() => {
		api_requests.get_rider_details(rider_id).then((res) => {
			set_rider_details(res.data);
			set_is_active(res.data.details.is_active);
		});
	}, []);

	useEffect(() => {
		fetch_rider_performance();
	}, [selected_tab, graph_date]);

	useEffect(() => {
		fetch_roster_data();
	}, [roster_date]);

	const fetch_roster_data = async () => {
		const start_date = moment.utc(moment(roster_date.split(',')[0]).startOf('day')).format();
		const end_date = moment.utc(moment(roster_date.split(',')[1]).endOf('day')).format();
		try {
			const response = await api_requests.get_rider_roster_attendence(rider_id, {
				start_date,
				end_date,
			});
			if (response.success) {
				set_roster_details(response.data);

				let start_date_of_week = new Date(roster_date.split(',')[0]);
				let days_Of_Week = [];
				// add all date of weeks in array
				for (let i = 0; i < 7; i++) {
					let currentDate = new Date(start_date_of_week);
					currentDate.setUTCDate(start_date_of_week.getUTCDate() + i);
					days_Of_Week.push(currentDate.toISOString().split('T')[0]);
				}

				let _roster_data = _.cloneDeep(response.data.roster);

				let combine_roster_data = {};

				_roster_data.forEach((item) => {
					// if shif is over night then add new data in next date
					const moment_from = moment(moment(item.time_slot_from_real).format('DD/MM/YYYY'), 'DD/MM/YYYY');
					const moment_to = moment(moment(item.time_slot_to_real).format('DD/MM/YYYY'), 'DD/MM/YYYY');
					const diff_in_days = moment_from.diff(moment_to, 'day');
					if (diff_in_days !== 0) {
						let date_key_to = moment(item.time_slot_to_real).format('DD-MM-YYYY');
						if (!combine_roster_data[date_key_to]) {
							combine_roster_data[date_key_to] = [];
						}

						combine_roster_data[date_key_to].push({ ...item });
						let date_key_from = moment(item.time_slot_from_real).format('DD-MM-YYYY');
						if (!combine_roster_data[date_key_from]) {
							combine_roster_data[date_key_from] = [];
						}
						combine_roster_data[date_key_from].push({ ...item });
					} else {
						let date_Key = moment(item.time_slot_from_real).format('DD-MM-YYYY');
						if (!combine_roster_data[date_Key]) {
							combine_roster_data[date_Key] = [];
						}
						combine_roster_data[date_Key].push({ ...item });
					}
				});

				let roster_week_data = [];
				days_Of_Week.forEach((date) => {
					const dateKey = moment(date).format('DD-MM-YYYY');

					if (combine_roster_data[dateKey]) {
						const entries = combine_roster_data[dateKey].map((entry) => {
							return {
								...entry,
								roster_date: date,
							};
						});

						roster_week_data.push({
							...combine_roster_data[dateKey][0],
							roster_date: date,
							entries: entries,
						});
					} else {
						roster_week_data.push({
							roster_date: date,
						});
					}
				});

				set_roster_details_week(roster_week_data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetch_rider_performance = async () => {
		try {
			let type = 'order';
			if (selected_tab === 1) type = 'distance';
			if (selected_tab === 2) type = 'login_hours';

			const start_date = graph_date.split(',')[0];
			const end_date = graph_date.split(',')[1];
			let agg_by = 'day';
			const diff_in_days = moment(end_date).diff(moment(start_date), 'days');

			if (diff_in_days > 14) agg_by = 'week';
			if (diff_in_days > 30) agg_by = 'month';
			if (selected_tab === 0) agg_by = 'category';

			const response = await api_requests.get_rider_performance(rider_id, type, {
				start_date: moment.utc(moment(start_date).startOf('day')).format(),
				end_date: moment.utc(moment(end_date).startOf('day')).format(),
				agg_by,
			});
			if (response.success) {
				set_chart_data(response.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const change_status = async (status) => {
		utils.track_event_for_analytics(`set_status_${status ? 'active' : 'inactive'}`);
		let confirm_desc = status
			? `Are you sure you want activate ${rider_id}. They will start receiving and performing tasks.`
			: `Are you sure you want to deactivate ${rider_id}. They will not be able to perform any tasks."`;
		set_confirm_close_modal({
			open: true,
			header_title: 'Update Rider Status',
			title: confirm_desc,
			positive_text: status ? 'Activate' : 'Deactivate',
			negative_text: 'Cancel',
			is_error_msg: !status,
			confirm_acion: () => change_confirmatory_rider_status(status),
		});
	};

	const change_confirmatory_rider_status = async (status) => {
		set_is_active(status);
		try {
			const respose = await api_requests.update_rider_status(rider_id, { status });
			if (respose.success)
				toast.success(respose.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
		} catch (error) {}
	};

	const clear_device_id = async () => {
		try {
			const respose = await api_requests.reset_rider_device_id(rider_id);
			if (respose.success && respose.message)
				toast.success(respose.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
		} catch (error) {}
	};

	const get_hour_diff = (time) => {
		// Parse the start and end times
		if (!time) return;
		const split_time = time.split('-');
		const start_time = split_time[0];
		const end_time = split_time[1];
		const start = new Date('1970-01-01 ' + start_time);
		const end = new Date('1970-01-01 ' + end_time);

		// Find the difference in milliseconds
		const diffInMilliseconds = end - start;

		// Convert the difference to hours and return it
		return diffInMilliseconds / (1000 * 60 * 60);
	};

	const on_roster_left_nav_press = () => {
		const date = roster_date.split(',')[0];
		const d = moment(date);
		const nextWeek = d.subtract(1, 'week');
		const start = moment(nextWeek).startOf('week').startOf('day').format('YYYY-MM-DD');
		const end = moment(nextWeek).endOf('week').endOf('day').format('YYYY-MM-DD');
		set_roster_date(`${start},${end}`);
	};
	const on_roster_right_nav_press = () => {
		const date = roster_date.split(',')[1];
		const d = moment(date);
		const nextWeek = d.add(1, 'week');
		const start = moment(nextWeek).startOf('week').startOf('day').format('YYYY-MM-DD');
		const end = moment(nextWeek).endOf('week').endOf('day').format('YYYY-MM-DD');
		set_roster_date(`${start},${end}`);
	};

	const get_item_bg_color = (item) => {
		const roster_date = moment(item.roster_date);
		if (roster_date.isSame(moment(), 'day')) {
			return theme.colors.primary;
		} else if (roster_date.isBefore(moment(), 'day')) {
			if (!item.shift_id) return theme.colors.lightGrey7;
			// if (item.attendance === null || item.attendance?.length === 0) return theme.colors.lightRed;
			if (item.attendance === null || item.attendance?.length === 0) return theme.colors.lightGrey7;
			return theme.colors.darkGreen;
		} else if (roster_date.isAfter(moment(), 'day')) {
			if (item.shift_id) return theme.colors.lightGrey5;
			return theme.colors.lightGrey7;
		}
		return 'red';
	};

	const handle_all_orders = () => {
		history.push(Routes.ALL_ORDERS_BY_RIDER.path.replace(':id', rider_id));
	};

	const handle_edit_or_create_roster = (index) => {
		set_show_create_roster(true);
		selected_attendence_item_index.current = index;
	};

	const close_roster_modal = () => {
		set_show_create_roster(false);
		selected_attendence_item_index.current = -1;
	};

	const close_confirm_modal = () => {
		set_confirm_close_modal(_.cloneDeep(initial_confirm_modal_details));
	};

	const handle_time_scale = (type = 'am' /*am or pm*/) => {
		switch (type) {
			case 'am':
				set_shift_time(time_am);
				set_day_start_time(moment('12:00 AM', 'HH:mm A'));
				set_day_end_time(moment('11:00 AM', 'HH:mm A'));
				break;

			case 'pm':
				set_shift_time(time_pm);
				set_day_start_time(moment('12:00 PM', 'HH:mm A'));
				set_day_end_time(moment('11:00 PM', 'HH:mm A'));
				break;

			default:
				break;
		}
	};
	return {
		selected_tab,
		graph_date,
		chart_data,
		roster_date,
		show_date_range_picker,
		shift_time,
		is_active,
		rider_details,
		roster_details,
		show_rider_modal,
		show_create_roster,
		selected_attendence_item_index: selected_attendence_item_index.current,
		current_week_roster_date,
		confirm_close_modal,
		roster_details_week,
		day_start_time,
		set_show_rider_modal,
		set_show_date_range_picker,
		set_selected_tab,
		set_graph_date,
		change_status,
		get_hour_diff,
		on_roster_left_nav_press,
		on_roster_right_nav_press,
		get_item_bg_color,
		clear_device_id,
		handle_all_orders,
		fetch_roster_data,
		handle_edit_or_create_roster,
		close_roster_modal,
		close_confirm_modal,
		set_rider_details,
		handle_time_scale,
	};
};

export default useRiderProfile;
