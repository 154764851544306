export const LOGIN_DETAILS_RECEIVED = 'LOGIN_DETAILS_RECEIVED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const USER_DETAILS_RECEIVED = 'USER_DETAILS_RECEIVED';
export const REFRESH_MASTER_ZONES = 'REFRESH_MASTER_ZONES';

export const MASTER_ZONES_RECEIVED = 'MASTER_ZONES_RECEIVED';
export const BUSINESS_PROPERTIES_RECEIVED = 'BUSINESS_PROPERTIES_RECEIVED';

export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export const ADD_USER_TEAMS = 'ADD_USER_TEAMS';
export const REMOVE_ALL_ALERTS = 'REMOVE_ALL_ALERTS';

export const SUPPORT_MODAL_TOGGLE = 'SUPPORT_MODAL_TOGGLE';
export const TOGGLE_NOTIF_MODAL = 'TOGGLE_NOTIF_MODAL';
export const UPDATE_BIZ_LIST = 'UPDATE_BIZ_LIST';

export const ADD_NEW_NOTIF_COUNT = 'ADD_NEW_NOTIF_COUNT';
export const REMOVE_NOTIF_COUNT = 'REMOVE_NOTIF_COUNT';

export const ADD_NEW_ALERT_COUNT = 'ADD_NEW_ALERT_COUNT';
export const REMOVE_ALERT_COUNT = 'REMOVE_ALERT_COUNT';
export const UPDATE_NOTIF_COUNT = 'UPDATE_NOTIF_COUNT';

export const ADD_ENTERPRISE_CHILD_BUSINESS_DATA = 'ADD_ENTERPRISE_CHILD_BUSINESS_DATA';
export const PIDGE_TITAN_LIST = 'PIDGE_TITAN_LIST';
