const { makeStyles } = require('@material-ui/core');
const { default: theme } = require('resources/theme');

const useStyles = makeStyles(() => ({
	filter_btn: {
		backgroundColor: theme.colors.primary,
		marginBottom: 10,
		width: 40,
		height: 40,
		borderRadius: '100%',
	},
	icon_btn: {
		marginBottom: 10,
		width: 35,
		height: 35,
		borderRadius: '100%',
	},
	textBox: { flex: 1, border: 'none', outline: 'none' },
	riderTask: {
		backgroundColor: theme.colors.white,
		borderRadius: 40,
		display: 'flex',
		padding: 10,
		marginRight: 10,
		width: 150,
		height: 40,
		justifyContent: 'space-evenly',
		'&:hover': { cursor: 'pointer' },
	},
	riderTaskToggle: {
		backgroundColor: theme.colors.primary,
		color: theme.colors.white,
		borderRadius: 40,
		display: 'flex',
		padding: 10,
		marginRight: 10,
		width: 150,
		height: 40,
		justifyContent: 'space-evenly',
		'&:hover': { cursor: 'pointer' },
	},
	referenceId: {
		backgroundColor: theme.colors.white,
		borderRadius: 40,
		display: 'flex',
		marginRight: 10,
		height: 40,
		width: 280,
		paddingLeft: 16,
		paddingRight: 16,
	},
	searchRider: {
		backgroundColor: theme.colors.white,
		borderRadius: 40,
		display: 'flex',
		marginRight: 10,
		height: 40,
		width: 280,
		paddingLeft: 16,
		paddingRight: 16,
		border: '1px solid #492897',
	},
	number: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
	filterContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: 25,
		alignItems: 'center',
		position: 'absolute',
		zIndex: 999,
		width: '100%',
	},
	filter_bubble: {
		height: 32,
		paddingLeft: 12,
		paddingRight: 12,
		borderRadius: 30,
		backgroundColor: theme.colors.white,
		boxShadow: `0px 0px 16px 0px rgba(102, 102, 102, 0.10)`,
		display: 'flex',
		alignItems: 'center',
		marginRight: 4,
		cursor: 'pointer',
	},
	filter_circle: { width: 12, height: 12, borderRadius: 6, marginRight: 6 },
	gps_wrapper: {
		position: 'absolute',
		zIndex: 999,
		width: '100%',
		bottom: 140,
		right: 40,
		width: 32,
		height: 32,
		cursor: 'pointer',
		'&:hover': {
			'& .gps_light': {
				display: 'none',
			},
			'& .gps_purple': {
				display: 'block !important',
			},
		},
	},
	trace_text: {
		fontSize: 12,
		textDecoration: 'underline',
		color: theme.colors.primary,
		cursor: 'pointer',
	},
}));

export default useStyles;
