import { io } from 'socket.io-client';
import _ from 'lodash';
import constant from './constant';

const RETRY_TIMEOUT = 5000;
class Socket {
	constructor() {
		this.socket = null;
		this.shouldReconnect = false;
	}

	isSocketConnected = () => _.get(this.socket, 'connected');

	connectSocket({ params = {}, on_socket_connected }) {
		this.destroySocket();
		const url = constant.API_STORE_URL;
		this.socket = io(url, {
			transports: ['websocket'],
			query: params,
			reconnectionDelay: RETRY_TIMEOUT,
			path: '/v1.0/wss',
		});

		this.socket.on(constant.SOCKET_EVENTS.NOTIFICATION, this.handle_notif_events);
		this.socket.on(constant.SOCKET_EVENTS.TEAM, this.handle_team_events);
		this.socket.on(constant.SOCKET_EVENTS.ALERT, this.handle_alert_events);

		this.socket.on('connect', () => {
			on_socket_connected?.();
		});

		this.socket.on('reconnect', (attempt) => {
			on_socket_connected?.();
		});
	}

	destroySocket = () => {
		if (this.socket) {
			this.socket.off('connect_timeout');
			this.socket.off('error');
			this.socket.off('connect_error');
			this.socket.off('connect');
			this.socket.off('disconnect');
			this.socket.off('res');
			this.socket.disconnect();
			this.socket = null;
		}
	};
	emit = (event, data) => {
		if (this.socket && this.socket.connected) {
			this.socket.emit(event, data);
		}
	};
}
export default Socket;
