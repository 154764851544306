import { IconButton as MUIconButton } from '@material-ui/core';
import React from 'react';
import theme from 'resources/theme';

const IconButton = ({ children, style = {}, disabled = false, classes = {}, ...rest }) => {
	return (
		<MUIconButton
			color='primary'
			style={{
				...(disabled && { opacity: 0.5, backgroundColor: theme.colors.lightGrey2 }),
				...style,
			}}
			disabled={disabled}
			{...rest}>
			{children}
		</MUIconButton>
	);
};

export default IconButton;
