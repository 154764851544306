import { createTheme } from '@material-ui/core';

const basicTheme = createTheme({
	palette: {
		primary: {
			main: '#492897',
		},
		secondary: {
			main: '#492897',
		},
		white: {
			main: '#ffffff',
		},
		success: {
			main: '#0F8560',
		},
		error: {
			main: '#BC0000',
		},
	},
	overrides: {
		RaAutocompleteSuggestionList: {
			suggestionsContainer: {
				zIndex: 9000,
			},
		},
	},
});

const theme = {
	colors: {
		primary: '#492897',
		sideNavPrimary: '#492897',
		sideNavText: '#fff',
		sideNavSecondary: '#E4DCF3',
		sideNavHover: '#684CA9',
		lightPurple: '#E4DCF3',
		lightPurple2: '#B29DE2',
		lightPurple3: '#CABFE5',
		lightPurple4: '#E8CCE5',
		lightPurple5: '#7953D2',
		lightPurple6: '#CABDE9',
		lightPurple7: '#9980DB',
		lightPurple8: '#F8F0FF',
		lightPurple9: '#F3F0F9',
		lightPurple10: '#F8F7FB',
		darkPurple: '#8D027D',
		lightGrey: '#B4B4B4',
		lightGrey2: '#C4C4C4',
		lightGrey3: '#E0E0E0',
		lightGrey4: '#F1F1F1',
		lightGrey5: '#E5E5E5',
		lightGrey6: '#707070',
		lightGrey7: '#FAFAFA',
		lightGrey8: '#EEEEEE',
		lightGrey10: '#F9F9F9',
		lightPink1: '#FFEBFD',
		lightPink2: '#F6E6F5',
		lightPink3: '#B95BA4',
		darkGrey: '#989898',
		darkGrey2: '#666666',
		darkGrey3: '#766F83',
		darkGrey4: '#3C4043',
		darkYellow: '#F2C16B',
		lightYellow: '#FCF0DB',
		lightGreen: '#D4E8E2',
		lightGreen2: '#C2E2D8',
		lightRed: '#FFEAEC',
		red: '#BC0000',
		white: '#ffffff',
		black: '#000000',
		green: '#0F8560',
		lightPink: '#F3E5F2',
		lightWhite: '#F6F5FA',
		lightGreen3: '#6BB22F',
		lightGreen5: '#D4EDBF',
		lightGrey9: '#9C9C9C',
		compareMapColor1: '#B95BA4',
		compareMapColor2: '#F2C16B',
		compareMapColor3: '#D75800',
		compareMapColor4: '#81DAFE',
		compareMapColor5: '#E49DA8',
		compareMapColor6: '#229954',
		compareMapColor7: '#5499C7',
		compareMapColor8: '#5B2C6F',
		compareMapColor9: '#1C2833',
		compareMapColor10: '#17A589',
		darkGrey4: '#79747E',
		darkGrey5: '#49454F',
		lightGreen4: '#C7DFD8',
		lightOrange: '#FFE3CF',
		cardBackgroundColor1: '#F3E5F2',
		cardBackgroundColor2: '#FCF0DB',
		cardBackgroundColor3: '#FFE3CF',
		cardBackgroundColor4: '#E7F8FF',
		cardBackgroundColor5: '#FFF3F5',
		cardBackgroundColor6: '#D4EFDF',
		cardBackgroundColor7: '#D4E6F1',
		cardBackgroundColor8: '#E8DAEF',
		cardBackgroundColor9: '#D5D8DC',
		cardBackgroundColor10: '#D1F2EB',
		darkPink: '#FF718B',
		darkPurple2: '#962DFF',
		lightPink4: '#F2EEF8',
		red2: '#B3261E',
		lightGreen6: '#D8F8EE',
		lightGreen7: '#CBE2DB',
		darkGreen: '#86C2B0',
		lightGreen8: '#DFF1EC',
		lightPink5: '#CE89BE',
		lightGrey11: '#D9D9D9',
		lightPurple11: '#F3EFF9',
		lightGreen9: '#C0CA33',
		darkGreen1: '#43A047',
		orange: '#FF9800',
		lightGrey12: '#F3F3F3',
		lightPurple12: '#5C33BF',
		darkGrey6: '#F2F2F2',
		darkGrey7: '#1E1E1E',
		lightGreen10: '#DBF4EC',
		lightPurple13: '#E6E9F5',
		lightPink6: '#F4EFFC',
		lightBlue: '#4285F4',
		lightOrange2: '#EE6002',
		lightPink8: '#DB1960',
		lightGrey13: '#E9E9E9',
		lightGreen11: '#E8FBF5',
		lightOrange2: '#C78A20',
		lightPink9: '#FDFCFF',
		lightGrey14: '#666666BF',
		darkGrey8: '#666',
		lightPink10: '#F4F1FA',
		lightGreen12: '#CEEDE3',
		lightPurple14: '#E0DEF7',
	},
	...basicTheme,
};

export default theme;
