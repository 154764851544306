import ImageLinks from 'assets/images/ImageLinks';
import constant from 'resources/constant';
import { ReactComponent as DashboardIcon } from '../../assets/images/icons/dashboard.svg';
import { ReactComponent as CreateOrderIcon } from '../../assets/images/icons/create-order.svg';
import { ReactComponent as Orders } from '../../assets/images/icons/orders.svg';
import { ReactComponent as RoutesIcon } from '../../assets/images/icons/route_fork_white.svg';
import { ReactComponent as RidersIcon } from '../../assets/images/icons/rider.svg';
import { ReactComponent as FleetIcon } from '../../assets/images/icons/truck_white.svg';
import { ReactComponent as NetworkIcon } from '../../assets/images/icons/network_purple.svg';
import { ReactComponent as LiveTrackingIcon } from '../../assets/images/icons/live_tracking_purple.svg';
import { ReactComponent as TraceIcon } from '../../assets/images/icons/tracking-selected.svg';
import { ReactComponent as RupeeIcon } from '../../assets/images/icons/rupee-selected.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/alert_triangle_red_filled.svg';

export const drawerWidth = 240;

export const SEARCH_BY_OPTIONS = [
	{
		title: 'Rider',
		value: 'rider',
	},
	{
		title: 'Reference ID',
		value: 'reference_id',
	},
];

export const NAVBAR_ITEMS = {
	DASHBOARD: {
		id: 'dashboard',
		text: 'Dashboard',
		routeLink: '/dashboard',
		image: (style) => <DashboardIcon className={style} />,
	},
	CREATE_ORDER: {
		id: 'create_order',
		text: 'Create Order',
		routeLink: '/create-order',
		image: (style) => <CreateOrderIcon className={style} />,
	},
	ORDERS: {
		id: 'orders',
		text: 'Orders',
		routeLink: '/all-orders',
		image: (style) => <Orders className={style} />,
	},
	ROUTING: {
		id: 'routing',
		text: 'Routes',
		routeLink: '/grouping-and-allocation',
		image: (style) => <RoutesIcon className={style} />,
	},
	RIDERS: {
		id: 'riders',
		text: 'Riders',
		image: (style) => <RidersIcon className={style} />,
		routeLink: '/all-riders',
		SUB_MENU: {
			ROSTER: {
				id: 'roster',
				text: 'Roster & Attendance',
				routeLink: '/roster',
			},
		},
	},
	FLEET_MANAGEMENT: {
		id: 'fleet_management',
		text: 'Fleets',
		routeLink: '/fleet-management',
		image: (style) => <FleetIcon className={style} />,
	},

	TRACE_LISTING: {
		id: 'trace_listing',
		text: 'Trace',
		routeLink: '/trace-list',
		image: (style) => <TraceIcon className={style} />,
	},
	LIVE_TRACKING: {
		id: 'live_tracking',
		text: 'Live Tracking',
		routeLink: '/tracking',
		image: (style) => <LiveTrackingIcon className={style} />,
	},
	...(constant.ENVIRONMENT === constant.env.STAGING && {
		NETWORKS: {
			id: 'networks',
			text: 'Networks',
			routeLink: '/networks',
			image: (style) => <NetworkIcon className={style} />,
		},
	}),
	CASH_MANAGEMENT: {
		id: 'cash_management',
		text: 'COD Management',
		routeLink: '/cash-management',
		image: (style) => <RupeeIcon className={style} />,
	},
	ALERTS: {
		id: 'alerts',
		text: 'Alerts',
		routeLink: '/my-alerts',
		image: (style) => <AlertIcon className={style} style={{ height: 22, width: 22 }} />,
	},
};

export const NAVBAR_BOTTOM_ITEMS = {
	WALLET: {
		id: 'wallet',
		text: 'Wallet',
		routeLink: '/my-wallet',
		active_icon: ImageLinks.dashboard_selected,
		inactive_icon: ImageLinks.dashboard,
	},
	NOTIFICATIONS: {
		id: 'notifications',
		text: 'Notifications',
		active_icon: ImageLinks.dashboard_selected,
		inactive_icon: ImageLinks.dashboard,
	},
	SETTINGS: {
		id: 'settings',
		text: 'Settings',
		routeLink: '/settings',
		active_icon: ImageLinks.dashboard_selected,
		inactive_icon: ImageLinks.dashboard,
	},

	SUPPORT: {
		id: 'support',
		text: 'Support',
		active_icon: ImageLinks.dashboard_selected,
		inactive_icon: ImageLinks.dashboard,
	},
	BIZ_SWITCH: {
		id: 'biz_switch',
		text: 'biz_switch',
	},
};

export const SUB_MENU_NAVBAR_ITEMS = {
	ADVANCED_ORDER_ANALYTICS: {
		routeLink: '/advanced-order-analytics',
		text: 'Advanced Order Analytics',
	},
	ADVANCED_RIDER_ANALYTICS: {
		routeLink: '/advanced-rider-analytics',
		text: 'Advanced Rider Analytics',
	},
};
