import React from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import { styled, Switch, alpha } from '@material-ui/core';
import Button from 'modules/Button';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 18, marginBottom: 10 },
	sub_heading: { color: theme.colors.darkGrey2 },
	info_container: { width: 430, backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, height: 640 },
	update: { borderRadius: 30, fontSize: 16, marginTop: 10, width: 100 },
	calls_box_text: { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 4 },
	box_container: { display: 'flex', justifyContent: 'space-between' },
	info_sub_heading: { fontSize: 16, color: theme.colors.primary, marginTop: 30 },
	calling_container: { display: 'flex', justifyContent: 'space-between', alignItems: 'start', height: 500, marginTop: 30 },
	info_heading: { fontSize: 18, color: theme.colors.lightPurple7 },
	mask_unmask_subText: { fontSize: 12, marginTop: 10, marginBottom: 10 },
}));

const GreenSwitch = styled(Switch)(({ switch_color, theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: 'white',
		border: `1px solid ${switch_color}`,
		padding: 0,
		margin: 4,
		'&:hover': {
			backgroundColor: alpha(switch_color, theme.palette.action.hoverOpacity),
		},
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: switch_color,
		opacity: 1,
	},
}));

const Maskedcalls = [
	{ text: 'Call Recording', img_url: 'call_recording' },
	{ text: 'Call Logging', img_url: 'call_logging' },
	{ text: 'Data Privacy', img_url: 'data_privacy' },
	{ text: '₹0.5/ minute', img_url: 'call_minute' },
];
const Unmaskedcalls = [
	{ text: 'Direct call', img_url: 'direct_call' },
	{ text: 'Call Logging', img_url: 'call_logging' },
	{ text: 'Number Visible', img_url: 'number_visible' },
	{ text: 'Free of cost', img_url: 'free_of_cost' },
];

const Calling = ({ mask_call, handle_update_settings }) => {
	const [is_masked_call, set_is_masked_call] = React.useState(mask_call);

	const classes = useStyles();
	return (
		<>
			<div style={{ position: 'relative' }}>
				<div className={classes.heading_container}>
					<Text bold className={classes.heading}>
						Calling
					</Text>
					<ListItem
						key={`tips`}
						button
						onClick={() => {}}
						style={{
							backgroundColor: theme.colors.lightPurple,
							marginRight: 0,
							width: 80,
							borderRadius: 5,
							display: 'flex',
							justifyContent: 'center',
							padding: '8px 8px',
						}}>
						<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
							Tips & Info
						</Text>
					</ListItem>
				</div>
				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: 20, flex: '1' }}>
						<Text semi className={classes.sub_heading} style={{ fontSize: 14 }}>
							Instill confidence in your customers by protecting their privacy and masking their contact details from delivery executives. Masked
							calls are recorded and can also be heard later for quality assurance or customer resolution.
						</Text>
						<div className={classes.calling_container}>
							<div style={{ width: '75%' }}>
								<Text semi style={{ fontSize: 16 }}>
									Masked Calling
								</Text>
								<Text style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
									Hides the customer contact number from the delivery executive while calling.
								</Text>
							</div>
							<div style={{ display: 'flex' }}>
								<Text bold style={{ fontSize: 14, marginTop: 2 }}>
									{is_masked_call ? 'Active' : 'Inactive'}
								</Text>
								<div style={{ marginLeft: 10 }}>
									<GreenSwitch
										switch_color={theme.colors.green}
										size='small'
										checked={is_masked_call}
										onChange={(e) => {
											utils.track_event_for_analytics(constant.TRACKING_EVENTS.TOGGLE_MASKED_CALL, { maksed: !is_masked_call });
											set_is_masked_call(!is_masked_call);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className={classes.info_container}>
						<Text bold className={classes.info_heading}>
							Types of Calls
						</Text>
						<Text bold className={classes.info_sub_heading}>
							Masked Calls
						</Text>
						<Text className={classes.mask_unmask_subText}>
							Instill confidence in your customers by protecting their privacy and masking their contact details from delivery executives. Masked
							calls are recorded and can also be heard later for quality assurance or customer resolution.
						</Text>
						<div className={classes.box_container}>
							{_.map(Maskedcalls, (item, index) => {
								return (
									<div className={classes.calls_box_text}>
										<img src={ImageLinks[item.img_url]} width={80} height={80} />
										<Text semi style={{ fontSize: 11, color: theme.colors.primary }}>
											{item.text}
										</Text>
									</div>
								);
							})}
						</div>
						<Text bold className={classes.info_sub_heading}>
							Unmasked Calls
						</Text>
						<Text className={classes.mask_unmask_subText}>
							Not a recommended industry practice for logistics business as it reveals the data of your customers. Small businesses go for this
							method to avoid overhead costs.{' '}
						</Text>
						<div className={classes.box_container}>
							{_.map(Unmaskedcalls, (item, index) => {
								return (
									<div className={classes.calls_box_text}>
										<img src={ImageLinks[item.img_url]} width={80} height={80} />
										<Text semi style={{ fontSize: 11, color: theme.colors.primary }}>
											{item.text}
										</Text>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div style={{ position: 'absolute', bottom: 20, right: 30 }}>
				<Button
					onClick={() => {
						handle_update_settings({ mask_call: is_masked_call });
					}}
					type='round'
					text='Save'
					size='large'
					style={{ width: 110 }}
				/>
			</div>
		</>
	);
};

export default Calling;
