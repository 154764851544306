const { useRef, useEffect } = require('react');

const useDidMountEffect = (func, deps) => {
	const did_mount = useRef(false);
	useEffect(() => {
		if (did_mount.current) {
			func();
		} else {
			did_mount.current = true;
		}
	}, deps);
};

export default useDidMountEffect;
