import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Text from 'modules/Text';
import React, { useEffect, useRef, useState } from 'react';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';
import { failed_options, status_options } from '../OrderDetail/constants';
import constant from 'resources/constant';
import { shallowEqual, useSelector } from 'react-redux';
import utils from 'resources/utils';

const confirm_modal_details = {
	open: false,
	header_title: '',
	title: '',
	positive_text: '',
	negative_text: '',
	confirm_acion: () => {},
	is_error_msg: false,
	modal_icon: null,
};

const initial_pwp_allocation_modal_details = {
	open: false,
	mcp_ids: [],
	network_id: null,
	is_multiple_network: false,
	pickup_coords: null,
	drop_coords: null,
};

function useOrderModal() {
	const { app } = useSelector((state) => state, shallowEqual);
	const [id, set_id] = useState(null);
	const [parent_api_headers, set_parent_api_headers] = useState({});
	const [data, set_data] = useState({});
	const [pbid_data, set_pbid_data] = useState({});
	const [show_delivery_modal, set_show_delivery_modal] = useState(false);
	const [show_address_modal, set_show_address_modal] = useState(false);
	const [show_package_modal, set_show_package_modal] = useState(false);
	const [edit_address_type, set_edit_address_type] = useState(null);
	const [show_trip_modal, set_show_trip_modal] = useState(false);
	const [show_edit_delivery_date_slot, set_show_edit_delivery_date_slot] = useState(false);
	const [show_edit_cod, set_show_edit_cod] = useState(false);
	const [active_step, set_active_step] = useState(0);
	const [show_product_modal, set_show_product_modal] = useState(false);
	const [confirm_close_modal, set_confirm_close_modal] = useState(_.cloneDeep(confirm_modal_details));
	const [show_cdr_allocation, set_show_cdr_allocation] = useState(false);
	const [show_pwp_allocation, set_show_pwp_allocation] = useState(_.cloneDeep(initial_pwp_allocation_modal_details));
	const [cdr_allocation_data, set_cdr_allocation_data] = useState([]);
	const [serviceability_data, set_serviceability_data] = useState({});
	const [network_manager_data, set_network_manager_data] = useState({});
	const [active_trip, set_active_trip] = useState(0);
	const [trip_status, set_trip_status] = useState('');
	const [failed_reason, set_failed_reason] = useState('');
	const [show_status_strip, set_show_status_strip] = useState(false);
	const [show_rider_action_save, set_show_rider_action_save] = useState(false);
	const [trip_data, set_trip_data] = useState({});
	const [rider_location, set_rider_location] = useState({});
	const [show_buttons, set_show_buttons] = useState(false);
	const [link_sent_to, set_link_sent_to] = useState([]);
	const [show_rider_change_modal, set_show_rider_change_modal] = useState(false);
	const [show_raise_issue, set_show_raise_issue] = useState(false);
	const [show_raise_issue_button, set_show_raise_issue_button] = useState(false);
	const [ondc_issue_list, set_ondc_issue_list] = useState([]);
	const network_configs = _.get(app, 'app_configs.network_configs', {});
	const is_cod_type_ppn = useRef(false);

	const updater_ref = useRef(() => {});
	const close_ref = useRef(() => {});

	useEffect(() => {
		if (id) {
			get_data_from_id();
		}
	}, [id]);
	useEffect(async () => {
		if (
			!_.isEmpty(network_configs?.[data?.fulfillment?.channel?.id]) &&
			network_configs?.[data?.fulfillment?.channel?.id]?.implementation == 'pidge-lbnp'
		) {
			set_show_raise_issue_button(true);
			try {
				const res = await api_requests.get_igm_issue();
				set_ondc_issue_list(res.data);
			} catch (err) {
				console.log('this is err', err);
			}
		}
	}, [data]);
	useEffect(() => {
		set_trip_data(_.get(pbid_data, `trip_history.${active_trip}`, {}));
	}, [pbid_data, active_trip]);

	useEffect(() => {
		get_network_list();
	}, []);

	useEffect(() => {
		if (show_trip_modal && show_product_modal) {
			set_show_product_modal(false);
		}
		if (show_trip_modal) {
			utils.track_event_for_analytics(constant.TRACKING_EVENTS.ORDERMODAL_TRACKING);
		}
	}, [show_trip_modal]);

	useEffect(() => {
		if (show_trip_modal && show_product_modal) {
			set_show_trip_modal(false);
		}
	}, [show_product_modal]);

	const send_payment_link = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.PAYMENT_LINK);
		if (_.includes(link_sent_to, 'payment')) {
			return;
		}
		let _link_sent_to = _.cloneDeep(link_sent_to);
		_link_sent_to.push('payment');
		set_link_sent_to(_link_sent_to);
		try {
			const _data = {
				name: _.get(data, 'customer_detail.name', '-'),
				mobile: _.get(data, 'customer_detail.mobile', '-'),
			};
			const res = await api_requests.send_payment_link(pbid_data.id, _data, parent_api_headers);
		} catch (err) {}
	};

	const send_tracking_link = async (recipient_type) => {
		if (_.includes(link_sent_to, recipient_type)) {
			return;
		}
		let _link_sent_to = _.cloneDeep(link_sent_to);
		_link_sent_to.push(recipient_type);
		set_link_sent_to(_link_sent_to);
		try {
			const res = await api_requests.send_tracking_link(id, { recipient_type }, parent_api_headers);
		} catch (err) {}
	};

	const on_status_update = async () => {
		const remark = _.filter(status_options, (item) => item.value === trip_status)[0].title;
		const payload = {
			trip_type: trip_data.trip_type,
			trip_id: trip_data.trip_id,
			action: trip_status,
			remark,
		};
		if (trip_data.group_id) payload.group_id = trip_data.group_id;
		if (trip_status == 5) {
			if (!failed_reason) {
				toast.error('Please select reason for failed delivery.', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				return;
			}
			const reason_str = _.filter(failed_options, (item) => item.value === failed_reason)[0].title;
			payload.remark = reason_str;
		}

		try {
			const response = await (trip_status === 130
				? api_requests.auto_quick_scan(
						{
							trip_type: trip_data.trip_type,
							group_id: trip_data.group_id,
							remark: 'admin quick scan',
						},
						parent_api_headers,
				  )
				: api_requests.update_trip_status(payload, parent_api_headers));
			if (response.success) {
				toast.success('Status updated successfully.', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				setTimeout(() => {
					get_data_from_id();
					updater_ref.current?.();
				}, 1000);

				set_show_rider_action_save(false);
			}
		} catch (error) {}
	};

	const get_rider_location = async () => {
		if (data.status == 3) {
			const res = await api_requests.get_rider_location(id);
			set_rider_location(res.data);
		}
	};

	const get_network_list = async () => {
		set_network_manager_data(
			_.map(
				_.filter(app.network_list, (item) => {
					return item.can_manage;
				}),
				(item) => {
					return item.id;
				},
			),
		);
	};
	const handle_pickup_details = async (name, mobile) => {
		try {
			const res = await api_requests.edit_cod(data.id, { sender_detail: { name, mobile, address: data.sender_detail.address } }, parent_api_headers);
			get_data_from_id();
			updater_ref.current?.();
		} catch (err) {}
		set_show_delivery_modal(false);
	};

	const handle_update_address = async (address) => {
		if (edit_address_type === 'sender')
			try {
				const res = await api_requests.edit_cod(
					data.id,
					{
						sender_detail: {
							...data.sender_detail,
							address: {
								...address,
								address_line_1: address.address_line1 ? address.address_line1 : address.address_line_1,
								address_line_2: address.address_line2 ? address.address_line2 : address.address_line_2,
							},
						},
					},
					parent_api_headers,
				);
				get_data_from_id();
				updater_ref.current?.();
			} catch (err) {}
		if (edit_address_type != 'sender')
			try {
				const res = await api_requests.edit_cod(
					data.id,
					{
						customer_detail: {
							...data.customer_detail,
							address: utils.remove_null_and_empty_from_obj({
								address_line_1: address.address_line1 ? address.address_line1 : address.address_line_1,
								address_line_2: address.address_line2 ? address.address_line2 : address.address_line_2,
								landmark: address.landmark,
								state: address.state,
								id: address.id,
								pincode: address.pincode.toString(),
								latitude: address.latitude,
								longitude: address.longitude,
							}),
						},
					},
					parent_api_headers,
				);
				get_data_from_id();
				updater_ref.current?.();
			} catch (err) {}
		set_show_delivery_modal(false);
	};

	const handle_update_sender_contact = async (
		edit_details,
		receiver_name,
		receiver_contact,
		cod_amount,
		bill_amount,
		reference_id,
		formatted_date,
		delivery_slot,
	) => {
		try {
			const res = await api_requests.edit_cod(
				data.id,
				{
					customer_detail: {
						...data.customer_detail,
						name: receiver_name,
						mobile: receiver_contact,
					},
				},
				parent_api_headers,
			);
			get_data_from_id();
			updater_ref.current?.();
		} catch (err) {}
		set_show_delivery_modal(false);
	};

	const get_data_from_id = async () => {
		try {
			const res = await api_requests.get_pending_order_details(id, parent_api_headers);
			set_data(res.data);
			if (res.data.fulfillment && res.data.fulfillment.channel.id) {
				if (network_configs[res.data.fulfillment.channel.id]?.implementation == 'ppn-private' || res.data.fulfillment.channel.id == -2) {
					const res2 = await api_requests.pbid_trip_timelines(res.data.fulfillment.channel.order_id, parent_api_headers);
					if (res2.data) set_pbid_data(res2.data);
				}
			}
			set_show_buttons(true);
		} catch (err) {
			console.log('this is error', err);
		}
	};

	const handle_update_delivery_date_slot = async (
		edit_details,
		receiver_name,
		receiver_contact,
		cod_amount,
		bill_amount,
		reference_id,
		formatted_date,
		delivery_slot,
	) => {
		try {
			const res = await api_requests.edit_cod(
				data.id,
				{
					delivery_date: formatted_date,
					delivery_slot: delivery_slot,
				},
				parent_api_headers,
			);
			get_data_from_id();
			updater_ref.current?.();
		} catch (err) {}
		set_show_edit_delivery_date_slot(false);
	};

	const handle_update_cod_bill = async (
		edit_details,
		receiver_name,
		receiver_contact,
		cod_amount,
		bill_amount,
		reference_id,
		formatted_date,
		delivery_slot,
	) => {
		try {
			if (is_cod_type_ppn.current && cod_amount > 0) {
				toast.error('COD Amount can only be 0 for PPN', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				return;
			}
			const res = await api_requests.edit_cod(
				data.id,
				{
					cod_amount,
					bill_amount,
				},
				parent_api_headers,
			);
			get_data_from_id();
			updater_ref.current?.();
		} catch (err) {}
		set_show_edit_delivery_date_slot(false);
	};

	const handle_package_update = async (packages) => {
		const _packages = _.map(packages, (pack) => {
			return {
				id: pack.id ? pack.id : pack.package_id,
				quantity: pack.quantity,
				...(pack.volumetric_weight && { volumetric_weight: pack.volumetric_weight }),
			};
		});
		try {
			const res = await api_requests.edit_cod(data.id, { packages: _packages }, parent_api_headers);
			get_data_from_id();
			updater_ref.current?.();
		} catch (err) {}
	};

	const handle_cancel_order = async (is_confirm = false) => {
		if (!is_confirm) {
			set_confirm_close_modal({
				open: true,
				headerTitle: 'Cancel Order',
				title: `Are you sure you want to delete ${data.id}? This action cannot be undone.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				confirm_acion: () => {
					handle_cancel_order(true);
				},
				show_negative_button: false,
				is_error_msg: true,
				modal_icon: null,
			});
			return;
		}
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.CANCEL_ORDER);
		try {
			const res = await api_requests.cancel_pending_orders(data.id, parent_api_headers);
			get_data_from_id();
			updater_ref.current?.();
			close_ref.current?.();
		} catch (err) {}
	};

	const handle_allocate_click = async () => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.ORDER_ALLOCATE_BTN);
		if (data.status == 3 || data.status == 4) {
			handle_pwp_allocate_click();
			return;
		}
		try {
			const response = await api_requests.get_pending_order_details(data.id, parent_api_headers);
			set_cdr_allocation_data([response.data]);
			set_show_cdr_allocation(true);
		} catch (error) {}
	};

	const handle_pwp_allocate_click = async () => {
		try {
			if (
				!_.isNull(_.get(pbid_data, 'schedule_time', null)) ||
				!_.isNull(_.get(pbid_data, 'bundle_label', null)) ||
				!_.isNull(_.get(pbid_data, 'supplier_id', null))
			) {
				toast.error('Please unroute order to proceed', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});

				return;
			}
			const res = await api_requests.get_serviceability({ mcpIds: pbid_data.id }, parent_api_headers);
			set_serviceability_data(res.data);
			const pickup_coords = _.get(res, 'data.data.0.pickup', null);
			const drop_coords = _.map(_.get(res, 'data.data', []), (item) => item.drop);
			set_show_pwp_allocation({
				open: true,
				pickup_coords,
				drop_coords,
				mcp_ids: [pbid_data.id],
				is_multiple_network: false,
				network_id: pbid_data.fulfillment_network_id > 0 ? pbid_data.fulfillment_network_id : null,
			});
			// set_show_pwp_allocation(true);
			// close_ref.current?.();
		} catch (error) {}
	};

	const handle_unallocate_order = async (is_confirm = false) => {
		if (!is_confirm) {
			set_confirm_close_modal({
				open: true,
				headerTitle: 'Unallocate Order',
				title: `Are you sure you want to unallocate ${data.id}? Order will move to Pending in All Orders.`,
				positive_text: 'Confirm',
				negative_text: 'Cancel',
				confirm_acion: () => {
					handle_unallocate_order(true);
				},
				show_negative_btn: true,
				is_error_msg: true,
				modal_icon: null,
			});
			return;
		}
		try {
			const res = await api_requests.cancel_fulfilment_order(data.id, true, parent_api_headers);
			get_data_from_id();
			updater_ref.current?.();
			close_ref.current?.();
		} catch (err) {}
	};

	const handle_unroute_order = async (is_confirm = false) => {
		utils.track_event_for_analytics(constant.TRACKING_EVENTS.ORDERMODAL_UNROUTE);
		if (!is_confirm) {
			set_confirm_close_modal({
				open: true,
				headerTitle: 'Unroute Order',
				title: (
					<Text>
						Are you sure you want to unroute{' '}
						<Text bold component={'span'}>
							{data.id}
						</Text>
						? This action cannot be undone.
					</Text>
				),
				positive_text: 'Unroute',
				negative_text: 'Cancel',
				confirm_acion: () => {
					handle_unroute_order(true);
				},
				show_negative_btn: true,
				is_error_msg: false,
				modal_icon: ImageLinks.unroute_ripple,
			});
			return;
		}
		try {
			const res = await api_requests.ungroup_trip({ mcp_ids: [pbid_data.id] }, parent_api_headers);
			get_data_from_id();
			updater_ref.current?.();
			close_ref.current?.();
		} catch (err) {}
	};

	const handle_change_rider = async (new_rider) => {
		let data = utils.remove_null_and_empty_from_obj({
			oldRiderId: trip_data.rider.id,
			newRiderId: new_rider?.rider_id,
			tripIds: [trip_data.trip_id],
		});
		try {
			const res = await api_requests.handover_trip_rider(data, parent_api_headers);
			get_data_from_id();
			set_show_rider_change_modal(false);
			window.location.reload(true);
		} catch (err) {}
	};
	return {
		id,
		set_id,
		data,
		set_data,
		show_delivery_modal,
		set_show_delivery_modal,
		show_address_modal,
		set_show_address_modal,
		edit_address_type,
		set_edit_address_type,
		show_package_modal,
		set_show_package_modal,
		handle_pickup_details,
		show_trip_modal,
		set_show_trip_modal,
		handle_update_address,
		handle_update_sender_contact,
		show_edit_delivery_date_slot,
		set_show_edit_delivery_date_slot,
		handle_update_delivery_date_slot,
		show_edit_cod,
		set_show_edit_cod,
		handle_update_cod_bill,
		active_step,
		set_active_step,
		show_product_modal,
		set_show_product_modal,
		handle_package_update,
		confirm_close_modal,
		set_confirm_close_modal,
		handle_cancel_order,
		updater_ref,
		close_ref,
		show_cdr_allocation,
		set_show_cdr_allocation,
		cdr_allocation_data,
		handle_allocate_click,
		handle_unallocate_order,
		pbid_data,
		show_pwp_allocation,
		set_show_pwp_allocation,
		handle_unroute_order,
		serviceability_data,
		set_serviceability_data,
		handle_pwp_allocate_click,
		network_manager_data,
		trip_status,
		set_trip_status,
		failed_reason,
		set_failed_reason,
		show_status_strip,
		set_show_status_strip,
		active_trip,
		set_active_trip,
		trip_data,
		on_status_update,
		status_options,
		show_rider_action_save,
		set_show_rider_action_save,
		get_rider_location,
		rider_location,
		show_buttons,
		send_tracking_link,
		send_payment_link,
		link_sent_to,
		show_rider_change_modal,
		set_show_rider_change_modal,
		handle_change_rider,
		parent_api_headers,
		set_parent_api_headers,
		network_configs,
		is_cod_type_ppn,
		show_raise_issue,
		set_show_raise_issue,
		ondc_issue_list,
		show_raise_issue_button,
	};
}

export default useOrderModal;
