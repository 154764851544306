import { makeStyles } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import ContainedButton from 'modules/ContainedButton';
import Select from 'modules/Select';
import Text from 'modules/Text';
import moment from 'moment';
import React from 'react';
import theme from 'resources/theme';

const useStyles = makeStyles((theme) => ({
	pickupText: { fontSize: 24 },
	pickupSlotContainer: { height: 80, display: 'flex', alignItems: 'center' },
	selectDateText: { fontSize: 14, color: theme.colors.darkGrey2, marginRight: 10 },
	textDate: {
		marginTop: 12,
		fontSize: 16,
		background: 'white',
		borderRadius: 20,
		height: 24,
		width: 24,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	selectTimeContainer: { display: 'flex', alignItems: 'center', marginTop: 30 },
	selectTimeText: { fontSize: 14, color: theme.colors.darkGrey2, marginRight: 10 },
	date_item_wrapper: {
		borderRadius: 50,
		width: 32,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginRight: 15,
		cursor: 'pointer',
		padding: '4px 0',
	},
}));

const Schedule = ({ schedule_slots, handle_schedule_close, selected_date, set_selected_date, selected_time, set_selected_time }) => {
	const classes = useStyles();

	const handle_date_select = (date) => {
		set_selected_date(date);
		set_selected_time(null);
	};

	const handle_time_select = (value) => {
		set_selected_time(value);
	};
	const selected_date_slots = _.find(schedule_slots, { date: selected_date })?.slots || [];

	return (
		<div style={{ background: theme.colors.white, borderTop: `1px solid ${theme.colors.lightGrey4}` }}>
			<ContainedButton
				onClick={handle_schedule_close}
				style={{ borderRadius: 100, width: 24, height: 24, position: 'absolute', right: 10, top: 20, background: 'transparent', boxShadow: 'unset' }}>
				<img src={ImageLinks.cross_purple} alt='cross' />
			</ContainedButton>
			<div style={{ padding: '20px 20px' }}>
				<div className={classes.pickupSlotContainer}>
					<Text semi className={classes.selectDateText}>
						Select Date
					</Text>
					{_.map(schedule_slots, (slot, key) => {
						const isSelected = selected_date === slot.date;
						const disabled = _.isEmpty(slot.slots);

						return (
							<div
								key={`date${key}`}
								className={classes.date_item_wrapper}
								style={{
									...(isSelected && { background: theme.colors.primary }),
									...(disabled && { opacity: 0.6 }),
								}}
								onClick={() => {
									!disabled && handle_date_select(slot.date);
								}}>
								<Text bold style={{ fontSize: 12, ...(isSelected && { color: 'white' }) }}>
									{moment(slot.date, 'YYYY-MM-DD').format('ddd')}
								</Text>
								<Text bold className={classes.textDate} style={{ color: isSelected ? theme.colors.primary : theme.colors.black }}>
									{moment(slot.date, 'YYYY-MM-DD').format('DD')}
								</Text>
							</div>
						);
					})}
				</div>
				<div className={classes.selectTimeContainer}>
					<Text semi className={classes.selectTimeText}>
						Select Time
					</Text>

					<Select
						value={selected_time}
						defaultOption={{
							title: `Select Time`,
							value: null,
						}}
						options={_.map(selected_date_slots, (option) => {
							return { title: option.title, value: option.value };
						})}
						menu_styles={{ height: 100, width: 200 }}
						containerStyles={{ width: 200, height: 32 }}
						handleChange={handle_time_select}
					/>
				</div>
			</div>
		</div>
	);
};

export default Schedule;
