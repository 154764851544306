import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Text from 'modules/Text';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import OutlinedButton from 'modules/OutlinedButton';
import constant from 'resources/constant';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import utils from 'resources/utils';
import OutlinedTextField from 'modules/OutlinedTextField';
import InfoIcon from '@material-ui/icons/Info';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 600,
		backgroundColor: theme.palette.background.paper,
		padding: 20,
		borderRadius: 20,
	},
	start_chat_button_container: {
		marginTop: 46,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 20,
	},
	support_information: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 24,
	},
	support_info_description: {
		color: theme.colors.darkGrey2,
		display: 'flex',
		alignItems: 'center',
	},
	ok_button_container: {
		marginTop: 32,
		textAlign: 'right',
	},
	ok_button_alignment: {
		boxShadow: 'unset',
		borderRadius: 30,
		paddingLeft: 36,
		paddingRight: 36,
	},
}));

const Support = ({ is_kyc, is_discover, close }) => {
	const classes = useStyles();
	const [message_content, set_message_content] = useState('');
	const [show_confirmation, set_show_confirmation] = useState(false);
	const {
		user_details: { name, user_id, mobile },
		business_accounts,
		selected_business_id: business_id_information,
	} = useSelector(({ app }) => app);

	useEffect(() => {
		if (is_kyc) {
			const filtered = _.filter(business_accounts, (item) => item.id === business_id_information)[0];
			let msg = `Hi \nCustomer Name: ${name} \nMobile Number: ${mobile} \nPidge Business Name (ID): ${filtered.brand_name} (${business_id_information}) \nI want to start my KYC validation process.`;
			set_message_content(msg);
		}
		if (is_discover) {
			let msg = `Hi, I would like to discover local delivery partners in [Area]`;
			set_message_content(msg);
		}
	}, [is_kyc, is_discover]);

	const selected_business_information = business_accounts.filter((business_account_info) => business_account_info.id === business_id_information)[0];
	const type_of_owner = utils._retrieveData(constant.TYPE_OF_OWNER);
	const user_type = utils._retrieveData(constant.TYPE_OF_OWNER).id;

	const custom_attribute_data = {
		user_name: name,
		user_id: user_id,
		user_type: user_type,
		business_name: _.get(selected_business_information, 'brand_name', ''),
		owner_id: _.get(selected_business_information, 'id', ''),
		type_of_owner: type_of_owner.id,
		...(is_kyc && { flow: ' kyc' }),
		...(is_discover && { flow: ' network_discover' }),
	};

	return (
		<Modal open={true} onClose={close}>
			<div className={classes.paper} style={{ top: '42%', left: '40%', transform: 'translate(-20%, -30%)' }}>
				{show_confirmation ? (
					<div className='d-flex'>
						<div style={{ paddingRight: 16 }}>
							<img src={ImageLinks.tick_circle_green_solid_ripple} alt='tick-circle-green-solid-ripple' width={48} height={48} />
						</div>
						<div>
							<Text bold style={{ fontSize: 18, marginBottom: 4 }}>
								Chat Initiated
							</Text>
							<Text medium style={{ fontSize: 14 }}>
								Chat with Support Team has been initiated in a new tab. Do not close or refresh the chat window.
							</Text>
							<div className={classes.ok_button_container}>
								<ContainedButton
									className={classes.ok_button_alignment}
									onClick={() => {
										set_show_confirmation(false);
										close();
									}}>
									<Text bold style={{ fontSize: 18 }}>
										OK
									</Text>
								</ContainedButton>
							</div>
						</div>
					</div>
				) : (
					<>
						<div className='justify-content-between d-flex'>
							<div>
								<Text bold style={{ fontSize: 20 }}>
									Support
								</Text>
							</div>
							<div>
								<img src={ImageLinks.crossBlack} alt='cross' className='cursor-pointer' onClick={() => close()} />
							</div>
						</div>
						<OutlinedTextField
							id='message'
							label='Message'
							placeholder='Write your message here'
							fullWidth
							className='h-100'
							value={message_content}
							margin='normal'
							InputLabelProps={{
								shrink: true,
							}}
							style={{ marginTop: 20 }}
							onChange={(e) => set_message_content(e.target.value)}
							multiline
							rows={7}
							variant='outlined'
						/>
						<div className={classes.support_info_description}>
							<img
								src={ImageLinks.information_reverse}
								alt='information-reverese'
								width={12}
								height={12}
								style={{ background: theme.colors.darkGrey2, marginRight: 4, borderRadius: '50%' }}
							/>
							<Text medium style={{ fontSize: 12, lineHeight: '14px' }}>
								Please note: You will be redirected to a new tab. Do not close or refresh the chat window.
							</Text>
						</div>
						<div className={classes.start_chat_button_container}>
							<a
								href={`${constant.CHAT_BOT_URL}widget?website_token=${
									constant.WEBSITE_TOKEN
								}&source_message=${message_content}&source_contact_name=${name}&source_custom_attributes=${JSON.stringify(
									custom_attribute_data,
								)}`}
								target='_blank'
								style={{ textDecoration: 'unset', color: 'inherit' }}>
								<Button
									type='round'
									text='Start Chat'
									size='large'
									onClick={() => set_show_confirmation(true)}
									disabled={!message_content.length}
									style={{ width: 'fit-content' }}
								/>
							</a>
						</div>
						{/* <Divider />
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<div className={classes.support_information}>
							<div style={{ marginRight: 30 }}>
								<img src={ImageLinks.document_sample} width='40px' height='40px' alt='document-sample' />
							</div>
							<div>
								<Text medium style={{ fontSize: 14, marginBottom: 6 }}>
									Explore features in depth
								</Text>
								<OutlinedButton style={{ color: theme.colors.primary, border: `1px solid ${theme.colors.primary}` }}>
									<Text semi style={{ fontSize: 12 }}>
										Help Center
									</Text>{' '}
									<img src={ImageLinks.arrow_right_purple} width='16px' height='16px' alt='arrow right' style={{ marginLeft: 12 }} />
								</OutlinedButton>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
						<div className={classes.support_information}>
							<div style={{ marginRight: 30 }}>
								<img src={ImageLinks.document_sample} width='40px' height='40px' alt='document-sample' />
							</div>
							<div>
								<Text medium style={{ fontSize: 14, marginBottom: 6 }}>
									Get the product tour
								</Text>
								<OutlinedButton style={{ color: theme.colors.primary, border: `1px solid ${theme.colors.primary}` }}>
									<Text semi style={{ fontSize: 12 }}>
										Onboarding Guide
									</Text>{' '}
									<img src={ImageLinks.arrow_right_purple} width='16px' height='16px' alt='arrow right' style={{ marginLeft: 12 }} />
								</OutlinedButton>
							</div>
						</div>
					</Grid>
				</Grid> */}
					</>
				)}
			</div>
		</Modal>
	);
};

export default Support;
