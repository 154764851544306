import ImageLinks from 'assets/images/ImageLinks';

export const ALL_ORDERS_MENU_ITEMS = {
	Overview: {
		id: 'overview',
		text: 'Overview',
	},
	Pending: {
		id: 'pending',
		text: 'Pending',
	},
	ReadyToShip: {
		id: 'ready_to_ship',
		text: 'AWB Manifested',
	},
	Shipped: {
		id: 'shipped',
		text: 'Shipped',
	},
	Terminal: {
		id: 'completed',
		text: 'Completed',
	},
	Cancelled: {
		id: 'cancelled',
		text: 'Cancelled',
	},
};

export const LOAD_ALL_STATES = {
	INITIAL: 'initial',
	STARTED: 'started',
	COMPLETED: 'completed',
};
