import { useSelector } from 'react-redux';
import api_requests from 'resources/api_requests';

export const UploadS3Document = async (path, file) => {
	const res = await api_requests.upload_document({
		path: path,
		content_type: file.type,
		content_length: file.size,
		permission: 'public',
	});

	const requestOptions = {
		method: 'PUT',
		body: file,
	};
	const response = await fetch(res.data.upload_url, requestOptions);

	return res;
};
