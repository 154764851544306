export const TYPES = {
	BUSINESS: 'business',
	NETWORK: 'network',
};

export const AVAILABLE_RIDER_TYPE = {
	AVAILABLE: 'available',
	LOWEST_COST: 'lowest_cost',
	BORADCAST: 'broadcast',
};

export const ALLOCATE_OPTIONS = [
	{
		title: 'Available',
		key: 'available',
	},
	{
		title: 'Lowest Cost',
		key: 'lowest_cost',
	},
	// {
	// 	title: 'Broadcast',
	// 	key: 'broadcast',
	// },
];

export const BOTTOM_STATE = {
	start: 'start',
	in_progress: 'in_progress',
	success: 'success',
	failed: 'failed',
};
