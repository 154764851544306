import _ from 'lodash';
import Text from 'modules/Text';
import React from 'react';
import { Marker, Popup } from 'react-leaflet';

const TaskMarkers = ({ route, marker_icon, on_marker_click }) => {
	return (
		<Marker
			position={{ lat: route.task_location.latitude, lng: route.task_location.longitude }}
			data={route}
			icon={marker_icon}
			eventHandlers={{
				click: on_marker_click,
				mouseout: (e) => {
					e.target.closePopup();
				},
				mouseover: (e) => {
					e.target.openPopup();
				},
			}}>
			<Popup className='rider_popup'>
				<Text semi style={{ fontSize: 14 }}>
					{route.task_type === 20 ? _.get(route, 'receiver_name', '-') : _.get(route, 'sender_details.name', '-')}
				</Text>
				<Text medium style={{ fontSize: 12, marginTop: -10, paddingTop: 0 }}>
					({route.mcp_id})
				</Text>
			</Popup>
		</Marker>
	);
};

export default TaskMarkers;
