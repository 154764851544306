import _ from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { makeStyles, Modal } from '@material-ui/core';
import Text from 'modules/Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import Button from 'modules/Button';
import Select from 'modules/Select';
import { CATEGORY } from '../helper';
import OutlinedTextField from 'modules/OutlinedTextField';
import { toast } from 'react-toastify';
import Routes from 'resources/Routes';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { UploadS3Document } from 'modules/UploadS3Document';
import api_requests from 'resources/api_requests';

const useStyles = makeStyles(() => ({
	modalContainer: { display: 'flex', outline: 'none' },
	componentContainer: {
		padding: 24,
		paddingBottom: 0,
		background: 'white',
		width: 600,
		margin: 'auto auto',
		borderRadius: 20,
		display: 'flex',
		flexDirection: 'column',
		outline: 'none',
	},
	headerTitle: { fontSize: 18, width: '100%', paddingTop: 5 },
	textContainer: { fontSize: 14, marginTop: 8 },
	containedButtonsContainer: { marginTop: 32, marginBottom: 24, display: 'flex', alignSelf: 'flex-end' },
	button: {
		'&:hover': {
			background: 'linear-gradient(180deg, #BC0000 0%, #620101 112.5%) !important',
			boxShadow: '0px 8px 20px 0px rgba(102, 102, 102, 0.15) !important',
			border: 'none !important',
		},
	},
	image_upload_wrap: {
		border: `1px dashed ${theme.colors.lightGrey2}`,
		height: 100,
		width: 172,
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 8,
		cursor: 'pointer',
	},
	cross_button: {
		position: 'absolute',
		top: -8,
		right: -8,
	},
	cross_wrap: {
		height: 16,
		width: 16,
		borderRadius: 8,
		backgroundColor: theme.colors.lightRed,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
	},
	image_wrap: {
		display: 'flex',
		gap: 20,
		marginTop: 10,
	},
}));

const CreateIssueModal = ({ close, style = {}, positive_btn_text = 'Confirm', is_error_msg = false, order_id }) => {
	const classes = useStyles();
	const history = useHistory();
	const [category, set_category] = useState('');
	const [description, set_description] = useState('');
	const [images, set_images] = useState([]);
	const [is_image_size_error, set_is_image_size_error] = useState(false);
	const upload_img_ref = useRef(null);

	const handle_confirm = async () => {
		try {
			const data = {
				id: order_id,
				issue_detail: {
					category: category,
					short_desc: _.filter(CATEGORY, (item) => item.value == category)[0].title,
					long_desc: description,
					image_urls: images,
				},
			};
			const response = await api_requests.create_igm_issue(data);
			close();
			history.push(Routes.IGM_ISSUE.path);
			toast.success('Ticket Raise Successfully', {
				position: toast.POSITION.TOP_CENTER,
			});
		} catch (error) {
			toast.error(error.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
		}
	};
	const handle_img_upload = async (e) => {
		const file = e.target.files[0];
		if (file) {
			let extension = '';
			extension = _.split(file.name, '.');
			extension = extension[extension.length - 1];
			if (!_.includes(['jpg', 'jpeg', 'png'], extension.toLowerCase())) {
				toast.error('Accept JPG, JPEG and PNG format Only', {
					position: toast.POSITION.BOTTOM_RIGHT,
				});
				return;
			}
			let _images = _.cloneDeep(images);
			if (file.size / 1024 > 3072) {
				set_is_image_size_error(true);
				return;
			}

			try {
				let path = `ondc/order__${order_id}/ticket_image_${Date.now()}.${extension}`;
				const res = await UploadS3Document(path, file);
				if (res.success) {
					_images.push({
						file_name: file.name,
						path: res.data.url,
					});
					set_images(_images);
				}
			} catch (err) {
				console.log('this is erro', err);
			}
		}
	};

	const remove_img = (index) => {
		let _images = _.cloneDeep(images);
		_images.splice(index, 1);
		set_images(_images);
	};
	const btn_disabled = useMemo(() => {
		let val = true;
		if (category != '' && description != '') {
			val = false;
		}
		if (category == 'FLM04' && _.isEmpty(images)) {
			val = true;
		}
		return val;
	}, [category, description, images]);
	return (
		<Modal open={true} onClose={close} className={classes.modalContainer}>
			<div className={classes.componentContainer} style={style}>
				<div style={{ display: 'flex' }}>
					<div style={{ marginLeft: 16, width: '100%' }}>
						<div style={{ display: 'flex' }}>
							<Text semi className={classes.headerTitle}>
								Raise Ticket
							</Text>
							<div style={{ cursor: 'pointer' }} onClick={close}>
								<img src={ImageLinks.crossBlack} style={{ width: 16, height: 16, paddingTop: 0 }} alt='' />
							</div>
						</div>
						<div className='d-flex' style={{ gap: 20, marginTop: 20, flexDirection: 'column' }}>
							<Select
								value={category}
								defaultOption={{
									title: `Select Ticket type`,
									value: '',
								}}
								options={CATEGORY}
								handleChange={(v) => set_category(v)}
								containerStyles={{
									height: 36,
									width: '80%',
									marginTop: 5,
									border: `1px solid ${theme.colors.lightPurple}`,
									boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
								}}
								text_props={{ semi: true }}
								menu_styles={{
									width: 250,
									height: 'fit-content',
								}}
							/>
							<OutlinedTextField
								value={description}
								type='text'
								multiline
								minRows={3}
								textStyle={{ fontSize: 16, color: theme.colors.black }}
								name='network_desc'
								onChange={(e) => {
									set_description(e.target.value);
								}}
								label='Description*'
								style={{ width: '80%', opacity: 1 }}
							/>
							<div className='d-flex' style={{ gap: 4, flexDirection: 'column' }}>
								<div>
									<Text semi className={classes.header_text}>
										Upload Image (up to 3)
									</Text>
									<Text medium style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
										Preferred image format: JPEG, PNG, SVG.
									</Text>
								</div>
								<input
									style={{ display: 'none' }}
									type='file'
									ref={upload_img_ref}
									onChange={(e) => {
										handle_img_upload(e);
									}}
								/>
								<div className={classes.image_wrap}>
									{images.length < 3 && (
										<div className={classes.image_upload_wrap} onClick={() => upload_img_ref.current.click()}>
											<img src={is_image_size_error ? ImageLinks.exclamation_error : ImageLinks.upload_light_purple} />
											<Text>Upload Image</Text>
											<Button type='round' size='extra_small' text={'Select File'} />
										</div>
									)}
									{_.map(images, (image, index) => {
										return (
											<div style={{ position: 'relative' }}>
												<img
													src={image.path}
													height={100}
													width={100}
													onClick={() => window.open(image.path, '_blank')}
													style={{ cursor: 'pointer' }}
												/>
												<div
													className={clsx(classes.cross_button, classes.cross_wrap)}
													onClick={() => {
														remove_img(index);
													}}>
													<img src={ImageLinks.crossRed} height={10} width={10} />
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={classes.containedButtonsContainer}>
					<Button
						type='round'
						size='large'
						text={positive_btn_text}
						onClick={handle_confirm}
						className={is_error_msg ? classes.button : ''}
						disabled={btn_disabled}
						style={{
							minWidth: 110,
						}}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default CreateIssueModal;
