import {
	BUSINESS_PROPERTIES_RECEIVED,
	LOGIN_DETAILS_RECEIVED,
	LOGIN_FAILED,
	MASTER_ZONES_RECEIVED,
	SHOW_LOADER,
	HIDE_LOADER,
	USER_DETAILS_RECEIVED,
	REFRESH_MASTER_ZONES,
	ADD_USER_TEAMS,
	UPDATE_BIZ_LIST,
	ADD_ENTERPRISE_CHILD_BUSINESS_DATA,
	PIDGE_TITAN_LIST,
} from 'actions/actionConstants';
import _ from 'lodash';
import constant from 'resources/constant';
import utils from 'resources/utils';

const initialState = {
	user: {},
	user_details: {},
	business_accounts: [],
	selected_business_id: null,
	zones: [],
	business_properties: {},
	bop_packages: [],
	permissions: [],
	status: constant.REDUX_STATE.IDLE,
	is_loading: false,
	refresh_master_zones: false,
	user_teams: [],
	selected_business_account: {},
	app_configs: {},
	network_list: [],
	enterprise_child_business_data: [],
	pidge_titan_list: [],
};

const app = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_DETAILS_RECEIVED: {
			const businessId = action.selected_business.business_id;
			const parent_id =
				action.selected_business.type_of_owner == constant.OWNER_TYPE.AGGREGATOR.id
					? action.selected_business.business_id
					: action.selected_business.parent_id;
			utils.setRequestAuthHeaderBusinessId(businessId, parent_id);

			return {
				...state,
				status: constant.REDUX_STATE.SUCCEEDED,
				user: action.user_details,
				business_accounts: action.business_accounts,
				selected_business_id: businessId,
				permissions: action.permissions,
				bop_packages: action.bop_packages,
				user_teams: action.user_teams,
				selected_business_account: action.selected_business,
				app_configs: action.app_configs,
				network_list: action.network,
				enterprise_child_business_data: action.enterprise_child_business_data,
			};
		}

		case USER_DETAILS_RECEIVED: {
			return {
				...state,
				user_details: {
					name: action.name,
					mobile: action.mobile,
					user_id: action.user_id,
					user_type: action.user_type,
					user_email: action.user_email,
				},
			};
		}

		case MASTER_ZONES_RECEIVED: {
			return { ...state, zones: action.zones };
		}

		case BUSINESS_PROPERTIES_RECEIVED: {
			return { ...state, business_properties: action.business_properties };
		}

		case LOGIN_FAILED:
			return { ...state, status: constant.REDUX_STATE.FAILED };

		case REFRESH_MASTER_ZONES:
			return { ...state, refresh_master_zones: !state.refresh_master_zones };

		case SHOW_LOADER:
			return { ...state, is_loading: true };
		case HIDE_LOADER:
			return { ...state, is_loading: false };

		case ADD_USER_TEAMS: {
			return { ...state, user_teams: action.user_teams };
		}

		case UPDATE_BIZ_LIST: {
			return { ...state, business_accounts: action.business_accounts };
		}

		case ADD_ENTERPRISE_CHILD_BUSINESS_DATA: {
			return { ...state, enterprise_child_business_data: action.enterprise_child_business_data };
		}

		case PIDGE_TITAN_LIST: {
			return { ...state, pidge_titan_list: action.pidge_titan_list };
		}
		default:
			return state;
	}
};

export default app;
