import { makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import React, { useEffect, useMemo, useState } from 'react';
import theme from 'resources/theme';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ContainedButton from 'modules/ContainedButton';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import Button from 'modules/Button';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
	plugins: {
		legend: {
			display: false,
		},
		datalabels: {
			display: false,
		},
	},
	maintainAspectRatio: false,
};

const useStyles = makeStyles((theme) => ({
	wrapper: { display: 'flex', flex: 1 },
	left_wrap: {
		flex: 0.4,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingTop: 0,
		paddingBottom: 20,
	},
	flex_center_between: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
	flex_center: { display: 'flex', alignItems: 'center' },
	indicator: { width: 8, height: 8, borderRadius: 4, marginRight: 4 },
	label: { fontSize: 12, color: theme.colors.darkGrey2 },
	stats_wrap: { paddingBottom: 6, borderBottom: `1px solid ${theme.colors.lightGrey5}`, marginBottom: 10 },
}));

const OrderChart = ({ chart_data, handle_all_orders }) => {
	const [plot_data, set_plot_data] = useState(null);
	const [display_data, set_display_data] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		if (chart_data) {
			const formatted_labels = _.map(chart_data.data, (item, index) => {
				return _.startCase(_.toLower(item.x));
			});
			const formatted_chart_data = _.map(chart_data.data, (item, index) => {
				return item.y.value;
			});
			const data = {
				labels: formatted_labels,
				datasets: [
					{
						data: formatted_chart_data,
						backgroundColor: [theme.colors.lightGreen4, theme.colors.lightOrange, theme.colors.lightPink],
						borderWidth: 0,
					},
				],
			};
			const read_data = [];
			for (let i = 0; i < formatted_labels.length; i++) {
				const obj = {
					label: formatted_labels[i],
					value: data.datasets[0].data[i],
					bg_color: data.datasets[0].backgroundColor[i],
				};
				read_data.push(obj);
			}
			set_display_data(read_data);
			set_plot_data(data);
		}
	}, [chart_data]);

	const sum_of_orders = useMemo(() => {
		let total = 0;
		for (let i = 0; i < display_data.length; i++) {
			const element = display_data[i];
			total += element.value;
		}
		return total;
	}, [display_data]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.left_wrap}>
				<div className={classes.stats_wrap}>
					<Text medium style={{ fontSize: 10, color: theme.colors.lightGrey2 }}>
						Total Orders
					</Text>
					<Text bold style={{ fontSize: 16, color: theme.colors.primary }}>
						{sum_of_orders}
					</Text>
				</div>
				<div>
					{_.map(display_data, (item, index) => (
						<div key={`display_data_${index}`} className={classes.flex_center_between} style={{ marginBottom: 18 }}>
							<div className={classes.flex_center}>
								<div className={classes.indicator} style={{ backgroundColor: item.bg_color }}></div>
								<Text semi className={classes.label}>
									{_.startCase(_.toLower(item.label))}
								</Text>
							</div>
							<Text bold style={{ fontSize: 14 }}>
								{item.value}
							</Text>
						</div>
					))}
				</div>
				<Button
					type='round'
					text='All Orders'
					size='extra_small'
					right_icon={ImageLinks.arrow_right_white_no_fill}
					onClick={handle_all_orders}
					style={{ width: 'fit-content' }}
				/>
			</div>
			<div style={{ flex: 0.6, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<div style={{ width: 140, height: 140 }}>{plot_data && <Pie data={plot_data} options={options} />}</div>
			</div>
		</div>
	);
};

export default OrderChart;
