import React, { useState } from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import Select from 'modules/Select';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Button from 'modules/Button';

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 18, marginBottom: 10 },
	sub_heading: { color: theme.colors.darkGrey2 },
	info_container: { width: 340, backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, height: 640 },
	update: { borderRadius: 30, fontSize: 16, marginTop: 10, width: 100 },
	info_heading: { fontSize: 18, color: theme.colors.lightPurple7 },
	list_item: { display: 'flex', marginBottom: 20 },
	list_item_text: { fontSize: 16, color: theme.colors.primary, marginBottom: 5 },
	margin: { marginLeft: 10 },
	list_item_container: { marginRight: 20, flex: '1' },
	line: { borderTop: `1px solid ${theme.colors.lightGrey2}`, marginTop: 30, marginBottom: 30 },
	dropdown_sub_heading: { fontSize: 12, color: theme.colors.darkGrey2 },
	dropdown_container: { marginBottom: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
}));

const deliverProof = [
	{
		imgURl: 'mobile_otp',
		name: 'OTP',
		text: 'Triggers a unique OTP to the sender. Highly accurate and most preferred for high value items.',
	},
	{
		imgURl: 'mobile_signature',
		name: 'Signature',
		text: 'Allows the sender/receiver to input their name, number, and signature',
	},
	{
		imgURl: 'mobile_capture',
		name: 'Image',
		text: 'Capture an image after all orders are picked up at pickup location.',
	},
	{
		imgURl: 'mobile_no_proof',
		name: 'None',
		text: 'No additional verification will be taken after orders are scanned for pickup',
	},
];

const VALUES = [
	{ title: 'OTP', value: 1 },
	{ title: 'Signature', value: 2 },
	{ title: 'None', value: 3 },
	{ title: 'Image', value: 4 },
	{ title: 'Image per cx', value: 5 },
];

const PopPod = ({
	modularity_logistics_pickup,
	modularity_reverse_logistics_pickup,
	modularity_logistics_delivery,
	modularity_reverse_logistics_delivery,
	modularity_middle_mile_pickup,
	modularity_middle_mile_delivery,
	handle_update_settings,
}) => {
	const classes = useStyles();

	const [pickup_forward_orders, set_pickup_forward_orders] = useState(modularity_logistics_pickup || null);
	const [pickup_return_orders, set_pickup_return_orders] = useState(modularity_middle_mile_pickup || null);
	const [pickup_reverse_orders, set_pickup_reverse_orders] = useState(modularity_reverse_logistics_pickup || null);

	const [delivery_forward_orders, set_delivery_forward_orders] = useState(modularity_logistics_delivery || null);
	const [delivery_return_orders, set_delivery_return_orders] = useState(modularity_middle_mile_delivery || null);
	const [delivery_reverse_orders, set_delivery_reverse_orders] = useState(modularity_reverse_logistics_delivery || null);

	return (
		<>
			<div style={{ position: 'relative' }}>
				<div className={classes.heading_container}>
					<Text bold className={classes.heading}>
						POP: Proof of Pickup
					</Text>
					<ListItem
						key={`tips`}
						button
						onClick={() => {}}
						style={{
							backgroundColor: theme.colors.lightPurple,
							marginRight: 0,
							width: 80,
							borderRadius: 5,
							display: 'flex',
							justifyContent: 'center',
							padding: '8px 8px',
						}}>
						<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
							Tips & Info
						</Text>
					</ListItem>
				</div>
				<div style={{ display: 'flex' }}>
					<div className={classes.list_item_container}>
						<Text semi className={classes.sub_heading} style={{ fontSize: 14 }}>
							Enable additional verification of picked up orders through a variety of POP options. All proof information is available against the
							order for review real-time.
						</Text>
						<div>
							<div style={{ marginTop: 30 }}>
								<div className={classes.dropdown_container}>
									<div>
										<Text semi style={{ fontSize: 16 }}>
											Forward Orders
										</Text>
										<Text className={classes.dropdown_sub_heading}>Orders moving from business to Customers</Text>
									</div>
									<Select
										value={pickup_forward_orders}
										defaultOption={{
											title: 'Select Proof Type',
											value: null,
										}}
										options={VALUES}
										handleChange={(val) => {
											set_pickup_forward_orders(val);
										}}
										containerStyles={{ height: 35, width: 160, marginLeft: 40, borderColor: theme.colors.lightPurple }}
										text_styles={{ width: 130 }}
									/>
								</div>
								<div className={classes.dropdown_container}>
									<div>
										<Text semi style={{ fontSize: 16 }}>
											Return Orders
										</Text>
										<Text className={classes.dropdown_sub_heading}>Orders moving from customer to business after a forward attempt</Text>
									</div>
									<Select
										value={pickup_return_orders}
										defaultOption={{
											title: 'Select Proof Type',
											value: null,
										}}
										options={VALUES}
										handleChange={(val) => {
											set_pickup_return_orders(val);
										}}
										containerStyles={{ height: 35, width: 160, marginLeft: 40, borderColor: theme.colors.lightPurple }}
										text_styles={{ width: 130 }}
									/>
								</div>
								<div className={classes.dropdown_container}>
									<div>
										<Text semi style={{ fontSize: 16 }}>
											Reverse Orders
										</Text>
										<Text className={classes.dropdown_sub_heading}>Orders that are moving from customer to business</Text>
									</div>
									<Select
										value={pickup_reverse_orders}
										defaultOption={{
											title: 'Select Proof Type',
											value: null,
										}}
										options={VALUES}
										handleChange={(val) => {
											set_pickup_reverse_orders(val);
										}}
										containerStyles={{ height: 35, width: 160, marginLeft: 40, borderColor: theme.colors.lightPurple }}
										text_styles={{ width: 130 }}
									/>
								</div>
							</div>
							<div className={classes.line}></div>
							<div>
								<Text bold className={classes.heading}>
									POD: Proof of Delivery
								</Text>
								<Text semi className={classes.sub_heading} style={{ fontSize: 14 }}>
									Enable additional verification of delivered orders through a variety of POD options. All proof information is available
									against the order for review real-time.
								</Text>
								<div style={{ marginTop: 30 }}>
									<div className={classes.dropdown_container}>
										<div>
											<Text semi style={{ fontSize: 16 }}>
												Forward Orders
											</Text>
											<Text className={classes.dropdown_sub_heading}>Orders moving from business to Customers</Text>
										</div>
										<Select
											value={delivery_forward_orders}
											defaultOption={{
												title: 'Select Proof Type',
												value: null,
											}}
											options={VALUES}
											handleChange={(val) => {
												set_delivery_forward_orders(val);
											}}
											containerStyles={{ height: 35, width: 160, marginLeft: 40, borderColor: theme.colors.lightPurple }}
											text_styles={{ width: 130 }}
										/>
									</div>
									<div className={classes.dropdown_container}>
										<div>
											<Text semi style={{ fontSize: 16 }}>
												Return Orders
											</Text>
											<Text className={classes.dropdown_sub_heading}>
												Orders moving from customer to business after a forward attempt
											</Text>
										</div>
										<Select
											value={delivery_return_orders}
											defaultOption={{
												title: 'Select Proof Type',
												value: null,
											}}
											options={VALUES}
											handleChange={(val) => {
												set_delivery_return_orders(val);
											}}
											containerStyles={{ height: 35, width: 160, marginLeft: 40, borderColor: theme.colors.lightPurple }}
											text_styles={{ width: 130 }}
										/>
									</div>
									<div className={classes.dropdown_container}>
										<div>
											<Text semi style={{ fontSize: 16 }}>
												Reverse Orders
											</Text>
											<Text className={classes.dropdown_sub_heading}>Orders that are moving from customer to business</Text>
										</div>
										<Select
											value={delivery_reverse_orders}
											defaultOption={{
												title: 'Select Proof Type',
												value: null,
											}}
											options={VALUES}
											handleChange={(val) => {
												set_delivery_reverse_orders(val);
											}}
											containerStyles={{ height: 35, width: 160, marginLeft: 40, borderColor: theme.colors.lightPurple }}
											text_styles={{ width: 130 }}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={classes.info_container}>
						<Text bold className={classes.info_heading}>
							Types of Proofs in Delivery
						</Text>
						<div style={{ marginTop: 20 }}>
							{_.map(deliverProof, (item, index) => {
								return (
									<div className={classes.list_item}>
										<img src={ImageLinks[item.imgURl]} height='80' width='80' />
										<div className={classes.margin}>
											<Text bold className={classes.list_item_text}>
												{item.name}
											</Text>
											<Text style={{ fontSize: 12 }}>{item.text}</Text>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
			<div style={{ position: 'absolute', bottom: 20, right: 30 }}>
				<Button
					disabled={
						pickup_forward_orders === null &&
						pickup_return_orders === null &&
						pickup_reverse_orders === null &&
						delivery_forward_orders === null &&
						delivery_return_orders === null &&
						delivery_reverse_orders === null
					}
					onClick={() => {
						handle_update_settings({
							...(pickup_forward_orders && { modularity_logistics_pickup: pickup_forward_orders }),
							...(pickup_return_orders && { modularity_middle_mile_pickup: pickup_return_orders }),
							...(pickup_reverse_orders && { modularity_reverse_logistics_pickup: pickup_reverse_orders }),
							...(delivery_forward_orders && { modularity_logistics_delivery: delivery_forward_orders }),
							...(delivery_return_orders && { modularity_middle_mile_delivery: delivery_return_orders }),
							...(delivery_reverse_orders && { modularity_reverse_logistics_delivery: delivery_reverse_orders }),
						});
					}}
					type='round'
					text='Save'
					size='large'
					style={{ width: 110 }}
				/>
			</div>
		</>
	);
};

export default PopPod;
