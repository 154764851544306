export const POWERED_WITH_PIDGE_MENU_ITEMS = {
	Overview: {
		id: 'overview',
		text: 'Overview',
	},
	ReadyToShip: {
		id: 'ready_to_ship',
		text: 'Ready to Ship',
	},
	OnGround: {
		id: 'live',
		text: 'On Ground',
	},
	AtWarehouse: {
		id: 'in_transit',
		text: 'At Warehouse',
	},
	Terminal: {
		id: 'completed',
		text: 'Completed',
	},
};

export const LOAD_ALL_STATES = {
	INITIAL: 'initial',
	STARTED: 'started',
	COMPLETED: 'completed',
};
