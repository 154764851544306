import React from 'react';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import Amount from 'modules/Amount';

const useStyles = makeStyles((theme) => ({
	wrapper: { flex: 1, alignItems: 'center', display: 'flex', flexDirection: 'column' },
	flex_center: { display: 'flex', alignItems: 'center' },
	indicator: {
		width: 8,
		height: 8,
		borderRadius: 4,
		marginRight: 4,
	},
}));

const PerformanceTab = ({ item }) => {
	const classes = useStyles();
	return (
		<div className={classes.wrapper}>
			{/* 
			Will be added later
			<Amount
				amount={item.amount}
				main_text_styles={{ fontSize: 14, color: theme.colors.black }}
				sub_text_styles={{ fontSize: 14, color: theme.colors.black }}
			/> */}
			<div className={classes.flex_center} style={{ marginTop: 2 }}>
				<div
					className={classes.indicator}
					style={{
						backgroundColor: item.item_color,
					}}></div>
				<div
					style={{
						width: 40,
						height: 6,
						borderRadius: 1,
						backgroundColor: '#D9D9D9',
					}}></div>
				{/* 
				Will be added later
				<Text semi style={{ fontSize: 10, color: theme.colors.darkGrey2 }}>
					{item.title}
				</Text> */}
			</div>
		</div>
	);
};

export default PerformanceTab;
