import _ from 'lodash';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import utils from 'resources/utils';

const Amount = ({ amount, main_text_styles = {}, sub_text_styles = {}, main_text_props = {}, sub_text_props = {} }) => {
	let _amount;
	let amount_split = _.split(_amount, '.');

	const is_amount_exist = amount !== null && amount !== undefined && amount !== '';

	if (is_amount_exist) {
		_amount = utils.format_currency(Math.round(amount * 100, 2) / 100);
		amount_split = _.split(_amount, '.');
	}

	return (
		<Text bold style={{ color: theme.colors.white, fontSize: 32, ...main_text_styles }} {...main_text_props}>
			{is_amount_exist ? (
				<>
					{amount_split[0]}
					{amount_split[1] && (
						<Text semi component={'span'} style={{ color: theme.colors.white, fontSize: 12, ...sub_text_styles }} {...sub_text_props}>
							.{amount_split[1]}
						</Text>
					)}
				</>
			) : (
				'-'
			)}
		</Text>
	);
};

export default Amount;
