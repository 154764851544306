import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';
import Text from 'modules/Text';
import theme from 'resources/theme';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { hide_loader, show_loader } from 'actions/app';

const useStyles = makeStyles((theme) => ({
	detailed_info_container: {
		minWidth: 160,
		height: 198,
		overflow: 'auto',
		backgroundColor: theme.colors.white,
		position: 'absolute',
		bottom: 175,
		right: 35,
		borderRadius: 6,
		zIndex: 999,
		border: `1px solid ${theme.colors.lightPurple8}`,

		boxShadow: `0px 4px 4px 0px rgba(202, 202, 202, 0.25)`,
		'&::-webkit-scrollbar': {
			width: '0.4em',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.colors.lightPurple8,
		},
	},

	process_item_wrap: {
		minHeight: 33,
		paddingTop: 8,
		paddingBottom: 8,
		paddingLeft: 12,
		paddingRight: 12,
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
	},
	process_item_time: { fontSize: 12, color: theme.colors.lightGrey6, marginTop: 4 },
}));
const CurrentAndZoneSelectModal = ({ outside_click, handle_zone_change, set_show_toast }) => {
	const dispatch = useDispatch();
	const { zones } = useSelector(
		(state) => ({
			zones: state.app.zones,
		}),
		shallowEqual,
	);

	const classes = useStyles();
	const component_ref = useRef(null);

	useEffect(() => {
		const handle_click_outside = (event) => {
			if (component_ref.current && !component_ref.current.contains(event.target)) {
				outside_click && outside_click();
			}
		};
		document.addEventListener('click', handle_click_outside, true);
		return () => {
			document.removeEventListener('click', handle_click_outside, true);
		};
	}, []);

	const handle_item_click = (item) => {
		if (item.name === 'custom') {
			if (navigator.geolocation) {
				dispatch(show_loader());
				navigator.geolocation.getCurrentPosition(show_position, show_error);
			} else {
				// x.innerHTML = 'Geolocation is not supported by this browser.';
			}
			outside_click();
			return;
		}
		handle_zone_change(false, item.id);
		outside_click();
	};

	const show_position = (position) => {
		dispatch(hide_loader());
		const CenterPoint = {
			latitude: position.coords.latitude,
			longitude: position.coords.longitude,
		};
		handle_zone_change(true, CenterPoint);
	};

	const show_error = (error) => {
		dispatch(hide_loader());
		set_show_toast(true);
		switch (error.code) {
			case error.PERMISSION_DENIED:
				// x.innerHTML = "User denied the request for Geolocation."
				break;
			case error.POSITION_UNAVAILABLE:
				// x.innerHTML = "Location information is unavailable."
				break;
			case error.TIMEOUT:
				// x.innerHTML = "The request to get user location timed out."
				break;
			case error.UNKNOWN_ERROR:
				// x.innerHTML = "An unknown error occurred."
				break;
		}
	};

	return (
		<div ref={component_ref} style={{ position: 'relative' }}>
			<div className={clsx(classes.detailed_info_container)}>
				<div
					onClick={() => handle_item_click({ name: 'custom' })}
					className={classes.process_item_wrap}
					style={{ backgroundColor: theme.colors.lightPink4, borderBottom: `1px solid ${theme.colors.lightPurple6}` }}>
					<Text medium style={{ fontSize: 14 }}>
						Current Location
					</Text>
				</div>
				{_.map(zones, (item, index) => (
					<div onClick={() => handle_item_click(item)} className={classes.process_item_wrap} key={`zone_item_${index}`}>
						<Text medium style={{ fontSize: 14 }}>
							{item.name}
						</Text>
					</div>
				))}
			</div>
		</div>
	);
};

export default CurrentAndZoneSelectModal;
