import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	wrapper: { height: '100%', position: 'relative', display: 'flex', flexDirection: 'column' },
	header: { display: 'flex', justifyContent: 'space-between', height: 40, alignItems: 'center' },
	all_riders_text: {
		fontSize: 22,
		color: theme.colors.lightGrey2,
		cursor: 'pointer',
	},
	screen_text_wrap: { width: 1, height: 16, background: theme.colors.darkGrey2, marginLeft: 20, marginTop: 7 },
	rider_name_text: {
		fontSize: 22,
		marginLeft: 20,
		color: theme.colors.black,
	},
	content: { display: 'flex', flex: 1, overflow: 'hidden', marginBottom: 30, marginTop: 32 },
	left_wrap: { flex: 1, overflowY: 'auto', marginRight: 10 },
	right_wrap: { flex: 1, overflowY: 'auto', marginLeft: 10 },
	flex_center: { display: 'flex', alignItems: 'center' },
	box_wrap: { display: 'flex', marginTop: 10, marginBottom: 18 },
	box_item_wrap: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.colors.white,
		height: 42,
		paddingLeft: 12,
		paddingRight: 12,
		borderRadius: 6,
	},
	reserved_grp_count_wrap: {
		width: 44,
		height: 22,
		backgroundColor: theme.colors.lightPurple,
		borderRadius: 50,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '2px 8px 2px 8px',
	},
	performance_box: {
		minHeight: 210,
		backgroundColor: theme.colors.white,
		borderRadius: 15,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		display: 'flex',
		margin: '4px 4px 0px 4px',
	},
	performance_tab_wrap: { flex: 0.3, boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)', padding: 10 },
	tab_item_wrap: {
		height: 42,
		borderRadius: 6,
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		padding: 12,
		marginBottom: 8,
		justifyContent: 'center',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
	},
	payout_wrap: {
		height: 215,
		backgroundColor: theme.colors.white,
		borderRadius: 16,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		marginTop: 20,
		padding: 20,
		margin: '4px 4px 0px 4px',
	},
	section_header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
	detail_text: { fontSize: 16, color: theme.colors.primary, marginRight: 2, textDecoration: 'underline' },
	payout_separator: { border: `1px solid ${theme.colors.lightGrey5}`, marginTop: 11, marginBottom: 11 },
	payout_left_wrap: {
		display: 'flex',
		flexDirection: 'column',
		flex: 0.7,
		justifyContent: 'space-between',
		height: '100%',
	},
	roster_wrap: {
		backgroundColor: theme.colors.white,
		borderRadius: 15,
		padding: '20px 12px 20px 20px',
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		margin: '4px 4px 0px 4px',
		overflow: 'hidden',
	},
	roster_header_time_text: { fontSize: 16, color: theme.colors.primary, marginLeft: 4, marginRight: 4 },
	roster_nav_btns: {
		width: 18,
		height: 18,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		borderRadius: 9,
		backgroundColor: theme.colors.lightPurple,
	},
	roster_border_line: {
		borderLeft: `1.5px dashed ${theme.colors.lightGrey5}`,
		position: 'absolute',
		top: 6,
		minHeight: 315,
	},
	shift_item_text: { fontSize: 12, color: theme.colors.lightPurple7 },
	roster_header_separator: { border: `0.5px solid ${theme.colors.lightGrey5}`, marginTop: 13, marginBottom: 13 },
	performance_header_separator: { border: `0.5px solid ${theme.colors.lightGrey5}`, marginTop: 11, marginBottom: 16 },
	graphs_wrap: { display: 'flex', flex: 0.7, padding: 10 },
	graph_date_filter_text: { fontSize: 16, color: theme.colors.primary, marginLeft: 4 },
	performance_wrap: {
		marginTop: 18,
	},
	back_arrow: { backgroundColor: theme.colors.lightPurple, width: 20, height: 20, borderColor: theme.colors.lightPurple, marginRight: 8 },
}));

export default useStyles;
