import React, { useState } from 'react';
import moment from 'moment';
import Text from 'modules/Text';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import _ from 'lodash';
import BootstrapTooltip from 'modules/BootstrapTooltip';
import RosterBar from 'views/Roster/components/RosterBar';

const useStyles = makeStyles((theme) => ({
	roster_row_item_wrap: {
		display: 'flex',
		flex: 1,
		paddingTop: 12,
		justifyContent: 'space-between',
		height: 34,
	},
	shift_item_text: { fontSize: 12, color: theme.colors.primary, textAlign: 'center' },
	shift_time_wrap: {
		position: 'relative',
		'&:hover': {
			'& $roster_set_edit_btn': {
				visibility: 'visible !important',
			},
		},
	},
	icon_wrap: {
		width: 16,
		height: 16,
		background: theme.colors.primary,
		borderRadius: 8,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 4,
	},
	roster_set_edit_btn: {
		width: 96,
		height: 24,
		background: theme.colors.lightPurple,
		borderRadius: 30,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		bottom: 0,
		right: 16,
		top: -2,
		cursor: 'pointer',
		visibility: 'hidden',
		zIndex: 1,
	},
	bar_item_wrap: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		height: 8,
		left: '6.8%',
		width: '94%',
	},
	bar_item: {
		height: 8,
		borderRadius: 4,
		position: 'absolute',
	},
}));

const AttendenceItem = ({ item, handle_edit_or_create_roster, day_start_time, leave_details }) => {
	const classes = useStyles();
	const roster_date = moment(item.roster_date);
	const is_same_date = roster_date.isSame(moment(), 'day');
	const is_before_date = roster_date.isBefore(moment(), 'day');
	const is_after_date = roster_date.isAfter(moment(), 'day');

	let leave_data = [];
	_.map(Object.keys(leave_details), (val) => {
		if (val == moment(roster_date).format('YYYY-MM-DD')) {
			leave_data.push(leave_details[val]);
		}
	});

	return (
		<>
			<div className={classes.roster_row_item_wrap} style={{ overflow: 'hidden' }}>
				<Text
					semi={!is_same_date}
					bold={is_same_date}
					style={{
						zIndex: 1,
						fontSize: 12,
						color: is_same_date ? theme.colors.primary : theme.colors.darkGrey2,
					}}>
					{`${moment(item.roster_date).format('ddd, DD MMM')} ${is_same_date ? ' | Today' : ''}`}
				</Text>
				<div className={classes.shift_time_wrap}>
					<Text semi className={classes.shift_item_text} style={!is_same_date ? { color: theme.colors.darkGrey2 } : {}}>
						{item.shift_id ? (
							!_.isEmpty(leave_data) ? (
								'Day Off'
							) : item.entries && item.entries.length > 1 ? (
								<>
									{moment(item.time_slot_from_real).format('h:mm A')} -{moment(item.time_slot_to_real).format('h:mm A')} {''}
									<BootstrapTooltip
										title={
											<div style={{ display: 'flex', gap: 5, flexDirection: 'column', padding: 4 }}>
												{_.map(item.entries, (val, index) => {
													if (index !== 0) {
														return (
															<Text semi style={{ fontSize: 12, color: theme.colors.darkGrey7 }}>
																{moment(val.time_slot_from_real).format('h:mm A')} -{' '}
																{moment(val.time_slot_to_real).format('h:mm A')}
															</Text>
														);
													}
												})}
											</div>
										}
										placement='left'
										is_white={true}>
										<span
											style={{ fontFamily: 'HK Grotesk SemiBold', color: !is_same_date ? theme.colors.darkGrey2 : '', cursor: 'pointer' }}
											className={classes.shift_item_text}>
											| +{item.entries.length - 1}
										</span>
									</BootstrapTooltip>
								</>
							) : (
								`${moment(item.time_slot_from_real).format('h:mm A')} - ${moment(item.time_slot_to_real).format('h:mm A')}`
							)
						) : !_.isEmpty(leave_data) ? (
							'Day Off'
						) : (
							'Not Set'
						)}
					</Text>
					{(is_after_date || (is_same_date && !item.shift_id)) && (
						<div onClick={handle_edit_or_create_roster} className={classes.roster_set_edit_btn}>
							<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
								{item.shift_id ? 'Edit Roster' : 'Set Roster'}
							</Text>
							<div className={classes.icon_wrap}>
								<img width={8} height={8} src={item.shift_id ? ImageLinks.edit_white : ImageLinks.addWhite} />
							</div>
						</div>
					)}
				</div>
			</div>
			<div className={classes.bar_item_wrap}>
				{_.map(item.entries, (val, key) => {
					return (
						<RosterBar
							r={val}
							days_diff={is_same_date ? 0 : is_after_date ? 1 : is_before_date ? -1 : 0}
							day_start_time={day_start_time}
							total_minutes={720}
							date={roster_date}
							index={key}
							current_time={moment().format()}
							show_hover_text={false}
						/>
					);
				})}

				{!_.isEmpty(leave_data) && (
					<div
						className={classes.bar_item}
						style={{
							backgroundColor: theme.colors.lightRed,
							width: '100%',
							left: '0%',
							borderRadius: 4,
						}}></div>
				)}
			</div>
		</>
	);
};

export default AttendenceItem;
