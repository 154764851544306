import { makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import React from 'react';
import theme from 'resources/theme';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const data = {
	labels: ['Order', 'Time', 'Distance', 'Salary', 'Min. G'],
	datasets: [
		{
			data: [5000, 2000, 2000, 5000, 1000],
			backgroundColor: [
				theme.colors.lightPurple7,
				theme.colors.lightPurple6,
				theme.colors.lightPurple4,
				theme.colors.lightYellow,
				theme.colors.lightGreen4,
			],
			borderWidth: 1,
		},
	],
};

const options = {
	// this hove option will be removed later
	hover: { mode: null },
	elements: {
		arc: {
			borderJoinStyle: 'bevel',
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			enabled: false,
		},
		datalabels: {
			display: false,
		},
		//will be added later
		// datalabels: {
		// 	formatter: (value, ctx) => {
		// 		let sum = 0;
		// 		let dataArr = ctx.chart.data.datasets[0].data;
		// 		dataArr.map((data) => {
		// 			sum += data;
		// 		});
		// 		let percentage = ((value * 100) / sum).toFixed(2) + '%';
		// 		return percentage;
		// 	},
		// 	color: theme.colors.black,
		// 	font: {
		// 		size: 7,
		// 		family: 'HK Grotesk Bold',
		// 	},
		// },
	},
	maintainAspectRatio: false,
};

const useStyles = makeStyles((theme) => ({
	wrapper: { display: 'flex', flex: 1, justifyContent: 'center' },
}));

const PayoutChart = () => {
	const classes = useStyles();
	return (
		<div className={classes.wrapper}>
			<div style={{ width: 140, height: 140 }}>
				<Doughnut data={data} options={options} />
			</div>
		</div>
	);
};

export default PayoutChart;
