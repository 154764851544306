import { CircularProgress, makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';

const useStyles = makeStyles(() => ({
	circle: {
		padding: 10,
		borderRadius: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const ContainedButton = ({ children, circle, onClick = () => {}, loader_size = 20, loader_color = 'white', style = {}, show_loader, className, ...rest }) => {
	const classes = useStyles();

	const [loader, set_loader] = React.useState(false);

	const handleClick = async () => {
		try {
			if (show_loader) {
				set_loader(true);
			}

			await onClick();

			if (show_loader) {
				set_loader(false);
			}
			return Promise.resolve();
		} catch (err) {
			set_loader(false);
			return Promise.reject(err);
		}
	};

	return (
		<Button
			variant='contained'
			color='primary'
			style={{
				textTransform: 'initial',
				letterSpacing: 'unset',
				minWidth: 0,
				...style,
			}}
			className={clsx(circle && classes.circle, className)}
			onClick={handleClick}
			{...rest}>
			{loader ? <CircularProgress size={loader_size} style={{ color: loader_color }} /> : children}
		</Button>
	);
};

export default ContainedButton;
