import loadjs from 'loadjs';
import _ from 'lodash';

class RazorpayUtils {
	success_callback = null;
	error_callback = null;

	initiate_txn(razorpay_init_config) {
		this.success_callback = razorpay_init_config.success_callback;
		this.error_callback = razorpay_init_config.error_callback;
		razorpay_init_config.options.handler = (response) => {
			this.handle_transaction_success(response);
		};
		if (window.Razorpay) {
			this.on_script_loaded(razorpay_init_config.options);
			return;
		}
		const script = 'https://checkout.razorpay.com/v1/checkout.js';
		loadjs(script, { returnPromise: true })
			.then(() => {
				this.on_script_loaded(razorpay_init_config.options);
			})
			.catch((pathsNotFound) => {
				this.handle_transaction_failure('Some Error Occurred. Please Retry...');
			});
	}

	on_script_loaded(config) {
		const rzp1 = new window.Razorpay(config);
		rzp1.open();
		rzp1.on('payment.failed', (response) => {
			let errMsg = 'Some Error Occurred. Please Retry...';
			this.handle_transaction_failure(_.get(response, 'error.description'), errMsg);
		});
	}

	async handle_transaction_success(data) {
		this.success_callback(data);
		this.deallocate_data();
	}

	handle_transaction_failure(data) {
		this.error_callback(data);
		this.deallocate_data();
	}

	deallocate_data() {
		this.success_callback = null;
		this.error_callback = null;
	}
}

export default new RazorpayUtils();
