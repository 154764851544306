import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		top: '30px',
		width: '50%',
		left: '25%',
		margin: 'auto',
		zIndex: '2',
		borderRadius: 4,
	},
	inner_wrapper: {
		alignItems: 'center',
		padding: '7px 12px',
		borderRadius: '4px',
		border: `1px solid ${theme.colors.lightGrey5}`,
	},
}));

function Toast({ children, error, close_callback = () => {}, wrapper_styles = {} }) {
	const [show, set_show] = useState(true);
	const classes = useStyles();
	const theme = useTheme();

	useEffect(() => {
		setTimeout(() => {
			set_show(false);
			close_callback();
		}, 5000);
	}, []);

	return (
		<div className={classes.container} style={wrapper_styles}>
			<div
				className={clsx(classes.inner_wrapper, 'box-shadow')}
				style={{
					display: show ? 'flex' : 'none',
					backgroundColor: error ? theme.colors.lightRed : theme.colors.white,
				}}>
				{children}
			</div>
		</div>
	);
}

export default Toast;
