import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import disabled_expand_more from 'assets/images/icons/disabled_expand_more.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		'&.MuiAccordion-root:before': {
			height: 0,
		},
	},
	summary_root: {
		'&.MuiAccordionSummary-root': {
			padding: 0,
			minHeight: 'auto',
		},
		'& .MuiAccordionSummary-content': {
			margin: 0,
		},
	},
	expand_icon_root: {
		'&.MuiIconButton-root': {
			paddingRight: 10,
		},
	},
	details_root: {
		'&.MuiAccordionDetails-root': {
			padding: 0,
		},
	},
}));

const CustomAccordion = (props) => {
	const {
		header,
		content,
		style = {},
		classes_override = {},
		header_container_style = {},
		content_container_style = {},
		header_click = () => {},
		header_icon_style = {},
		...rest
	} = props;
	const classes = useStyles();

	return (
		<Accordion
			style={style}
			elevation={0}
			classes={{
				root: classes.root,
			}}
			{...rest}>
			<AccordionSummary
				onClick={header_click}
				classes={{
					root: clsx(classes.summary_root, classes_override.summary_root),
					expandIcon: clsx(classes.expand_icon_root, classes_override?.expand_icon_root),
				}}
				style={header_container_style}
				expandIcon={<img src={disabled_expand_more} style={header_icon_style} alt='expand more icon' />}>
				{header}
			</AccordionSummary>
			<AccordionDetails classes={{ root: clsx(classes.details_root, classes_override.details_root) }} style={content_container_style}>
				{content}
			</AccordionDetails>
		</Accordion>
	);
};

export default CustomAccordion;
