import React from 'react';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';

const useStyles = makeStyles((theme) => ({
	tab_item_wrap: {
		height: 42,
		borderRadius: 6,
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		padding: 12,
		marginBottom: 8,
		justifyContent: 'center',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
	},
}));

const PerformanceTab = ({ item, is_selected, on_tab_click }) => {
	const classes = useStyles();
	return (
		<div
			onClick={on_tab_click}
			className={classes.tab_item_wrap}
			style={{
				backgroundColor: is_selected ? theme.colors.lightPurple : theme.colors.white,
				border: `1px solid ${is_selected ? theme.colors.primary : theme.colors.lightGrey7}`,
			}}>
			<Text semi style={{ fontSize: 14, color: is_selected ? theme.colors.primary : theme.colors.black }}>
				{item}
			</Text>
		</div>
	);
};

export default PerformanceTab;
