import React from 'react';
import { makeStyles, Switch as MUISwitch } from '@material-ui/core';
import theme from 'resources/theme';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiSwitch-switchBase': {
			'& .MuiSwitch-thumb': {
				color: theme.colors.white,
				backgroundColor: theme.colors.lightPurple,
				border: `0.6px solid  ${theme.colors.lightPurple6}`,
				height: 18,
				width: 18,
			},
			'&:hover': {
				background: 'none',
			},
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			color: theme.colors.white,
			'& .MuiSwitch-thumb': {
				color: theme.colors.white,
				border: `1px solid ${theme.colors.primary}`,
				backgroundColor: theme.colors.white,
			},
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		'& .MuiSwitch-switchBase.Mui-disabled': {
			color: theme.colors.white,
			'& .MuiSwitch-thumb': {
				color: theme.colors.darkGrey,
				border: `1px solid ${theme.colors.darkGrey} !important`,
				backgroundColor: theme.colors.white,
			},
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		'& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
			backgroundColor: theme.colors.darkGrey + ' !important',
			borderColor: `transparent`,
			opacity: 1,
		},
		'& .MuiSwitch-switchBase + .MuiSwitch-track': {
			backgroundColor: theme.colors.white,
			border: `0.6px solid  ${theme.colors.lightPurple6}`,
			opacity: 1,
			height: 12,
		},
		'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
			backgroundColor: theme.colors.primary,
			borderColor: `transparent`,
			opacity: 1,
		},
	},
	root_green: {
		'& .MuiSwitch-switchBase': {
			'& .MuiSwitch-thumb': {
				color: theme.colors.white,
				backgroundColor: theme.colors.lightPurple,
				border: `0.6px solid  ${theme.colors.lightPurple6}`,
				height: 18,
				width: 18,
			},
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			color: theme.colors.white,
			'& .MuiSwitch-thumb': {
				color: theme.colors.white,
				border: `1px solid ${theme.colors.green}`,
				backgroundColor: theme.colors.white,
			},
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		'& .MuiSwitch-switchBase + .MuiSwitch-track': {
			backgroundColor: theme.colors.white,
			border: `0.6px solid  ${theme.colors.lightPurple6}`,
			opacity: 1,
			height: 12,
		},
		'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
			backgroundColor: theme.colors.green,
			borderColor: `transparent`,
			opacity: 1,
		},
	},
	root_red: {
		'& .MuiSwitch-switchBase': {
			'& .MuiSwitch-thumb': {
				color: theme.colors.white,
				backgroundColor: theme.colors.lightPurple,
				border: `0.6px solid  ${theme.colors.lightPurple6}`,
				height: 18,
				width: 18,
			},
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			color: theme.colors.white,
			'& .MuiSwitch-thumb': {
				color: theme.colors.white,
				border: `1px solid ${theme.colors.red}`,
				backgroundColor: theme.colors.white,
			},
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		'& .MuiSwitch-switchBase + .MuiSwitch-track': {
			backgroundColor: theme.colors.white,
			border: `0.6px solid  ${theme.colors.lightPurple6}`,
			opacity: 1,
			height: 12,
		},
		'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
			backgroundColor: theme.colors.red,
			borderColor: `transparent`,
			opacity: 1,
		},
	},
	root_yellow: {
		'& .MuiSwitch-switchBase': {
			'& .MuiSwitch-thumb': {
				color: theme.colors.darkYellow,
				backgroundColor: theme.colors.white,
				border: `0.6px solid  ${theme.colors.darkYellow}`,
				height: 18,
				width: 18,
			},
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			color: theme.colors.white,
			'& .MuiSwitch-thumb': {
				color: theme.colors.darkYellow,
				border: `1px solid ${theme.colors.darkYellow}`,
				backgroundColor: theme.colors.darkYellow,
			},
		},
		'& .MuiSwitch-switchBase + .MuiSwitch-track': {
			backgroundColor: theme.colors.darkYellow,
			border: `0.6px solid  ${theme.colors.darkYellow}`,
			opacity: 1,
			height: 12,
		},
		'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
			backgroundColor: theme.colors.darkYellow,
			borderColor: `transparent`,
			opacity: 1,
		},
	},
}));

const Switch = (props) => {
	const classes = useStyles();

	return (
		<MUISwitch
			className={props?.green ? classes.root_green : props?.red ? classes.root_red : props?.yellow ? classes.root_yellow : classes.root}
			{...props}
		/>
	);
};

export default Switch;
