import { TextField } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';

const UndlerlineTextField = ({ style = {}, textStyle = {}, type, onChange, labelStyle, ...rest }) => {
	let inputType = type || 'text';

	if (inputType === 'number') {
		inputType = 'text';
	}

	const handleChange = (e) => {
		const value = e.target.value;
		if (type === 'number') {
			const pattern = /^[0-9]*(\.[0-9]{0,10})?$/;
			if (value && !pattern.test(value)) {
				return;
			}
		}
		onChange(e);
	};

	return (
		<TextField
			variant='standard'
			style={{ ...style }}
			inputProps={{ style: { ...textStyle } }}
			InputLabelProps={{
				style: {
					...(textStyle.fontSize && { fontSize: textStyle.fontSize }),
					...labelStyle,
				},
			}}
			onChange={handleChange}
			type={inputType}
			{...rest}
		/>
	);
};

export default UndlerlineTextField;
