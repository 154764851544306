import history from 'browserHistory';
import _ from 'lodash';
import api_requests from 'resources/api_requests';
import constant from 'resources/constant';
import utils from 'resources/utils';
import { TOGGLE_NOTIF_MODAL, SUPPORT_MODAL_TOGGLE } from './actionConstants';

export const toggle_support_modal = (support_data) => ({
	type: SUPPORT_MODAL_TOGGLE,
	support_data,
});

export const toggle_notif_modal = (data) => ({
	type: TOGGLE_NOTIF_MODAL,
	data,
});
