import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import _ from 'lodash';
import Button from 'modules/Button';

const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		width: 550,
		backgroundColor: theme.palette.background.paper,
		padding: 20,
		borderRadius: 20,
	},
	start_chat_button_container: {
		marginTop: 36,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 20,
	},
	logo_wrap: { position: 'absolute', left: '32%', top: '-32%', zIndex: 100 },
	cc_text: { fontSize: 18, color: theme.colors.lightGrey, textAlign: 'center', paddingTop: 65 },
	value_text: { fontSize: 36, color: theme.colors.primary, textAlign: 'center', marginTop: 23 },
}));

const CouponCode = ({ coupon, close }) => {
	const classes = useStyles();

	return (
		<Modal open={true} onClose={close} style={{ zIndex: 1501 }}>
			<div
				className={classes.paper}
				style={{
					top: '42%',
					left: '40%',
					transform: 'translate(-20%, -30%)',
					backgroundImage: `url(${ImageLinks.sun_ray})`,
				}}>
				<div className={classes.logo_wrap}>
					<img src={ImageLinks.nrai} width={212} height={212} />
				</div>
				<div className='justify-content-between d-flex'>
					<div>
						<Text bold style={{ fontSize: 20 }}></Text>
					</div>
					<div>
						<img src={ImageLinks.crossBlack} alt='cross' className='cursor-pointer' onClick={() => close()} />
					</div>
				</div>
				<div>
					<Text semi className={classes.cc_text}>
						{`'${coupon.coupon_code}' applied`}
					</Text>
					<Text semi className={classes.value_text}>
						₹{`${coupon.value} `}
						<Text component={'span'} semi style={{ fontSize: 34, color: theme.colors.black }}>
							credit in your wallet with this coupon
						</Text>
					</Text>
				</div>

				<div className={classes.start_chat_button_container}>
					<Button type='round' text='Yay!' size='large' onClick={close} style={{ width: 'fit-content' }} />
				</div>
			</div>
		</Modal>
	);
};

export default CouponCode;
