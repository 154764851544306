import loadjs from 'loadjs';
import _ from 'lodash';
import constant from './constant';

class PaytmUtils {
	success_callback = null;
	error_callback = null;
	updates_callback = null;

	initiate_txn(paytm_init_config) {
		this.success_callback = paytm_init_config.success_callback;
		this.error_callback = paytm_init_config.error_callback;
		this.updates_callback = paytm_init_config.paytm_updates;

		const handler = {
			transactionStatus: (paymentStatus) => {
				if (paymentStatus?.STATUS === 'TXN_SUCCESS') {
					window.Paytm.CheckoutJS.close();
					this.handle_transaction_success(paymentStatus);
				}
				if (paymentStatus?.STATUS === 'PENDING') {
					window.Paytm.CheckoutJS.close();
					let errMsg = 'Some Error Occurred. Please Retry...';
					this.handle_transaction_failure(_.get(paymentStatus, 'RESPMSG'), errMsg);
				}
			},
			notifyMerchant: (eventName, data) => {
				if (_.get(data, 'message')) this.updates_callback(data.message);
			},
		};
		paytm_init_config.config.handler = handler;

		if (window.Paytm && window.Paytm.CheckoutJS) {
			this.on_script_loaded(paytm_init_config.config);
			return;
		}
		const script = `${constant.PAYTM_BASE_URL}/merchantpgpui/checkoutjs/merchants/${paytm_init_config.mid}.js`;
		loadjs(script, { returnPromise: true })
			.then(() => {
				window.Paytm.CheckoutJS.onLoad(() => {
					this.on_script_loaded(paytm_init_config.config);
				});
			})
			.catch((pathsNotFound) => {
				this.handle_transaction_failure('Some Error Occurred. Please Retry...');
			});
	}

	on_script_loaded(config) {
		window.Paytm.CheckoutJS.init(config)
			.then(() => {
				window.Paytm.CheckoutJS.invoke();
			})
			.catch((error) => {
				this.handle_transaction_failure(error);
			});
	}

	async handle_transaction_success(data) {
		this.success_callback(data);
		this.deallocate_data();
	}

	handle_transaction_failure(data) {
		this.error_callback(data);
		this.deallocate_data();
	}

	deallocate_data() {
		this.success_callback = null;
		this.error_callback = null;
		this.updates_callback = null;
	}
}

export default new PaytmUtils();
