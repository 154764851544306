import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import api_requests from 'resources/api_requests';
import { useDispatch } from 'react-redux';
import { hide_loader, show_loader } from 'actions/app';
import Footer from './Footer';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	title_text: { fontSize: 40 },
	content_wrap: { flex: 0.9, display: 'flex', flexDirection: 'column', justifyContent: 'center' },
	form_style: {
		width: '50%',
		height: '100%',
	},

	subtitle_text: { color: theme.colors.primary, fontSize: 20, marginTop: 30, marginBottom: 16 },
	accept_btn: {
		width: 110,
		height: 28,
		borderRadius: 100,
		padding: '6px 8px',
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.green}`,
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: `${theme.colors.green} !important`,
			'& .green-tick': {
				display: 'none',
			},
			'& .white-tick': {
				display: 'block !important',
			},
			'& $accept_btn_text': {
				color: `${theme.colors.white}`,
			},
		},
	},
	accept_btn_text: {
		fontSize: 12,
		color: theme.colors.green,
		marginLeft: 4,
	},
	invite_info_wrap: {
		height: 75,
		width: 454,
		borderRadius: 4,
		backgroundColor: theme.colors.lightPurple,
		padding: 20,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: 27,
	},
	deleted_invite: {
		animation: '$myEffect 1000ms forwards',
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 1,
			transform: 'translateX(0%)',
		},
		'100%': {
			zindex: -100,
			opacity: 0,
			transform: 'translateX(100%)',
		},
	},
}));

const PendingInvites = ({ login_details, pending_invites, on_accept_all_invites }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [invites_list, set_invites_list] = useState(pending_invites);
	const [deleted_invite_id, set_deleted_invite_id] = useState(null);

	useEffect(() => {
		set_invites_list(pending_invites);
	}, [pending_invites]);

	const accept_invite = async (invite) => {
		set_deleted_invite_id(invite.id);
		try {
			dispatch(show_loader());
			const response = await api_requests.accept_user_invite(invite.id, login_details.token);
			if (response.success) {
				dispatch(hide_loader());
				const remaining_invites = _.filter(invites_list, (item) => item.id != invite.id);
				set_invites_list(remaining_invites);
				if (remaining_invites.length === 0) on_accept_all_invites();
			}
		} catch (error) {
			dispatch(hide_loader());
		}
	};
	return (
		<div className={classes.form_style}>
			<div style={{ paddingLeft: '15%', paddingTop: '15%' }}>
				<div className={classes.content_wrap}>
					<Text medium className={classes.title_text}>
						You’ve been invited!
					</Text>
					<Text semi style={{ fontSize: 18, color: theme.colors.primary, marginTop: 10 }}>
						Accept invitation to access the dashboard
					</Text>
					<div style={{ marginTop: 20, maxHeight: 380, overflowY: 'auto' }}>
						{_.map(invites_list, (invite, key) => (
							<div key={`invite_${key}`} className={clsx(deleted_invite_id === invite.id && classes.deleted_invite)}>
								<Text bold className={classes.subtitle_text}>
									{`${invite.brand_name} `}
									<Text component={'span'} medium style={{ fontSize: 20, color: theme.colors.black }}>
										invited you to join their team.
									</Text>
								</Text>
								<div className={classes.invite_info_wrap}>
									<div>
										<Text medium style={{ fontSize: 16 }}>
											{`${_.get(login_details, 'info.first_name', '')} ${_.get(login_details, 'info.last_name', '')} (${_.get(
												login_details,
												'info.id',
												'',
											)})`}
										</Text>
										<Text medium style={{ fontSize: 12, color: theme.colors.lightGrey6 }}>
											{`${_.get(login_details, 'info.meta_info.email', '')}| ${_.get(login_details, 'info.mobile', '')}`}
										</Text>
									</div>

									<div onClick={() => accept_invite(invite)} className={classes.accept_btn}>
										<img className='green-tick' width={12} height={12} src={ImageLinks.tick_circle_green} />
										<img className='white-tick' style={{ display: 'none' }} width={12} height={12} src={ImageLinks.tick_circle_white} />
										<Text semi className={classes.accept_btn_text}>
											Accept Invite
										</Text>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>

				<Footer show_help />
			</div>
		</div>
	);
};

export default PendingInvites;
