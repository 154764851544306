import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';

export const useStyles = makeStyles(() => ({
	wrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		background: '#666666BF',
		zIndex: 1199,
	},
	date_arrow: {
		height: 12,
		width: 12,
		background: theme.colors.lightPurple,
		'&:hover': {
			backgroundColor: theme.colors.primary,
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	date_text: { color: theme.colors.primary, fontSize: 12, margin: '0 5px' },
	sub_wrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		height: '100vh',
		overflow: 'scroll',
		width: 600,
		backgroundColor: 'white',
		borderTopLeftRadius: 20,
		padding: 30,
		zIndex: 1200,
		overflowY: 'auto',
	},
	header: { borderBottom: '1px solid lightgrey', paddingBottom: 10, display: 'flex', justifyContent: 'space-between' },
	header_text_wrap: { display: 'flex', alignItems: 'center' },
	header_text: { fontSize: 20, color: theme.colors.primary },
	textDate: {
		marginTop: 8,
		fontSize: 16,
		background: 'white',
		borderRadius: 20,
		height: 28,
		width: 28,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	input_row: { display: 'flex', justifyContent: 'space-between', marginTop: 10 },
	shift_wrap: { display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 20 },
	shift_sub_wrap: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' },
	shift_input: {
		fontFamily: 'HK Grotesk Regular',
		outline: 'none',
		padding: 5,
		borderRadius: 20,
		marginTop: 10,
		borderColor: theme.colors.primary,
		borderWidth: 1,
	},
	date_label: { fontSize: 16, color: theme.colors.darkGrey2, marginBottom: 10 },
	date_input_wrap: { display: 'flex', alignItems: 'center', height: 70 },
	date_list_item: {
		borderRadius: 50,
		width: 40,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		marginRight: 15,
		cursor: 'pointer',
	},
	select_all_text: { fontSize: 14, color: theme.colors.primary, marginTop: 20 },
	add_rider_wrap: { display: 'flex', justifyContent: 'space-between', marginTop: 20 },
	add_rider_input_wrap: {
		border: `1px solid ${theme.colors.lightPurple3}`,
		width: '100%',
		padding: '10px 20px',
		height: 40,
		borderRadius: 20,
		display: 'flex',
	},
	add_rider_input: {
		outline: 'none',
		border: 'none',
		marginLeft: 10,
		fontSize: 16,
		width: '100%',
	},
	search_rider_wrap: {
		marginTop: 5,
		position: 'absolute',
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		marginLeft: 30,
		width: 300,
		zIndex: 1000,
		border: `1px solid ${theme.colors.lightGrey3}`,
		borderRadius: 10,
		background: 'white',
		overflowY: 'auto',
		maxHeight: 200,
	},
	rider_list_wrap: { width: '100%', height: 'auto', overflowY: 'auto', marginTop: 20, marginBottom: '100px' },
	delete_btn: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 20,
		display: 'flex',
		alignItems: 'center',
	},
	create_btn: { borderRadius: 25, height: 45, width: 110 },
	create_btn_wrap: {
		marginTop: 'auto',
		boxShadow: '0px -4px 14px rgba(0, 0, 0, 0.05)',
		backgroundColor: theme.colors.white,
		position: 'fixed',
		width: 600,
		bottom: 0,
		right: 0,
		padding: '28px 30px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	rider_name_text: { color: 'black', marginLeft: 40, fontSize: 16 },
	rider_info: { display: 'flex', alignItems: 'center' },
	rider_id_text: { color: 'black', fontSize: 16 },
	rider_list_item: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 15,
	},
	custom_date_wrap: {
		width: 110,
		height: 32,
		borderRadius: 20,
		border: '1px solid black',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingRight: 8,
		paddingLeft: 8,
		marginTop: 10,
	},
	divider: {
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
	},
	hub_box: {
		padding: 12,
		boxShadow: '0px 2px 4px rgba(196, 196, 196, 0.3)',
		borderRadius: 8,
		marginTop: 16,
	},
	upload_button: {
		height: 36,
		width: 36,
		borderRadius: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 20,
		cursor: 'pointer',
		'&:hover': {
			'& .purple-arrow': {
				display: 'none',
			},
			'& .white-arrow': {
				display: 'block !important',
			},
		},
	},
	overnight_wrapper: {
		fontSize: 16,
		color: theme.colors.darkGrey2,
		marginBottom: 10,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: 4,
	},
	overnight_strip: {
		display: 'flex',
		padding: '8px 12px',
		gap: 8,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '0px 0px 8px 8px',
		background: 'rgba(242, 238, 248, 0.60)',
		marginTop: 20,
	},
	info_circle: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			'& .purple_info': {
				display: 'none',
			},
			'& .filled_purple_info': {
				display: 'block !important',
			},
		},
	},
}));
