import React from 'react';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import IconButton from 'modules/IconButton';
import Text from 'modules/Text';
import theme from 'resources/theme';
import moment from 'moment';
import { Dialog, DialogContent } from '@material-ui/core';
import { DateRangePicker } from 'materialui-daterange-picker';
import OrderChart from './commponents/OrderChart';
import DistanceChart from './commponents/DistanceChart';
import HourChart from './commponents/HourChart';
import PayoutChart from './commponents/PayoutChart';
import ActiveInactiveDropdown from 'modules/ActiveInactiveDropdown';
import RiderInfoCard from './commponents/RiderInfoCard';
import PerformanceTab from './commponents/PerformanceTab';
import PayoutBreakup from './commponents/PayoutBreakup';
import useStyles from './styles';
import RiderOverviewModal from 'modules/modals/RiderOverviewModal';
import CreateRoster from 'views/Roster/components/CreateRoster/CreateRoster';
import useRiderProfile from './useRiderProfile';
import AttendenceItem from './commponents/AttendenceItem';
import Routes from 'resources/Routes';
import ConfirmClose from 'modules/modals/ConfirmClose';
import utils from 'resources/utils';
import constant from 'resources/constant';

const performance_tab = ['Total Orders', 'Distance Travelled', 'Hours Worked'];
const payout_breakup = [
	{
		amount: 5000,
		title: 'Order',
		item_color: theme.colors.lightPurple7,
	},
	{
		amount: 2000,
		title: 'Time',
		item_color: theme.colors.lightPurple6,
	},
	{
		amount: 2000,
		title: 'Distance',
		item_color: theme.colors.lightPurple4,
	},
	{
		amount: 5000,
		title: 'Salary',
		item_color: theme.colors.lightYellow,
	},
	// {
	// 	amount: 1000,
	// 	title: 'Min. G',
	// 	item_color: theme.colors.lightGreen4,
	// },
];

const RiderProfile = ({ match, history }) => {
	const rider_id = match.params.id;
	const classes = useStyles();
	const {
		selected_tab,
		graph_date,
		chart_data,
		roster_date,
		show_date_range_picker,
		shift_time,
		is_active,
		rider_details,
		roster_details,
		show_rider_modal,
		show_create_roster,
		selected_attendence_item_index,
		current_week_roster_date,
		confirm_close_modal,
		roster_details_week,
		day_start_time,
		set_show_rider_modal,
		set_show_date_range_picker,
		set_selected_tab,
		set_graph_date,
		change_status,
		get_hour_diff,
		on_roster_left_nav_press,
		on_roster_right_nav_press,
		get_item_bg_color,
		clear_device_id,
		handle_all_orders,
		fetch_roster_data,
		handle_edit_or_create_roster,
		close_roster_modal,
		close_confirm_modal,
		set_rider_details,
		handle_time_scale,
	} = useRiderProfile(rider_id, history);

	return (
		<div className={`container ${classes.wrapper}`}>
			<div className={classes.header}>
				<div className='align-items-center'>
					<Text className='cursor-pointer global_header_text' onClick={() => history.goBack()}>
						Riders
					</Text>
					<div className='header_separator'></div>
					<Text semi className='header_active_title'>
						{_.get(rider_details, 'details.rider_name', '')}
						<Text component={'span'} semi style={{ fontSize: 15 }}>
							{` (${_.get(rider_details, 'details.rider_id', '')})`}
						</Text>
					</Text>
				</div>
				<div className={classes.flex_center}>
					<Text bold style={{ fontSize: 16, color: theme.colors.lightPurple6, marginRight: 15 }}>
						{`Rider Since: ${moment(_.get(rider_details, 'details.created_at')).format('DD MMMM YYYY')}`}
					</Text>
					<ActiveInactiveDropdown is_active={is_active} set_is_active={change_status} inactive_text={'Inactive'} />
				</div>
			</div>

			<div className={classes.content}>
				<div className={classes.left_wrap}>
					<RiderInfoCard
						history={history}
						rider_details={rider_details}
						toggle_rider_modal={() => {
							utils.track_event_for_analytics(constant.TRACKING_EVENTS.RIDER_NAME);
							set_show_rider_modal(true);
						}}
						clear_device_id={clear_device_id}
					/>
					{/* 
					Commenting gow now. Will be implemented later
					<div className={classes.box_wrap}>
						<div className={classes.box_item_wrap} style={{ marginRight: 10 }}>
							<Text semi style={{ fontSize: 16 }}>
								Reserved Group
							</Text>
							<div className={classes.reserved_grp_count_wrap}>
								<Text bold style={{ fontSize: 12, color: theme.colors.primary }}>
									2
								</Text>
								<img src={ImageLinks.chevron_right_pink} width={12} height={12} />
							</div>
						</div>
						<div className={classes.box_item_wrap} style={{ marginLeft: 10 }}>
							<Text semi style={{ fontSize: 16 }}>
								Network Access
							</Text>
							<Text semi style={{ fontSize: 12, color: theme.colors.primary }}>
								Coming Soon!
							</Text>
						</div>
					</div> */}

					<div className={classes.performance_wrap}>
						<div className={classes.section_header}>
							<Text bold style={{ fontSize: 16 }}>
								Rider Performance
							</Text>
							<div
								className={classes.flex_center}
								onClick={() => {
									set_show_date_range_picker(true);
								}}>
								<img src={ImageLinks.calendar_solid_circle} width={20} height={20} />
								<Text bold className={classes.graph_date_filter_text}>
									{`${moment(graph_date.split(',')[0]).format('DD MMM `YY')} - ${moment(graph_date.split(',')[1]).format('DD MMM `YY')}`}
								</Text>
							</div>
						</div>
						<div className={classes.performance_header_separator}> </div>

						<div className={classes.performance_box}>
							<div className={classes.performance_tab_wrap}>
								{_.map(performance_tab, (item, index) => (
									<PerformanceTab
										key={`performance_tab_${index}`}
										item={item}
										is_selected={selected_tab === index}
										on_tab_click={() => {
											utils.track_event_for_analytics(`rider_performance_${index == 0 ? 'order' : index == 1 ? 'distance' : 'hour'}`);
											set_selected_tab(index);
										}}
									/>
								))}
							</div>
							<div className={classes.graphs_wrap}>
								{selected_tab === 0 && <OrderChart handle_all_orders={handle_all_orders} chart_data={chart_data} />}
								{selected_tab === 1 && <DistanceChart chart_data={chart_data} />}
								{selected_tab === 2 && <HourChart chart_data={chart_data} />}
							</div>
						</div>
					</div>
				</div>
				<div className={classes.right_wrap}>
					<div className={classes.roster_wrap}>
						<div className={classes.section_header}>
							<Text bold style={{ fontSize: 16 }}>
								Roster
							</Text>
							<div className={classes.flex_center}>
								<div className={classes.roster_nav_btns}>
									<IconButton onClick={on_roster_left_nav_press}>
										<img src={ImageLinks.chevronLeft} />
									</IconButton>
								</div>
								<Text bold className={classes.roster_header_time_text}>
									{`${moment(roster_date.split(',')[0]).format('DD MMM `YY')} - ${moment(roster_date.split(',')[1]).format('DD MMM `YY')}`}
								</Text>
								<div className={classes.roster_nav_btns}>
									<IconButton disabled={current_week_roster_date === roster_date} onClick={on_roster_right_nav_press}>
										<img src={ImageLinks.chevronLeft} style={{ transform: 'rotate(180deg)' }} />
									</IconButton>
								</div>
							</div>
						</div>
						<div className={classes.roster_header_separator}> </div>

						<div>
							<div style={{ display: 'flex' }}>
								{_.includes(shift_time, '12 AM') ? (
									<img
										src={ImageLinks.chevron_up_gray}
										className='purple-arrow'
										width='16'
										height={'16'}
										style={{ transform: 'rotate(270deg)' }}
									/>
								) : (
									<img
										src={ImageLinks.chevron_right_purple}
										className='purple-arrow'
										width='16'
										height={'16'}
										onClick={() => handle_time_scale('am')}
										style={{ transform: 'rotate(180deg)' }}
									/>
								)}
								{_.map(shift_time, (item, index) => (
									<div
										key={`shift_time_${index}`}
										style={{
											display: 'flex',
											flexDirection: 'column',
											width: `${100 / shift_time.length + 1}%`,
											alignItems: 'center',
											textAlign: 'center',
											justifyContent: 'center',
											position: 'relative',
										}}>
										<Text className={classes.shift_item_text} semi>
											{_.split(item, ' ')[0]}
											<Text component={'span'} semi style={{ fontSize: 10 }}>
												{_.split(item, ' ')[1]}
											</Text>
										</Text>
										{<div className={classes.roster_border_line}></div>}
									</div>
								))}
								{_.includes(shift_time, '12 PM') ? (
									<img
										src={ImageLinks.chevron_up_gray}
										className='purple-arrow'
										width='16'
										height={'16'}
										style={{ transform: 'rotate(90deg)' }}
									/>
								) : (
									<img
										src={ImageLinks.chevron_right_purple}
										className='purple-arrow'
										onClick={() => handle_time_scale('pm')}
										width='16'
										height={'16'}
									/>
								)}
							</div>

							{shift_time.length > 0 && (
								<div>
									{_.map(roster_details_week, (item, index) => {
										return (
											<AttendenceItem
												key={`attendence_row_${index}`}
												item={item}
												shift_time={shift_time}
												get_item_bg_color={get_item_bg_color}
												get_hour_diff={get_hour_diff}
												handle_edit_or_create_roster={() => handle_edit_or_create_roster(index)}
												day_start_time={day_start_time}
												leave_details={roster_details.leave_data}
											/>
										);
									})}
								</div>
							)}
						</div>
					</div>

					<div className={classes.payout_wrap}>
						<div className={classes.section_header}>
							<Text bold style={{ fontSize: 16 }}>
								Payout
							</Text>
							{/* 
							Will be added later
							<div className={classes.flex_center}>
								<Text bold className={classes.detail_text}>
									Detail
								</Text>
								<img src={ImageLinks.chevron_right_pink} width={12} height={12} />
							</div> */}
						</div>
						<div className={classes.payout_separator}> </div>

						<div style={{ display: 'flex', height: '75%' }}>
							<div className={classes.payout_left_wrap}>
								<div>
									{/* 
									Will be added later
									<Amount
										amount={11000}
										main_text_styles={{ fontSize: 26, color: theme.colors.primary }}
										sub_text_styles={{ fontSize: 26, color: theme.colors.primary }}
									/> */}

									<Text semi style={{ fontSize: 22, color: theme.colors.primary }}>
										{/* 
										Will be added later
										15 Days | 11 Dec ‘22 - 26 Dec ‘22 */}
										Coming Soon!
									</Text>
									<Text bold style={{ fontSize: 12, marginTop: 1 }}>
										{/* 15 Days | 11 Dec ‘22 - 26 Dec ‘22 */}
										Manage your riders payments with full visibility
									</Text>
								</div>
								<div style={{ display: 'flex', width: '80%' }}>
									{_.map(payout_breakup, (item, index) => (
										<PayoutBreakup item={item} key={`payout_breakup_${index}`} />
									))}
								</div>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column', flex: 0.3 }}>
								<PayoutChart />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Dialog open={show_date_range_picker} PaperProps={{ style: { background: 'transparent', maxWidth: 900 }, elevation: 0 }}>
				<DialogContent style={{}}>
					<DateRangePicker
						maxDate={new Date()}
						open={show_date_range_picker}
						toggle={() => set_show_date_range_picker((prev) => !prev)}
						initialDateRange={{ startDate: graph_date.split(',')[0], endDate: graph_date.split(',')[1] }}
						style={{ width: 200 }}
						onChange={(range) => {
							set_graph_date(moment(range.startDate).format('YYYY-MM-DD') + ',' + moment(range.endDate).format('YYYY-MM-DD'));
						}}
					/>
				</DialogContent>
			</Dialog>
			{show_create_roster && (
				<CreateRoster
					is_rider_editable={false}
					rider_info={{
						mobile: _.get(rider_details, 'details.mobile'),
						rider_category: _.get(rider_details, 'details.rider_category'),
						rider_id: _.get(rider_details, 'details.rider_id'),
						rider_name: _.get(rider_details, 'details.rider_name'),
						shift_start_24_time: roster_details.roster[selected_attendence_item_index]?.shift_id
							? moment(roster_details.roster[selected_attendence_item_index]?.time_slot_from_real).format('HH:mm')
							: '',
						shift_end_24_time: roster_details.roster[selected_attendence_item_index]?.shift_id
							? moment(roster_details.roster[selected_attendence_item_index]?.time_slot_to_real).format('HH:mm')
							: '',
						selected_date: roster_details.roster[selected_attendence_item_index]?.roster_date
							? moment(roster_details.roster[selected_attendence_item_index]?.roster_date).format('YYYY-MM-DD')
							: moment(roster_details_week[selected_attendence_item_index]?.roster_date).format('YYYY-MM-DD'),
						selected_hub: roster_details.roster[selected_attendence_item_index]?.hub_id,
					}}
					close={(isCreated) => {
						if (isCreated) {
							fetch_roster_data();
						}
						close_roster_modal();
					}}
				/>
			)}
			{show_rider_modal && <RiderOverviewModal rider_data={rider_details} set_rider_data={set_rider_details} close={() => set_show_rider_modal(false)} />}

			{confirm_close_modal.open && (
				<ConfirmClose
					headerTitle={confirm_close_modal.header_title}
					title={confirm_close_modal.title}
					close={close_confirm_modal}
					positive_btn_text={confirm_close_modal.positive_text}
					negative_btn_text={confirm_close_modal.negative_text}
					titleStyles={{ fontSize: 16, textAlign: 'left' }}
					on_confirm={confirm_close_modal.confirm_acion}
				/>
			)}
		</div>
	);
};

export default RiderProfile;
