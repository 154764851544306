import React, { useEffect, useMemo, useState } from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import Text from 'modules/Text';
import _ from 'lodash';
import ImageLinks from 'assets/images/ImageLinks';
import Select from 'modules/Select';
import Button from 'modules/Button';
import utils from 'resources/utils';
import UndeliveredOrdersInfo from 'views/NetworksFlow/CreateNetwork/components/UndeliveredOrders/UndeliveredOrdersInfo';
import api_requests from 'resources/api_requests';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
	heading_container: { display: 'flex', justifyContent: 'space-between', marginBottom: 10 },
	heading: { color: theme.colors.primary, fontSize: 18, marginBottom: 10 },
	sub_heading: { color: theme.colors.darkGrey8 },
	info_container: { width: 340, backgroundColor: theme.colors.lightPurple10, borderRadius: 20, padding: 20, height: '60vh', flex: 0.8 },
	tips: {
		backgroundColor: theme.colors.lightPurple,
		marginRight: 0,
		width: 80,
		borderRadius: 5,
		display: 'flex',
		justifyContent: 'center',
		padding: '8px 8px',
	},
	input_section: {
		marginTop: 8,
		backgroundColor: theme.colors.white,
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		borderRadius: 14,
	},
	label_subtitle: { fontSize: 12, color: theme.colors.darkGrey2 },
	output_section: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	notes_wrapper: {
		backgroundColor: theme.colors.lightPink4,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 28,
		borderBottomLeftRadius: 12,
		borderBottomRightRadius: 12,
	},
	output_section_info_1: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 8, marginBottom: 30 },
	output_section_info_box: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 4 },
	info_box: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: 4,
		padding: 10,
		border: `1px solid ${theme.colors.lightPink9}`,
		borderRadius: 6,
		background: theme.colors.white,
		textAlign: 'center',
	},
	reattempt_button: {
		display: 'flex',
		width: '87.5px',
		padding: '6.667px 6.667px 6.667px 10px',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 30,
		border: `0.833px solid ${theme.colors.lightPurple6}`,
	},
	menu_item_class: {
		borderBottom: `1px solid ${theme.colors.lightGrey5}`,
		'&:hover': {
			backgroundColor: theme.colors.lightPink4,
		},
	},
}));

const ACTION_ON_FAILED_DELIVERY = [
	{ title: 'Return to Sender', subtitle: 'Package will be sent to the sender', value: true },
	{ title: 'Reattempt Order', subtitle: 'If you or your supplier do not have a warehouse then order will return to sender', value: false },
];

const UndeliveredOrders = ({ set_refresh, failed_delivery }) => {
	const classes = useStyles();
	const [reattempt_config, set_reattempt_config] = useState({ number_of_reattempts: 0, return_to_sender: true });

	useEffect(() => {
		set_reattempt_config(failed_delivery);
	}, [failed_delivery]);

	const handle_select_action = (val) => {
		const _reattempt_config = _.cloneDeep(reattempt_config);
		if (val) {
			_reattempt_config.return_to_sender = val;
			_reattempt_config.number_of_reattempts = 0;
		} else {
			_reattempt_config.return_to_sender = val;
			_reattempt_config.number_of_reattempts = 1;
		}
		set_reattempt_config(_reattempt_config);
	};

	const handle_update_settings = async () => {
		let is_create_flow = true;
		const config_payload = {
			default: false,
			reattempt_config: reattempt_config,
			owner: {
				id: utils._retrieveData('owner_id'),
			},
		};
		if (reattempt_config?.id !== 0) {
			is_create_flow = false;
		}
		try {
			const response = is_create_flow
				? await api_requests.create_network_reattempt_config(-2, config_payload)
				: await api_requests.update_network_reattempt_config(-2, config_payload);
			toast.success('OK', {
				position: toast.POSITION.BOTTOM_RIGHT,
			});
			set_refresh((prev) => !prev);
		} catch (err) {}
	};
	const btn_disable = useMemo(() => {
		let val = true;
		if (
			failed_delivery.number_of_reattempts != reattempt_config.number_of_reattempts ||
			failed_delivery.return_to_sender != reattempt_config.return_to_sender ||
			failed_delivery?.id == 0
		) {
			val = false;
		}
		return val;
	}, [reattempt_config, failed_delivery]);
	return (
		<>
			<div style={{ position: 'relative' }}>
				<div className={classes.heading_container}>
					<Text bold className={classes.heading}>
						Undelivered Orders
					</Text>
					<ListItem key={`tips`} button onClick={() => {}} className={classes.tips}>
						<Text bold style={{ color: theme.colors.primary, fontSize: 10, marginLeft: 2 }}>
							Tips & Info
						</Text>
					</ListItem>
				</div>

				<div style={{ display: 'flex' }}>
					<div style={{ marginRight: 20, flex: '1' }}>
						<Text semi className={classes.sub_heading} style={{ fontSize: 14 }}>
							Manage what happens when orders are not delivered to the end customer.
						</Text>
						<div style={{ display: 'flex' }}>
							<div className={classes.input_section}>
								<div>
									<div className='d-flex align-items-center' style={{ marginTop: 10, gap: 55 }}>
										<div>
											<Text semi style={{ fontSize: 14, color: theme.colors.darkGrey7 }}>
												Action on Failed Deliveries
											</Text>
											<Text medium className={classes.label_subtitle}>
												Select what happens when an order is not delivered to the customer
											</Text>
										</div>
										<Select
											value={reattempt_config?.return_to_sender}
											defaultOption={{
												title: 'Select Action',
												value: '',
											}}
											options={ACTION_ON_FAILED_DELIVERY}
											handleChange={(val) => handle_select_action(val)}
											selected_title_styles={{ color: theme.colors.primary }}
											containerStyles={{ height: 35, width: '63%', borderColor: theme.colors.lightPurple }}
											menu_item_class={{ className: classes.menu_item_class }}
											menu_styles={{ width: '16%' }}
										/>
									</div>
									{!reattempt_config?.return_to_sender && (
										<div className='align-items-center justify-content-between' style={{ marginTop: 20 }}>
											<div>
												<Text medium style={{ fontSize: 14 }}>
													No. of re-attempts allowed
												</Text>
												<Text medium className={classes.label_subtitle}>
													Define how many times delivery attempts can be made
												</Text>
											</div>
											<div className={classes.reattempt_button}>
												<img
													src={ImageLinks.minus_simple_primary}
													style={{ cursor: 'pointer', pointerEvents: true && !true ? 'none' : 'auto' }}
													onClick={() =>
														set_reattempt_config({
															...reattempt_config,
															number_of_reattempts:
																reattempt_config?.number_of_reattempts > 1
																	? reattempt_config?.number_of_reattempts - 1
																	: reattempt_config?.number_of_reattempts,
														})
													}
												/>
												<Text semi style={{ color: theme.colors.primary, fontSize: 14 }}>
													{reattempt_config?.number_of_reattempts}
												</Text>
												<img
													src={ImageLinks.plus_simple_primary}
													style={{ cursor: 'pointer', pointerEvents: true && !true ? 'none' : 'auto' }}
													onClick={() =>
														set_reattempt_config({
															...reattempt_config,
															number_of_reattempts: reattempt_config?.number_of_reattempts + 1,
														})
													}
												/>
											</div>
										</div>
									)}
									<div
										className='d-flex justify-content-center align-items-center'
										style={{ marginTop: 20, background: theme.colors.lightPink, borderRadius: 6 }}>
										<Text semi style={{ fontSize: 12, color: theme.colors.darkPurple, padding: 10 }}>
											Please Note: This configuration is for self-fulfilled orders only. For orders delivered by networks, network
											settings will be applied.
										</Text>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={classes.info_container}>
						<div className={classes.output_section}>
							<div className={classes.output_section_info_1}>
								<Text semi style={{ fontSize: 14, color: theme.colors.lightPurple5 }}>
									What can happen to undelivered orders?
								</Text>
								<div className={classes.output_section_info_box}>
									<Text medium className={classes.label_subtitle}>
										The delivery executive can do one of the following 2options:
									</Text>
									<div className='d-flex align-items-start' style={{ gap: 10 }}>
										<div className={classes.info_box}>
											<Text semi style={{ fontSize: 14, color: theme.colors.lightPurple7 }}>
												Return to Sender
											</Text>
											<Text medium className={classes.label_subtitle}>
												Package is sent back to the pickup location.
											</Text>
										</div>
										<div className={classes.info_box}>
											<Text semi style={{ fontSize: 14, color: theme.colors.lightPurple7 }}>
												Reattempt Order
											</Text>
											<Text medium className={classes.label_subtitle}>
												Packages is attempted again for delivery before final RTO.
											</Text>
										</div>
									</div>
								</div>
							</div>
							<UndeliveredOrdersInfo />
						</div>
					</div>
				</div>
			</div>
			<div style={{ position: 'absolute', bottom: 20, right: 30 }}>
				<Button
					disabled={btn_disable}
					onClick={() => {
						handle_update_settings({
							default: false,
							reattempt_config: {
								number_of_reattempts: 1,
								return_to_sender: false,
							},
							owner: {
								id: 467,
							},
						});
					}}
					type='round'
					text='Save'
					size='large'
					style={{ width: 110 }}
				/>
			</div>
		</>
	);
};

export default UndeliveredOrders;
