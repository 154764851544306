import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

import TimeInput from './TimeInput';
import Text from './Text';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';

const useStyles = makeStyles(() => ({
	date_wrapper: {
		border: `1px solid ${theme.colors.lightPurple}`,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		padding: '8px 12px',
		borderRadius: 36,
		display: 'flex',
		alignItems: 'center',
		width: 130,
		justifyContent: 'space-between',
		height: 36,
		marginRight: 20,
	},
	wrapper: {
		width: '47%',
	},
}));

function DateTimeInput({ on_change, initial_value = null }) {
	const classes = useStyles();

	const [date, set_date] = useState(initial_value ? moment(initial_value).format('YYYY-MM-DD') : null);
	const [time, set_time] = useState(initial_value ? moment(initial_value).format('HH:mm') : '12:00');

	useEffect(() => {
		if (initial_value) {
			set_date(moment(initial_value).format('YYYY-MM-DD'));
			set_time(moment(initial_value).format('HH:mm'));
		}
	}, [initial_value]);

	useEffect(() => {
		if (date) {
			on_change(moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').toISOString());
		}
	}, [date, time]);
	return (
		<div className='d-flex justify-content-center align-items-center' style={{ gap: 0 }}>
			<DatePicker
				wrapperClassName={classes.wrapper}
				dateFormat='yyyy-MM-dd'
				onChange={(date) => {
					const selected_date = moment(date).format('YYYY-MM-DD');
					set_date(selected_date);
				}}
				customInput={
					<div className={classes.date_wrapper}>
						<Text semi style={{ color: theme.colors.primary }}>
							{!_.isEmpty(date) ? moment(date).format('DD MMM `YY') : 'Enter Date'}
						</Text>
						<img src={ImageLinks.calendar_solid_circle} alt='date' className='cursor-pointer' width={20} height={20} />
					</div>
				}
			/>
			<TimeInput value={time} handleChange={(value) => set_time(value)} />
		</div>
	);
}

export default DateTimeInput;
