import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import ImageLinks from 'assets/images/ImageLinks';
import theme from 'resources/theme';
import Footer from './Footer';

const useStyles = makeStyles(() => ({
	title_text: { fontSize: 40 },
	content_wrap: { flex: 0.9, display: 'flex', flexDirection: 'column', justifyContent: 'center' },
	form_style: {
		width: '50%',
		height: '100%',
	},
	business_item_wrap: {
		minHeight: 52,
		borderRadius: 4,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 12,
		width: 330,
		marginBottom: 16,
		cursor: 'pointer',
		backgroundColor: theme.colors.lightPink9,
		border: `1px solid ${theme.colors.lightPurple}`,
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
			border: `1px solid ${theme.colors.primary}`,
		},
	},
}));

const BusinessList = ({ business_type_list, on_select_business }) => {
	const classes = useStyles();
	const [b_list, set_b_list] = useState(business_type_list);

	useEffect(() => {
		set_b_list(business_type_list);
	}, [business_type_list]);

	return (
		<div className={classes.form_style}>
			<div style={{ paddingLeft: '15%', paddingTop: '15%' }}>
				<div className={classes.content_wrap}>
					<Text semi style={{ fontSize: 20, marginTop: 10 }}>
						User Type
						<Text component={'span'} semi style={{ fontSize: 20 }}>
							{` | User`}
						</Text>
					</Text>
					<div
						style={{
							marginTop: 50,
							maxHeight: 300,
							overflowY: 'auto',
						}}>
						{_.map(b_list, (business, key) => (
							<div key={`business_list_${key}`} onClick={() => on_select_business(business)} className={classes.business_item_wrap} style={{}}>
								<Text medium style={{ fontSize: 16 }}>
									{business.brand_name}
									<Text component={'span'} style={{ fontSize: 12 }}>
										{` (${business.business_id})`}
									</Text>
								</Text>
								<img src={ImageLinks.arrow_right_purple} width={32} height={32} />
							</div>
						))}
					</div>
				</div>

				<Footer show_help />
			</div>
		</div>
	);
};

export default BusinessList;
