import { useTheme } from '@material-ui/core';
import React from 'react';
import Text from './Text';
import _ from 'lodash';
import { ICON_STYLE } from 'views/NetworksFlow/CreateNetwork/helper';
import NetworkRiderModal from 'views/Tracking/components/NetworkRiderModal';
import { shallowEqual, useSelector } from 'react-redux';
import ImageLinks from 'assets/images/ImageLinks';

function NetworkConfigAvatar({ height = 24, width = 24, name = '', id, logo_height = 40, logo_width = 40, text_color = 'black', font_size = 14 }) {
	const { app_configs } = useSelector(
		(state) => ({
			app_configs: state.app.app_configs,
		}),
		shallowEqual,
	);
	const network_configs = _.get(app_configs, `network_configs.${id}`, {});
	const theme = useTheme();
	const selected_icon_style_val = _.filter(ICON_STYLE, (item) => item.key === network_configs?.avatar?.configs?.icon_style)[0];
	return _.isEmpty(network_configs) ? (
		<Text style={{ fontSize: 16, color: text_color }} semi>
			{id == '-3' ? <img src={ImageLinks.pidge_titan_black} width={90} /> : name}
		</Text>
	) : network_configs?.avatar?.image ? (
		<div className='d-flex align-items-center'>
			{network_configs.avatar.image ? (
				<img src={network_configs.avatar.image} height={logo_height} width={logo_width} alt='' />
			) : (
				<Text semi style={{ color: text_color }}>
					{network_configs.name}
				</Text>
			)}
			{id != '-1' && id != '-2' && (
				<Text semi style={{ color: text_color, marginLeft: 4 }}>
					({id})
				</Text>
			)}
		</div>
	) : (
		<div className='d-flex align-items-center' style={{ gap: 5 }}>
			{network_configs.avatar?.configs ? (
				<div
					style={{
						backgroundColor: network_configs.avatar.configs?.avatar_color?.bg
							? network_configs.avatar.configs.avatar_color.bg
							: theme.colors.lightGrey5,
						width: width,
						minWidth: width,
						height: height,
						borderRadius: width / 2,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					{network_configs.avatar.configs?.avatar_style === 'monogram' ? (
						<Text semi style={{ fontSize: Math.floor(height * 0.6), color: theme.colors.white }}>
							{network_configs.avatar.configs?.initials_string}
						</Text>
					) : (
						<img src={selected_icon_style_val?.white_img} width={width / 2} height={height / 2} alt='' />
					)}
				</div>
			) : (
				<img src={network_configs.avatar.image} style={{ width: width, height: height, borderRadius: width / 2 }} alt='' />
			)}
			<Text semi style={{ color: text_color, fontSize: font_size }}>
				{network_configs?.name} ({id})
			</Text>
		</div>
	);
}

export default NetworkConfigAvatar;
