import { ClickAwayListener, Divider, Popper, makeStyles, useTheme } from '@material-ui/core';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React, { useContext, useRef, useState } from 'react';
import OrderModalContext from '../OrderModalContext';
import _ from 'lodash';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		borderRadius: 8,
		boxShadow: '0px 2px 4px 0px rgba(196, 196, 196, 0.30)',
		padding: 12,
	},
	img_wrap: { height: 22, width: 22, border: `1px solid ${theme.colors.lightGrey5}`, borderRadius: 11 },
	menu_wrap: { backgroundColor: theme.colors.white, boxShadow: '0px 1px 10px 1px rgba(202, 202, 202, 1)', borderRadius: 6, cursor: 'pointer' },
	menu_icon: {
		'&:hover': {
			'& .light-purple': {
				display: 'none !important',
			},
			'& .primary-purple': {
				display: 'block !important',
			},
		},
	},
}));

function OrderDetails() {
	const { data, set_show_edit_cod, send_payment_link, link_sent_to, network_configs, is_cod_type_ppn, pbid_data } = useContext(OrderModalContext);
	const classes = useStyles();
	const theme = useTheme();

	const [show_menu, set_show_menu] = useState(false);
	const menu_ref = useRef();
	const channel_id = _.get(data, 'fulfillment.channel.id');

	const is_cod_edit_for_ppn =
		data.status === 3 && network_configs[channel_id]?.implementation === 'ppn-private' && network_configs[channel_id]?.type === 1 && data.cod_amount > 0 && pbid_data?.cash_status != 200;

	return (
		<div className={classes.wrapper}>
			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center' style={{ gap: 5 }}>
					<img src={ImageLinks.reciept} alt='' />
					<Text bold component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
						Order Details
					</Text>
				</div>

				<div ref={menu_ref} onClick={() => set_show_menu((prev) => !prev)}>
					<div style={{ cursor: 'pointer' }} className={classes.menu_icon}>
						<img className='light-purple' style={{ display: 'block' }} src={ImageLinks.three_dots_light_purple} alt='' />
						<img className='primary-purple' style={{ display: 'none' }} src={ImageLinks.three_dots_primary} alt='' />
					</div>
					<Popper
						anchorEl={menu_ref.current}
						open={show_menu}
						style={{ zIndex: 1202, marginRight: 30 }}
						onClose={() => set_show_menu(false)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}>
						<ClickAwayListener onClickAway={() => set_show_menu(false)}>
							<div className={classes.menu_wrap} style={{ backgroundColor: theme.colors.white }}>
								{(data.status === 1 || (data.status === 3 && _.get(data, 'fulfillment.channel.id') == '-2' && pbid_data?.cash_status != 200) || is_cod_edit_for_ppn) && (
									<div
										className='d-flex'
										style={{ padding: '8px 10px', gap: 5, borderBottom: `1px solid ${theme.colors.lightGrey5}` }}
										onClick={() => {
											utils.track_event_for_analytics(constant.TRACKING_EVENTS.EDIT_COD);
											set_show_edit_cod(true);
											is_cod_type_ppn.current = is_cod_edit_for_ppn;
										}}>
										<img src={ImageLinks.edit_filled_light_purple} alt=''></img>
										<Text medium>COD/Amount</Text>
									</div>
								)}
								{data.status == 3 &&
									_.get(data, 'fulfillment.channel.id') == '-2' &&
									data.cod_amount > 0 &&
									_.includes(['PICKED_UP', 'IN_TRANSIT', 'OUT_FOR_DELIVERY', 'REACHED_DELIVERY'], _.get(data, 'fulfillment.status')) && (
										<div className='d-flex' style={{ padding: '8px 10px', gap: 5 }} onClick={send_payment_link}>
											<img
												src={_.includes(link_sent_to, 'payment') ? ImageLinks.tickCirclePurpleSolid : ImageLinks.link_chain_filled}
												height={18}
												width={18}
												alt=''></img>
											<Text medium>Payment link</Text>
										</div>
									)}
							</div>
						</ClickAwayListener>
					</Popper>
				</div>
			</div>
			<Divider style={{ margin: '10px 0' }} />
			<div className='d-flex align-items-center justify-content-between'>
				<div className='d-flex align-items-center' style={{ flex: 1, gap: 10 }}>
					<div>
						<Text semi style={{ fontSize: 14 }}>
							{utils._retrieveData('owner_id') != data.owner?.id ? _.get(data, 'owner.name', '-') : _.get(data, 'dd_channel.name', '-')}
						</Text>
						<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
							{utils._retrieveData('owner_id') != data.owner?.id ? 'Source' : 'Channel'}
						</Text>
					</div>
				</div>
				{_.get(data, 'dd_channel.user.type', '-') == 4 && (
					<div className='d-flex align-items-center' style={{ flex: 1, gap: 10 }}>
						<div>
							<Text semi style={{ fontSize: 14 }}>
								{_.get(data, 'dd_channel.order_id', '')}
							</Text>
							<Text medium style={{ fontSize: 12, color: theme.colors.darkGrey2 }}>
								CHOID
							</Text>
						</div>
					</div>
				)}
				<div style={{ flex: 1 }}>
					<div className='d-flex align-items-center' style={{ gap: 5 }}>
						<img src={ImageLinks.inr_filled} alt='' />
						<Text component={'span'} medium>
							{data.cod_amount}
						</Text>
					</div>
					<div>
						<Text component={'span'} medium>
							<Text component={'span'} style={{ fontSize: 14, color: theme.colors.darkGrey2 }}>
								Bill Amount:
							</Text>
							{data.bill_amount}
						</Text>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OrderDetails;
