export const NDR_INSTRUCTIONS = {
	RTO: 'RTO',
	RE_ATTEMPT: 'RE_ATTEMPT',
};

export const NDR_INSTRUCTIONS_DETAILS = {
	[NDR_INSTRUCTIONS.RTO]: {
		label: 'RTO',
	},
	[NDR_INSTRUCTIONS.RE_ATTEMPT]: {
		label: 'Re-attempt',
	},
};

export const ATTEMPT_TYPE = {
	[NDR_INSTRUCTIONS.RTO]: 30,
	[NDR_INSTRUCTIONS.RE_ATTEMPT]: 40,
};

export const EXEMPTED_UNIFIED_STATUSES_FOR_NDR_CONFIRMATION = [0, 11, 22];
export const ALLOWED_UNIFIED_STATUSES_EDIT_RECEIVER_INFO = [1, 2, 3, 4, 5, 10];

export const RECEIVER_INPUT_STATES = {
	EDIT: 'EDIT',
	SAVE: 'SAVE',
};

export const status_options = [
	{ title: 'Confirm Orders', value: 90 },
	{ title: 'Start for Pickup', value: 100 },
	{ title: 'Reached Pickup Location', value: 120 },
	{ title: 'Receive Packages', value: 130 },
	{ title: 'Start for Delivery', value: 150 },
	{ title: 'Reached Delivery Location', value: 170 },
	{ title: 'Trip Completed', value: 190 },
	{ title: 'Trip Failed', value: 5 },
];

export const failed_options = [
	{ title: 'Customer not available', value: '10' },
	{ title: 'Customer unreachable', value: '20' },
	{ title: 'Customer reschedule order', value: '30' },
	{ title: 'Requested by brand', value: '40' },
	{ title: 'Address pincode mismtatch - DRTO', value: '50' },
	{ title: 'Non serviceable ODA - DRTO', value: '60' },
	{ title: 'Customer reject order - DRTO', value: '70' },
	{ title: 'COD dispute - DRTO', value: '80' },
	{ title: 'Not attempted', value: '90' },
];

export const get_allowable_status = (trip_type, current_status) => {
	let result = [];
	for (let i = 0; i < status_options.length; i++) {
		const element = status_options[i];
		if (current_status < element.value) {
			result.push(element.value);
			break;
		}
	}
	if (trip_type === 3 && (current_status == 130 || current_status == 150 || current_status == 170)) result.push(5);
	if (trip_type === 4 && current_status == 90) {
		result = [];
		result.push(150);
	}
	return result;
};
