import _ from 'lodash';
import moment from 'moment';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import BootstrapTooltip from 'modules/BootstrapTooltip';

const RosterBar = ({ r, days_diff, day_start_time, total_minutes, date, index, current_time, show_hover_text = true }) => {
	const overnight_last =
		!_.isEmpty(r.time_slot_to_real) &&
		moment(moment(r.time_slot_to_real).format('DD/MM/YYYY'), 'DD/MM/YYYY').diff(moment(moment(date).format('DD/MM/YYYY'), 'DD/MM/YYYY'), 'day') > 0;

	const overnight_first =
		!_.isEmpty(r.time_slot_to_real) &&
		moment(moment(date).format('DD/MM/YYYY'), 'DD/MM/YYYY').diff(moment(moment(r.time_slot_from_real).format('DD/MM/YYYY'), 'DD/MM/YYYY'), 'day') > 0;

	let show_bar = true;
	let borderRadius = 4;
	let width = `${(moment(r.time_slot_to_real).diff(moment(r.time_slot_from_real), 'minute') / total_minutes) * 100}%`;

	let margin_left = `${Math.max(
		(moment(moment(r.time_slot_from_real).format('HH:mm A'), 'HH:mm A').diff(moment(day_start_time), 'minute') / total_minutes) * 100,
		0,
	)}%`;

	let tooltip_text = '';

	let login_bar_array = [];
	let login_data = _.map(r.login_data ? r.login_data : r.attendance, (val) => {
		return {
			login_time: val.login_time ? val.login_time : val.login_time_real,
			logout_time: val.logout_time ? val.logout_time : val.logout_time_real,
		};
	});
	if (!_.isEmpty(r.time_slot_from_real) && !_.isEmpty(r.time_slot_to_real)) {
		if (
			(moment(day_start_time, 'H:mm').format('a') == 'am' && moment(r.time_slot_from_real).format('a') == 'am') ||
			(moment(day_start_time, 'H:mm').format('a') == 'pm' && moment(r.time_slot_to_real).format('a') == 'pm') ||
			(moment(day_start_time, 'H:mm').format('a') == 'pm' && overnight_last) ||
			(moment(day_start_time, 'H:mm').format('a') == 'am' && overnight_first)
		) {
			show_bar = true;
		} else {
			show_bar = false;
		}
	}
	if (!_.isEmpty(login_data)) {
		_.map(login_data, (val) => {
			let login_bar_margin_left = 0;
			let login_bar_width = 0;
			let login_bar_border_radius = 4;
			if (val.logout_time) {
				if (moment(day_start_time, 'H:mm').format('a') == 'am') {
					if (moment(val.login_time).format('a') == 'am' && moment(val.login_time).format('a') == 'am') {
						login_bar_margin_left = `${Math.max(
							(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A').diff(moment(day_start_time), 'minute') / total_minutes) * 100,
							0,
						)}%`;
						login_bar_border_radius = 4;
						login_bar_width = `${
							(moment(moment(val.logout_time)).diff(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A'), 'minute') / total_minutes) * 100
						}%`;
					} else if (moment(val.login_time).format('a') == 'am' && moment(val.login_time).format('a') == 'pm') {
						login_bar_margin_left = `${Math.max(
							(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A').diff(moment(day_start_time), 'minute') / total_minutes) * 100,
							0,
						)}%`;
						login_bar_border_radius = '0px 4px 4px 0px';
						login_bar_width = '-webkit-fill-available';
					}
				} else {
					if (moment(val.login_time).format('a') == 'am' && moment(val.login_time).format('a') == 'pm') {
						login_bar_margin_left = 0;
						login_bar_border_radius = '4px 0px 0px 4px';
						login_bar_width = `${
							(moment(moment(val.logout_time)).diff(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A'), 'minute') / total_minutes) * 100
						}%`;
					} else if (moment(val.login_time).format('a') == 'pm' && moment(val.login_time).format('a') == 'pm') {
						login_bar_margin_left = `${Math.max(
							(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A').diff(moment(day_start_time), 'minute') / total_minutes) * 100,
							0,
						)}%`;
						login_bar_border_radius = 4;
						login_bar_width = `${
							(moment(moment(val.logout_time)).diff(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A'), 'minute') / total_minutes) * 100
						}%`;
					}
				}
			} else {
				if (moment(day_start_time, 'H:mm').format('a') == 'am') {
					if (moment(val.login_time).format('a') == 'am' && moment(current_time).format('a') == 'am') {
						login_bar_margin_left = `${Math.max(
							(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A').diff(moment(day_start_time), 'minute') / total_minutes) * 100,
							0,
						)}%`;
						login_bar_width = `${
							(moment(current_time).diff(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A'), 'minute') / total_minutes) * 100
						}%`;
						login_bar_border_radius = '0px 4px 4px 0px';
					} else if (moment(val.login_time).format('a') == 'am' && moment(current_time).format('a') == 'pm') {
						login_bar_border_radius = '4px 0px 0px 4px';
						login_bar_width = '-webkit-fill-available';
						login_bar_margin_left = `${Math.max(
							(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A').diff(moment(day_start_time), 'minute') / total_minutes) * 100,
							0,
						)}%`;
					}
				} else {
					if (moment(val.login_time).format('a') == 'am' && moment(current_time).format('a') == 'pm') {
						login_bar_margin_left = 0;
						login_bar_width = `${
							(moment(current_time).diff(moment(moment(day_start_time).format('HH:mm A'), 'HH:mm A'), 'minute') / total_minutes) * 100
						}%`;
						login_bar_border_radius = 0;
					} else if (moment(val.login_time).format('a') == 'pm' && moment(current_time).format('a') == 'pm') {
						login_bar_margin_left = `${Math.max(
							(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A').diff(moment(day_start_time), 'minute') / total_minutes) * 100,
							0,
						)}%`;
						login_bar_width = `${
							(moment(current_time).diff(moment(moment(val.login_time).format('HH:mm A'), 'HH:mm A'), 'minute') / total_minutes) * 100
						}%`;
						login_bar_border_radius = '4px 0px 0px 4px';
					}
				}
			}
			login_bar_array.push({ margin_left: login_bar_margin_left, bar_width: login_bar_width, border_radius: login_bar_border_radius });
		});
	}

	if (moment(day_start_time, 'H:mm').format('a') == 'am' && moment(r.time_slot_to_real).format('a') == 'pm') {
		borderRadius = '4px 0px 0px 4px';
		width = '-webkit-fill-available';
	} else if (moment(day_start_time, 'H:mm').format('a') == 'pm' && moment(r.time_slot_from_real).format('a') == 'am') {
		borderRadius = '0px 4px 4px 0px';
		width = `${(moment(moment(r.time_slot_to_real).format('HH:mm A'), 'HH:mm A').diff(moment(day_start_time), 'minute') / total_minutes) * 100}%`;
	} else {
		borderRadius = 4;
		width = `${(moment(r.time_slot_to_real).diff(moment(r.time_slot_from_real), 'minute') / total_minutes) * 100}%`;
	}

	if (overnight_last) {
		width = '-webkit-fill-available';
		tooltip_text = (
			<div style={{ display: 'flex', padding: 4, gap: 8 }}>
				<Text style={{ color: theme.colors.lightGrey2 }}>till</Text>
				<Text style={{ color: theme.colors.darkGrey7 }}>{moment(r.time_slot_to_real).format('DD MMM `YY')}</Text>
				<Text style={{ color: theme.colors.lightGrey5 }}>|</Text>
				<Text style={{ color: theme.colors.darkGrey7 }}>{moment(r.time_slot_to_real).format('h:mm A')}</Text>
			</div>
		);
	}
	if (overnight_first) {
		if (moment(day_start_time).format('DD-MM-YYYY') == moment(r.time_slot_to_real).format('DD-MM-YYYY')) {
			width = `${(moment(r.time_slot_to_real).diff(moment(day_start_time), 'minute') / total_minutes) * 100}%`;
		} else {
			const moment1 = moment(moment(day_start_time).format('DD/MM/YYYY'), 'DD/MM/YYYY');
			const moment2 = moment(moment(r.time_slot_to_real).format('DD/MM/YYYY'), 'DD/MM/YYYY');
			const diffInDays = moment2.diff(moment1, 'day');
			width = `${(moment(r.time_slot_to_real).diff(moment(moment(day_start_time)).add(diffInDays, 'day'), 'minute') / total_minutes) * 100}%`;
		}
		margin_left = '0%';
		tooltip_text = (
			<div style={{ display: 'flex', padding: 4, gap: 8 }}>
				<Text style={{ color: theme.colors.lightGrey2 }}>since</Text>
				<Text style={{ color: theme.colors.darkGrey7 }}>{moment(r.time_slot_from_real).format('DD MMM `YY')}</Text>
				<Text style={{ color: theme.colors.lightGrey5 }}>|</Text>
				<Text style={{ color: theme.colors.darkGrey7 }}>{moment(r.time_slot_from_real).format('h:mm A')}</Text>
			</div>
		);
	}
	return (
		<>
			{!_.isEmpty(r.time_slot_to_real) && (
				<>
					{show_bar ? (
						<div
							style={{
								position: 'absolute',
								width: width,
								height: 8,
								borderRadius: borderRadius,
								backgroundColor: days_diff < 0 ? theme.colors.lightGreen : days_diff > 0 ? theme.colors.lightGrey5 : theme.colors.lightPurple,
								marginLeft: margin_left,
								textAlign: overnight_last ? 'end' : 'start',
							}}>
							{(overnight_last || overnight_first) && (
								<BootstrapTooltip title={tooltip_text} placement='top' is_white={true}>
									<img src={ImageLinks.over_night_filled_purple} style={{ position: 'relative', bottom: 6, zIndex: 2, right: 2 }} />
								</BootstrapTooltip>
							)}
						</div>
					) : (
						<>
							{show_hover_text && (
								<div style={{ margin: 'auto', textTransform: 'capitalize', display: index > 0 ? 'none' : '' }}>
									<Text>Hover to see Shift Details</Text>
								</div>
							)}
						</>
					)}
				</>
			)}
			{days_diff <= 0 &&
				_.map(login_bar_array, (val) => {
					return (
						<div
							style={{
								position: 'absolute',
								width: val.bar_width,
								height: 8,
								borderRadius: val.border_radius,
								backgroundColor: days_diff === 0 ? theme.colors.primary : theme.colors.green,
								marginLeft: val.margin_left,
							}}></div>
					);
				})}
		</>
	);
};

export default RosterBar;
