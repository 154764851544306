import { makeStyles } from '@material-ui/core';
import Text from 'modules/Text';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import theme from 'resources/theme';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, Filler } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import _ from 'lodash';

ChartJS.register(CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend, TimeScale, LineElement, Filler);

const data = {
	datasets: [
		{
			data: [],
			tension: 0.5,
			borderColor: theme.colors.lightPurple7,
			borderWidth: 1.66,
			fill: true,
			pointBackgroundColor: theme.colors.lightPurple7,
			pointHoverBackgroundColor: theme.colors.primary,
			pointBorderWidth: 0,
			pointHoverBorderWidth: 2,
			pointBorderColor: theme.colors.white,
			pointHoverRadius: 10,
		},
	],
};

const tooltipLine = {
	id: 'tooltipLine',
	afterDraw: (chart) => {
		const ctx = chart.ctx;
		if (chart.tooltip._active && chart.tooltip._active.length) {
			ctx.save();
			const active_point = chart.tooltip._active[0];
			// const data_point = active_point.index;
			// const data_set_index = active_point.datasetIndex;
			ctx.beginPath();
			ctx.moveTo(active_point.element.x, active_point.element.y);
			ctx.lineTo(active_point.element.x, chart.chartArea.bottom);
			ctx.lineWidth = 2;
			ctx.strokeStyle = theme.colors.primary;
			ctx.stroke();
			ctx.restore();
		}
	},
};

const options = {
	elements: {
		point: {
			radius: 2,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
		datalabels: {
			display: false,
		},
		tooltip: {
			yAlign: 'bottom',
			backgroundColor: theme.colors.lightPurple,
			titleColor: theme.colors.primary,
			titleMarginBottom: 2,
			titleFont: {
				size: 8,
				family: 'HK Grotesk Medium',
			},
			titleAlign: 'center',
			bodyColor: 'theme.colors.primary',
			bodyFont: {
				size: 10,
				family: 'HK Grotesk Bold',
			},
			bodyAlign: 'center',
			displayColors: false,
			callbacks: {
				label: (item, data) => {
					return `${item.raw.y}km`;
				},
				title: (item, data) => {
					return item[0].raw.x;
				},
			},
		},
	},
	scales: {
		x: {
			// type: 'time',
			// time: {
			//  unit: 'day',
			//  round: 'day',
			// },
			grid: {
				display: false,
			},
			ticks: {
				color: theme.colors.lightGrey2,
				font: {
					size: 10,
					family: 'HK Grotesk Medium',
				},
			},
		},
		y: {
			beginAtZero: true,
			position: 'right',
			grid: {
				display: true,
				color: theme.colors.lightGrey5,
				lineWidth: 0.55,
			},
			ticks: {
				color: theme.colors.lightPurple7,
				font: {
					size: 10,
					family: 'HK Grotesk Bold',
				},
				callback: function (value, index, ticks) {
					return value + 'k';
				},
			},
		},
	},
	maintainAspectRatio: false,
};

const useStyles = makeStyles((theme) => ({
	wrapper: { display: 'flex', flex: 1, flexDirection: 'column' },
	left_wrap: { flex: 0.4, display: 'flex', flexDirection: 'column' },
	flex_center_between: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
	flex_center: { display: 'flex', alignItems: 'center' },
	indicator: { width: 8, height: 8, borderRadius: 4, marginRight: 4 },
	label: { fontSize: 10, color: theme.colors.darkGrey2 },
	stats_wrap: { marginBottom: 16 },
}));

const DistanceChart = ({ chart_data }) => {
	const classes = useStyles();
	const chart_ref = useRef();
	const [plot_data, set_plot_data] = useState(data);

	useEffect(() => {
		if (chart_data) {
			const formatted_chart_data = _.map(chart_data.data, (item, index) => {
				return { x: item.x, y: item.y.value };
			});

			data.datasets[0].data = formatted_chart_data;
		}
		const chart = chart_ref.current;

		if (!chart) {
			return;
		}

		const chartData = {
			...data,
			datasets: data.datasets.map((dataset) => ({
				...dataset,
				backgroundColor: create_gradient(chart.ctx, chart.chartArea),
			})),
		};
		set_plot_data(chartData);
	}, [chart_data]);

	const create_gradient = (ctx, area) => {
		const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

		gradient.addColorStop(1, theme.colors.lightPurple2);
		gradient.addColorStop(0.5, theme.colors.lightPurple);
		gradient.addColorStop(0, theme.colors.white);

		return gradient;
	};

	const sum_of_distance = useMemo(() => {
		let total = 0;
		for (let i = 0; i < chart_data.data.length; i++) {
			const element = chart_data.data[i];
			total += element.y.value;
		}
		total = parseFloat(total).toFixed(2);
		return total;
	}, [chart_data]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.stats_wrap}>
				<Text medium style={{ fontSize: 10, color: theme.colors.lightGrey2 }}>
					Total Distance
				</Text>
				<Text bold style={{ fontSize: 16, color: theme.colors.primary }}>
					{sum_of_distance}km
				</Text>
			</div>
			<div style={{ width: '100%', height: '100%' }}>
				<Line ref={chart_ref} options={options} data={plot_data} plugins={[tooltipLine]} />
			</div>
		</div>
	);
};

export default DistanceChart;
