import ImageLinks from 'assets/images/ImageLinks';
import Text from 'modules/Text';
import React from 'react';
import useStyles from '../styles';

const PaymentItem = ({ item, onClick }) => {
	const classes = useStyles();

	const getPaymentOptionSubtitle = (item) => {
		switch (item.key) {
			case 'card':
				return 'All cards supported';
			case 'upi':
				return 'BHIM, Google, Paytm';
			case 'netbanking':
				return 'All Banks supported';
			case 'paytm':
				return 'Enter registered mobile number';
			default:
				return '';
		}
	};

	return (
		<div onClick={onClick} className={classes.upi_wrap}>
			<div>
				{item.key === 'paytm' ? (
					<img alt='paytm_logo' src={ImageLinks.paytm} />
				) : (
					<Text semi className={classes.upi_label}>
						{item.value}
					</Text>
				)}

				{getPaymentOptionSubtitle(item) !== '' && (
					<Text medium className={classes.subtitle}>
						{getPaymentOptionSubtitle(item)}
					</Text>
				)}
			</div>
			<div className={classes.select_wrap}>
				<Text semi className={classes.select_text}>
					Select
				</Text>
			</div>
		</div>
	);
};

export default PaymentItem;
