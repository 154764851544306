import { TOGGLE_NOTIF_MODAL, SUPPORT_MODAL_TOGGLE } from 'actions/actionConstants';
import _ from 'lodash';

const initial_state = {
	support_modal_toggle: {},
	notif_modal_toggle: {},
};

const uiUpdates = (state = initial_state, action) => {
	switch (action.type) {
		case SUPPORT_MODAL_TOGGLE: {
			return {
				...state,
				support_modal_toggle: { ...action.support_data },
			};
		}

		case TOGGLE_NOTIF_MODAL: {
			return {
				...state,
				notif_modal_toggle: { ...action.data },
			};
		}

		default:
			return state;
	}
};

export default uiUpdates;
