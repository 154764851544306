import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import theme from 'resources/theme';
import ImageLinks from 'assets/images/ImageLinks';
import ContainedButton from 'modules/ContainedButton';
import Text from 'modules/Text';
import Amount from 'modules/Amount';
import IconButton from 'modules/IconButton';
import Popover from '@material-ui/core/Popover';
import Routes from 'resources/Routes';
import moment from 'moment';
import Button from 'modules/Button';
import utils from 'resources/utils';
import constant from 'resources/constant';

const useStyles = makeStyles((theme) => ({
	rider_info_wrap: {
		height: 150,
		backgroundColor: theme.colors.white,
		borderRadius: 16,
		boxShadow: '0px 2px 4px 0px rgba(30, 30, 30, 0.04), 0px 0px 6px 0px rgba(30, 30, 30, 0.04)',
		margin: '4px 4px 0px 4px',
	},
	rider_upper_wrap: { display: 'flex', justifyContent: 'space-between', padding: 14 },
	rider_down_wrap: {
		backgroundColor: theme.colors.lightPurple9,
		height: 70,
		justifyContent: 'space-between',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 14,
		paddingRight: 14,
		borderBottomLeftRadius: 16,
		borderBottomRightRadius: 16,
	},
	flex_center: { display: 'flex', alignItems: 'center' },
	rider_stats_label: { fontSize: 11, color: theme.colors.lightGrey6 },
	popover_wrap: { minWidth: 131, borderRadius: 6, padding: 10 },
	settings_dot: { width: 1.68, height: 1.68, borderRadius: 0.84, backgroundColor: theme.colors.darkGrey2 },
	avatar_wrap: {
		width: 56,
		height: 56,
		borderRadius: 28,
		backgroundColor: theme.colors.white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	settings_popover_wrap: { minWidth: 160, borderRadius: 4, boxShadow: '0px 4px 4px 0px rgba(202, 202, 202, 0.25)' },
	settings_popover_item: {
		cursor: 'pointer',
		height: 33,
		padding: '8px 12px',
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
		},
	},
	settings_dot_wrap: {
		cursor: 'pointer',
		width: 20,
		height: 20,
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: theme.colors.lightPurple,
		},
	},
	action_btns: { width: 20, height: 20, cursor: 'pointer', marginRight: 12 },
	edit_btn: {
		borderRadius: 34,
		height: 22,
		marginBottom: 5,
		marginRight: 12,
		paddingLeft: 8,
		paddingRight: 8,
	},
}));

const RiderInfoCard = ({ rider_details, toggle_rider_modal, clear_device_id, history }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [settings_anchor_el, set_settings_anchor_el] = React.useState(null);

	const handle_close = () => {
		setAnchorEl(null);
		set_settings_anchor_el(null);
	};

	const handle_device_id = () => {
		set_settings_anchor_el(null);
		clear_device_id();
	};

	const get_border_color = () => {
		if (_.isEmpty(rider_details)) return;
		const online_flag = rider_details.details.is_online;
		const ongoing_flag = rider_details.delivery_stats.ongoing;
		const shift_flag = rider_details.shift;
		let border_color = theme.colors.lightGrey2;
		if (online_flag && (ongoing_flag == 0 || ongoing_flag === null)) border_color = theme.colors.green;
		if (ongoing_flag >= 1) border_color = theme.colors.darkYellow;
		if (!online_flag && !_.isEmpty(shift_flag)) border_color = theme.colors.red;
		if (_.isEmpty(shift_flag)) border_color = theme.colors.lightGrey2;

		return `2px solid ${border_color}`;
	};

	const open = Boolean(anchorEl);
	const settings_open = Boolean(settings_anchor_el);

	return (
		<div className={classes.rider_info_wrap}>
			<div className={classes.rider_upper_wrap}>
				<div className={classes.flex_center}>
					<div className={classes.avatar_wrap} style={{ border: get_border_color() }}>
						<img
							src={_.isEmpty(rider_details?.details?.profile_pic) ? ImageLinks.user_filled_purple : rider_details.details.profile_pic}
							width={_.isEmpty(rider_details?.details?.profile_pic) ? 32 : 50}
							height={_.isEmpty(rider_details?.details?.profile_pic) ? 32 : 50}
							style={!_.isEmpty(rider_details?.details?.profile_pic) ? { borderRadius: 25 } : {}}
						/>
					</div>
					<div style={{ marginLeft: 10 }}>
						<Text bold style={{ fontSize: 12 }}>
							{_.get(rider_details, 'details.rider_id', '')}
						</Text>
						<Text medium style={{ fontSize: 16 }}>
							{_.get(rider_details, 'details.rider_name', '')}
						</Text>
					</div>
				</div>

				<div className={classes.flex_center}>
					<Button
						text='Edit Details'
						type='round'
						size='extra_small'
						left_icon={ImageLinks.edit_white}
						onClick={() => {
							utils.track_event_for_analytics(constant.TRACKING_EVENTS.EDIT_RIDER_DETAILS);
							history.push(Routes.EDIT_RIDER.path.replace(':id', rider_details?.details?.rider_id));
						}}
						style={{ marginRight: 12 }}
					/>

					<div onClick={toggle_rider_modal} className={classes.action_btns}>
						<img src={ImageLinks.track_purple} width={20} height={20} />
					</div>
					<div
						onClick={(event) => {
							utils.track_event_for_analytics(constant.TRACKING_EVENTS.CALL_RIDER);
							setAnchorEl(event.currentTarget);
						}}
						className={classes.action_btns}>
						<img src={ImageLinks.call_filled} width={20} height={20} />
					</div>

					<div
						onClick={(event) => {
							set_settings_anchor_el(event.currentTarget);
						}}
						style={settings_open ? { backgroundColor: theme.colors.lightPurple } : {}}
						className={classes.settings_dot_wrap}>
						<div className={classes.settings_dot}></div>
						<div
							className={classes.settings_dot}
							style={{
								marginTop: 3,
								marginBottom: 3,
							}}></div>
						<div className={classes.settings_dot}></div>
					</div>
				</div>
			</div>

			<div className={classes.rider_down_wrap}>
				<div>
					<Text semi style={{ fontSize: 14 }}>
						{!_.isEmpty(_.get(rider_details, 'shift.time_slot_from_real', null))
							? `${moment(rider_details.shift.time_slot_from_real).format('hh:mm A')} - ${moment(rider_details.shift.time_slot_to_real).format(
									'hh:mm A',
							  )}`
							: '-'}
					</Text>
					<Text medium className={classes.rider_stats_label}>
						Shift Time
					</Text>
				</div>
				<div>
					<Text semi style={{ fontSize: 14 }}>
						{_.get(rider_details, 'shift.hub_title', '-')}
					</Text>
					<Text medium className={classes.rider_stats_label}>
						Hub Location
					</Text>
				</div>
				<div style={{ width: 1, height: 32, background: theme.colors.lightPurple6 }}></div>
				<div>
					<div className={classes.flex_center}>
						<Text semi style={{ fontSize: 14, marginRight: 6 }}>
							{_.get(rider_details, 'details.bike_details.registration', '-')}
						</Text>
						{/* <img src={ImageLinks.chevron_right_black} width={10} height={10} /> */}
					</div>
					<Text medium className={classes.rider_stats_label}>
						Vehicle Number
					</Text>
				</div>
				<div>
					<div className={classes.flex_center}>
						<Text semi style={{ fontSize: 14, marginRight: 6 }}>
							{_.get(rider_details, 'details.bag_title', '-')}
						</Text>
						{/* <img src={ImageLinks.chevron_right_black} width={10} height={10} /> */}
					</div>
					<Text medium className={classes.rider_stats_label}>
						Rider Bag
					</Text>
				</div>
				<div>
					<div
						style={{ cursor: 'pointer' }}
						onClick={() => {
							history.push(Routes.CASH_MANAGEMENT_RIDER_DETAIL.path.replace(':id', rider_details.details.rider_id));
						}}
						className={classes.flex_center}>
						<Amount
							amount={_.get(rider_details, 'outstanding_cash', 0)}
							main_text_styles={{ fontSize: 14, color: theme.colors.black }}
							sub_text_styles={{ fontSize: 14, color: theme.colors.black }}
						/>
						<img src={ImageLinks.chevron_right_black} style={{ marginLeft: 6 }} width={10} height={10} />
					</div>
					<Text medium className={classes.rider_stats_label}>
						Outstanding Amount
					</Text>
				</div>
			</div>
			<Popover
				open={open}
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'top',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				classes={{ paper: classes.popover_wrap }}
				onClose={handle_close}>
				<div style={{}}>
					<Text bold style={{ fontSize: 18 }}>
						{_.get(rider_details, 'details.mobile')}
					</Text>
				</div>
			</Popover>

			<Popover
				open={settings_open}
				anchorEl={settings_anchor_el}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				classes={{ paper: classes.settings_popover_wrap }}
				onClose={handle_close}>
				<div className={classes.settings_popover_item} onClick={handle_device_id}>
					<Text medium style={{ fontSize: 14 }}>
						Clear Device ID
					</Text>
				</div>
			</Popover>
		</div>
	);
};

export default RiderInfoCard;
